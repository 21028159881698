import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { ActivityService } from 'src/app/providers/activity-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from '../../../libs/MyUtil';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

/**
 * Generated class for the ActivityBookingPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-activity-booking',
  templateUrl: 'activity-booking.html',
  styleUrls: ['activity-booking.scss'],
})
export class ActivityBookingPage implements OnInit, OnDestroy{

  @ViewChild('Content', {static: true}) content;
  pageData: any = {
    details: [],
    detailFormFields: {},
  };
  routeData:any = {};

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, 
    private route: ActivatedRoute,
    public navParams: NavParams, public formBuilder: UntypedFormBuilder,
    public desktopChecker: DesktopChecker, public activityService: ActivityService,
    private titleService:Title) {
      this.titleService.setTitle("Book Activity");
  }

  ngOnInit(): void {
      
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData)
    });

    let id = this.routeData.id;
    this.pageData.bookingFormSubmitAttempt = false;
    this.pageData.containsAccessibility = false;
    this.pageData.containsGeneral = false;
    this.pageData.removeComments = false;
    
    this.pageData.activity = MyUtil.getActivity(id);
    this.pageData.clashingActivities = this.routeData.clashingActivities;
    this.pageData.details = this.routeData.details;
    this.pageData.bookingLabel = "Book place on activity";
    this.pageData.options = this.routeData.options;
    
    if(this.pageData.options.convertBooking) {
      let hybridBookingType = this.pageData.options.hybridOnline ? 'online' : 'in-person';
      if(this.pageData.options.firmBooking) {
        this.pageData.bookingLabel = "Convert booking to " + hybridBookingType;
      } else if(this.pageData.options.waitingList) {
        this.pageData.bookingLabel = "Join " + hybridBookingType + " waiting list";
      } else if(this.pageData.options.applicationList) {
        this.pageData.bookingLabel = "Join " + hybridBookingType + " application list";
      }
    } else {
      if(this.pageData.options.waitingList) {
        this.pageData.bookingLabel = "Join waiting list";
      } else if(this.pageData.options.applicationList) {
        this.pageData.bookingLabel = "Join application list";
      }
    }

    MyUtil.lodash.forEach(this.pageData.details, (detail) => {
      this.pageData.detailFormFields[detail.label] = ['', detail.type == 1 && detail.item != 5 && detail.label != 'require_comments' ? Validators.compose([Validators.required]) : null];

      if(detail.label == 'require_dietary' || detail.label == 'require_comments') {
        this.pageData.detailFormFields[detail.label + '_comment'] = [''];
      }
      if(detail.type == 1) {
        this.pageData.containsGeneral = true;
      }
      if(detail.type == 2) {
        this.pageData.containsAccessibility = true;
      }

      // if we have a custom question, we want to remove the general question
      if(detail.item == 5) {
        this.pageData.removeComments = true;
      }
    });

    if(this.pageData.containsAccessibility) {
      this.pageData.detailFormFields.accessibility = ['', Validators.compose([Validators.required])];
    }

    this.pageData.bookingForm = this.formBuilder.group(this.pageData.detailFormFields);
  }

  ionViewDidLoad() {

  }


  async process(action) {

    let loading = MyUtil.presentLoading();

    // validate input and prepare api call
    let data;
    this.pageData.bookingFormSubmitAttempt = true;
    if (!this.pageData.bookingForm.valid) {
      MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
      (await loading).dismiss();
      return;
    }

    data = this.pageData.bookingForm.value;
    data.details = [];
    //check to see if dietary requirements has been filled in if the requirement is yes
    if(data['require_dietary'] == 1 && data['require_dietary_comment'].length == 0) {
      MyUtil.presentToast('Please specify your dietary requirements', { cssClass: 'inkpath-toast' });
      (await loading).dismiss();
      return;
    }

    //Get the name of the free text comment field
    let freeTextField = 'require_comments_comment';
    MyUtil.lodash.forEach(this.pageData.details, (detail) => {
      if(detail.item == 5) {
        freeTextField = detail.label;
      }
    });

    //reshape data into details format
    MyUtil.lodash.forEach(this.pageData.details, (detail) => {

      if(detail.label != 'require_comments' && data[detail.label] == 1) {
        let reshapedDetail = {
          item: detail.item,
          comment: data[detail.label + '_comment'],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      if(detail.label == freeTextField && data[freeTextField] != "") {
        let reshapedDetail = {
          item: detail.item,
          comment: data[freeTextField],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      if(detail.label == 'accessibility_free_text' && data['accessibility_free_text'] != "") {
        let reshapedDetail = {
          item: detail.item,
          comment: data['accessibility_free_text'],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      MyUtil.lodash.unset(data, detail.label);
      MyUtil.lodash.unset(data, detail.label + '_comment');
    });

    data.bookable_id = this.pageData.activity.id;
    data.hybrid_online = this.pageData.options.hybridOnline;
    data.convert_booking = this.pageData.options.convertBooking;

    MyUtil.lodash.unset(data, 'accessibility');
    
    this.activityService.saveBooking(data).then(async result => {
      if(result) {
        let message = "Booking confirmed";
        if (this.pageData.options.waitingList) {
          message = "You have been added to the activity waiting list";
        } else if (this.pageData.options.applicationList) {
          message = "You have been added to the activity application list";
        }

        (await loading).dismiss();
        MyUtil.presentToast(message, {
          duration: MyUtil.CONST.DURATION_TOAST_LONG,
          cssClass: 'inkpath-toast'
        });

        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: this.pageData.activity.id })]);
      }
    });
  }


  public getHumanLabel(text: string) {
    return MyUtil.BOOKING_LABELS[text];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
