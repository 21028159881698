import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store } from "@ngrx/store";
import { NgxCopilotService } from 'ngx-copilot';
import { Observable, Subject } from 'rxjs';
import { Appapi } from 'src/app/providers/appapi';
import { Appcalc } from 'src/app/providers/appcalc';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-goals',
  templateUrl: 'goals.html',
  styleUrls: ['goals.scss']
})
export class GoalsPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  pageLabel = 'GoalsPage';
  isMobileView = this.uiux.isMobileView();
  loaded = false;


  /**
  * Get brand name from store.
  */
  brandName$: Observable<string> = this.store.select(getBrandName);

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<appStore.AppViewState>,
    public router: Router,
    private copilot: NgxCopilotService,
    public navParams: NavParams,
    public uiux: UiuxService,
    public appapi: Appapi,
    public appcalc: Appcalc,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Find Goals"); 
    }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
  * Set Walkthrough state. 
  * @param pageName   Name of page
  * @param value      Boolean - (Has been visited or not)
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  ngOnInit() {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    // prepare my goals
    this.pageData.userGoals = [];
    this.pageData.userGoalsDisplay = [];
    this.pageData.displayHelpIcon = true;
    this.pageData.homeOrg = MyUtil.getRootOrganization();
    
    let loading = MyUtil.presentLoading();
    this.appapi.syncGoalsAndActivities().then(() => {
      this.appapi.queryUserGoals().then((result) => {
        this.pageData.userGoals = result;
        this.appcalc.refreshPerformancePerGoals$().subscribe(async () => {
          this.renderUserGoals();
          this.pageData.loaded = true;
          (await loading).dismiss();
        });
      });
    });

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID?.GOALS]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.GOALS, true);
    }

    MyUtil.firebaseSetScreenName('goals');
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'goals', data: this.navParams.data });

    this.startWalkthrough()
  }

  /** 
  * start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('22');
      }, 1000);
    }
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'goals', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'browse-goals':
        this.router.navigate(['/GoalListPage', '{}']);
        return;
      case 'goal-detail':
        this.router.navigate(['/GoalDetailPage', item.id]);
        return;
      case 'create-goal':
        this.router.navigate(['/GoalEditPage', '{}']);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }


  /** 
  * Render user goals.
  */
  private renderUserGoals() {
    this.pageData.userGoalsDisplay = MyUtil.lodash.chain(this.pageData.userGoals).map((item) => {
      let goal = MyUtil.getGoal(item.goal_id);
      let result = null;

      if (goal) {
        let dateFormat = MyUtil.formatPHPDate(this.pageData.homeOrg.org_date_format);
        let startedDisplay = (item.started_at ? MyUtil.formatUnixTimeStampDate(item.started_at, dateFormat) : null);
        let completedDisplay = (item.completed_at ? MyUtil.formatUnixTimeStampDate(item.completed_at, dateFormat) : null);

        let chartInfo = this.appcalc.getGoalChartData(item.id);
        result = {
          id: goal.id,
          name: goal.name,
          organization: MyUtil.getOrgnizationChainName(goal.oid),
          priority: MyUtil.cache[MyUtil.DOC_ID.APP_META]['GOAL_PRIORITY'][goal.priority],
          period: (completedDisplay ? 'from ' + startedDisplay + ' to ' + completedDisplay : startedDisplay),
          percent_complete: chartInfo['completeness'],
          profile_id: goal.profile_id,
          badge_details: goal.badge_details ? goal.badge_details : null,
        }
      }

      return result;
    }).filter((item) => {
      return item;
    }).sort((a: any, b: any) => {
      //Sort by name
      if(a.name > b.name) {
        return 1;
      }

      if(b.name > a.name) {
        return -1;
      }
      
      return 0;
    }).value();
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
