import { Injectable } from '@angular/core';
import { Appapi } from './appapi';
import { Observable } from 'rxjs';
import { MyUtil } from 'src/libs/MyUtil';

/**
 * General calculation engine service to calculate performance of the user
 * calculate in an asynchronized way and notify UI update via observable subscribe
 * cache calculate result for UI to show immediately
 */
@Injectable()
export class Appcalc {
  perSkills = {}; // array of skill completeness indexed timeframe, then by skill id
  perActivities: any = {}; // array of completed user activity indexed by activity id
  perCourses: any = {}; // array of completed user course indexed by course id
  performancePerSkills: any = {}; // cache array of user skill performance indexed timeframe, then by skill id
  performancePerGoals: any = {}; // cache array of user goal performance indexed by user goal id
  engineStatus: any = { // current status of the calculation engine
    performancePerSkillsUpdatedAt: null,
    performancePerGoalsUpdatedAt: null,
  };

  constructor(public appapi: Appapi) { }

  refreshCompletedActivities$(): Observable<any> {

    return new Observable((observer) => {

      let skills: any = {};
      let activities: any = {};
      let courses: any = {};

      //Get activity skills and time logged
      let userActivities = MyUtil.cache[MyUtil.DOC_ID.USER_ACTIVITIES];
      MyUtil.lodash.forEach(userActivities, (userActivity) => {

        //Store the hours for the completed non-course activities. Also add activity to skill structure
        if (!!userActivity.completed_at) {
          let activity = MyUtil.getActivity(userActivity.activity_id);
          if (activity && !activity.course_id) {

            // build activities structure
            activities[activity.id] = {
              time_logged: userActivity.time_logged
            }

            // build skills structure
            MyUtil.lodash.forEach(activity.skills, (skillId) => {
              if (!skills[skillId]) {
                //Create item if it doesn't already exist
                skills[skillId] = {
                  time_logged: 0,
                  activities: [],
                  courses: [],
                };
              }

              //Add activity details
              skills[skillId].time_logged += userActivity.time_logged;
              skills[skillId].activities.push({
                //id: userActivity.id, //??
                id: skillId, //??
                activity_id: activity.id,
                ts: activity.start_at,
                time_logged: userActivity.time_logged ? userActivity.time_logged : 0,
              });
            });
          }
        }
      });

      //Get course skills and total time logged
      let userCourses = MyUtil.cache[MyUtil.DOC_ID.USER_COURSES];
      MyUtil.lodash.forEach(userCourses, (userCourse) => {

        if (!!userCourse.completed_at) {
          let course = MyUtil.getCourse(userCourse.course_id);
          if (course) {

            let courseTimeLogged = 0;
            MyUtil.lodash.forEach(course.course_activities, (courseActivity) => {
              let userActivity = MyUtil.getUserActivity(courseActivity.activity_id);
              if (!!userActivity?.completed_at) {
                if (userActivity.time_logged && userActivity.time_logged > 0) {
                  courseTimeLogged += userActivity.time_logged;
                }
              }
            });

            courses[course.id] = {
              time_logged: courseTimeLogged
            }

            // build skills structure
            MyUtil.lodash.forEach(course.skills, (skillId) => {
              if (!skills[skillId]) {
                //Create item if it doesn't already exist
                skills[skillId] = {
                  time_logged: 0,
                  activities: [],
                  courses: [],
                };
              }

              //Add course details
              skills[skillId].time_logged += courseTimeLogged;
              skills[skillId].courses.push({
                id: skillId, //??
                course_id: course.id,
                ts: course.start_at,
                time_logged: courseTimeLogged
              });
            });
          }
        }
      });

      MyUtil.lodash.forEach(skills, (skill) => {
        skill.activities = MyUtil.lodash.orderBy(skill.activities, ['ts'], ['asc']);
        skill.courses = MyUtil.lodash.orderBy(skill.courses, ['ts'], ['asc']);
      });

      this.perSkills = skills;
      this.perActivities = activities;
      //this.perCourses = skills;
      this.perCourses = courses;

      observer.next(skills);
    });

  }



  refreshPerformancePerSkills$(interval: number, orgId?: number): Observable<void> {

    return new Observable((observer) => {
      this.refreshCompletedActivities$().subscribe((data) => {

        let intervalMap = {
          0: 0,
          1: MyUtil.getMoment().subtract(1, 'year').unix(),
          2: MyUtil.getMoment().subtract(1, 'quarter').unix(),
          3: MyUtil.getMoment().subtract(1, 'month').unix(),
        };
        let startAt = (intervalMap[interval] ? intervalMap[interval] : 0);
        let skillIds = [];

        let performance = MyUtil.lodash.chain(this.perSkills).map((skill, id) => {
          //Add the hours up for the timeframe

          //Total time logged - activities
          let timeLoggedActivities = MyUtil.lodash.chain(skill.activities).filter((activity) => {
            return (activity.ts >= startAt);
          }).sumBy('time_logged').value();

          //Total time logged - courses
          let timeLoggedCourses = MyUtil.lodash.chain(skill.courses).filter((course) => {
            return (course.ts >= startAt);
          }).sumBy('time_logged').value();

          //Total of both
          let totalTimeLogged = timeLoggedActivities + timeLoggedCourses;

          //Get activities for timeframe
          let activities = MyUtil.lodash.chain(skill.activities).filter((activity) => {
            return (activity.ts >= startAt);
          }).map((item) => {
            return {
              id: item.id,
              activity_id: item.activity_id,
              time_logged: item.time_logged / 60
            }
          }).value();

          //Get courses for timeframe
          let courses = MyUtil.lodash.chain(skill.courses).filter((course) => {
            return (course.ts >= startAt);
          }).map((item) => {
            return {
              id: item.id,
              course_id: item.course_id,
              time_logged: item.time_logged / 60
            }
          }).value();

          //Check if skill in cache
          skillIds.push(id);
          let skillObj = MyUtil.getSkillWithoutProgramFiltering(id);
          if (skillObj) {
            return {
              id: id,
              name: skillObj.name,
              oid: skillObj.oid,
              value: totalTimeLogged / 60, // convert to hours
              activities: activities,
              courses: courses,
              programs: skillObj.programs,
            };

          } else {
            return null;
          }
        }).filter((item) => {

          // filter empty item
          if (item) {
            // filter org
            if (orgId) {
              return (orgId == item.oid);
            } else {
              return true;
            }
          } else {
            return false;
          }
        }).value();

        //Also return skills with no progress in timeframe
        MyUtil.lodash.forEach(MyUtil.getSkillsWithoutProgramFiltering(orgId), (skill, id) => {
          if (skillIds.indexOf(id) === -1) {
            performance.push({
              id: id,
              name: skill.name,
              oid: skill.oid,
              value: 0,
              activities: [],
              courses: [],
              programs: skill.programs,
            });
          }
        });

        // cache by timeframe
        this.performancePerSkills[interval] = performance;

        observer.next(performance);
      })
    });
  }


  refreshPerformancePerGoals$(): Observable<void> {

    return new Observable((observer) => {
      this.refreshCompletedActivities$().subscribe(() => {
        // get all user goals and iterate through
        this.appapi.queryUserGoals().then((userGoals) => {
          // get all user completed activities
          let completedActivityIds = MyUtil.lodash.keys(this.perActivities);
          completedActivityIds = MyUtil.lodash.map(completedActivityIds, (item) => {
            return Number(item);
          });

          MyUtil.lodash.forEach(userGoals, (userGoal) => {
            let goal = MyUtil.getGoal(userGoal.goal_id);
            if (goal) {
              let completeness = 0;
              let completed_activities = {};

              if (goal.calc_type == MyUtil.CONST.APP_META.GOAL_CALC_TYPE_ATTEND_COUNT) {
                // Goal with activities to complete

                let requiredActivityIds = MyUtil.lodash.map(goal.activities, (item) => {
                  return item;
                });

                let intersectedActivityIds = MyUtil.lodash.filter(completedActivityIds, (id) => {
                  return requiredActivityIds.indexOf(id) > -1;
                });

                let num_complete_activities = intersectedActivityIds.length;
                let num_activities = Number(goal.attend_count);

                completed_activities = MyUtil.lodash.map(requiredActivityIds, (id) => {
                  return {
                    id: id,
                    completed: completedActivityIds.indexOf(id) > -1,
                  };
                });

                // completeness = ( number_complete_activities / total_number_of activities )
                if (num_complete_activities > num_activities) {
                  completeness = 1;
                } else {
                  completeness = num_complete_activities / num_activities;
                }

              } else if (goal.calc_type == MyUtil.CONST.APP_META.GOAL_CALC_TYPE_TIME_EFFORT) {
                // Goal with skills to complete

                let skillId = goal.skills[0];
                let startedAt = userGoal.started_at;
                let num_hours = Number(goal.time_effort);
                let num_skill_hours = 0;
                let completed_skill_user_activities = MyUtil.lodash.chain({});

                if (this.perSkills[skillId] && this.perSkills[skillId].activities) {
                  // only count activities after goal started
                  completed_skill_user_activities = MyUtil.lodash.chain(this.perSkills[skillId].activities).filter((activity) => {
                    return (activity.ts >= startedAt);
                  });
                  num_skill_hours = completed_skill_user_activities.sumBy('time_logged').value();
                }

                completed_activities = completed_skill_user_activities.map((user_activity) => {
                  return {
                    id: user_activity.activity_id,
                    time_logged: user_activity.time_logged,
                    completed: true,
                  };
                }).value();

                if (num_skill_hours > num_hours) {
                  completeness = 1;
                } else {
                  completeness = num_skill_hours / num_hours;
                }
              }

              let performace = {
                completeness: completeness,
                items: completed_activities,
              };

              this.performancePerGoals[userGoal.id] = performace;
            }
          });
          observer.next();
        });
      })
    });
  }


  getProfileChartData(interval: number, programId?: number) {
    let result = null;
    if (this.performancePerSkills[interval]) {
      result = MyUtil.lodash.filter(this.performancePerSkills[interval], (item) => {
        return item && (!programId || (item.programs.length === 0 || item.programs.indexOf(programId) !== -1));
      });
    }
    return result;
  }

  

  getGoalChartData(id: number) {
    let result = 0;
    if (this.performancePerGoals[id]) {
      result = this.performancePerGoals[id];
    }
    return result;
  }

}
