import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { MyDb } from '../../../libs/MyDb';
import { MyUtil } from '../../../libs/MyUtil';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { OrganizationsPage } from '../organizations/organizations';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-funding-list',
  templateUrl: 'funding-list.html',
  styleUrls: ['funding-list.scss']
})
export class FundingListPage implements OnInit{

  // Component variables.
  @ViewChild('Content', {static: true}) content;
  pageData: any = {};
  demogQuestions: any = [];
  routeData: any = {};
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, 
    private route: ActivatedRoute,
    public navParams: NavParams, 
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi, 
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Add an Affiliation");

  }

  ngOnInit() {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    let loading = MyUtil.presentLoading();
    this.appapi.checkAndRefreshProfile().then(() => {
      this.appapi.syncAllFundings().then(async () => {
      
        // First time setup
        this.pageData.initialisedProfile = this.routeData.initialisedProfile;

        // expand help for first time landing on the page
        if (MyUtil.context.helpStatus[MyUtil.HELP_ID.FUNDING_LIST]) {
          this.pageData.helperToggle = false;
        } else {
          this.pageData.helperToggle = true;
          this.appapi.setAppHelpStatus(MyUtil.HELP_ID.FUNDING_LIST, true);
        }

        // prepare profile for change
        this.pageData.profile = MyUtil.lodash.cloneDeep(MyUtil.getProfile());
        this.renderFundingsDisplay();

        // define funding form validation
        this.pageData.funding = {};
        this.pageData.fundingForm = this.formBuilder.group({
          program_id: [this.pageData.funding.program_id, Validators.compose([Validators.required])],
          phase_id: [this.pageData.funding.phase_id, Validators.compose([Validators.required])],
          funding_id: [this.pageData.funding.funding_id, Validators.compose([Validators.required, this.validateOrgnization('bottomLevel')])],
        });

        this.pageData.demogQuestions = [];

        (await loading).dismiss();
      }, async (error) => {
        (await loading).dismiss();
        MyUtil.debug(error);
      });
    });
  }
  
  /** 
  * Form toggle handler.
  */
  formToggleHandler(){
    this.pageData.formToggle =!this.pageData.formToggle
  }

  /** 
  * Validate organisation.
  */
  private validateOrgnization(key: string): ValidatorFn {
    return (formControl: UntypedFormControl): {[key: string]: any} => {
      let result: {[key: string]: any} = null;
      let funding_id = formControl.value;
      let orgMap = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
      if (orgMap && orgMap[funding_id] && orgMap[funding_id].children) {
        result = {};
        result[key] = true;
      }
      return result;
    }
  }

  private renderFundingsDisplay() {
    this.pageData.fundingsDisplay = [];
    this.pageData.fundingRootIds = [];
    MyUtil.lodash.forEach(this.pageData.profile.fundings, (funding: any) => {
      //TODO: change to pass in root_funding_id when back-end API refactor sorted
      let fundingRoot = MyUtil.getRootOrganization(funding.funding_id, true);
      this.pageData.fundingRootIds.push(fundingRoot.id);

      let programs = this.getFundingPrograms(funding.funding_id);
      let phases = this.getFundingPhases(funding.funding_id);
      if (programs[funding.program_id] && phases[funding.phase_id] ) {
        let fundingDisplay = {
          fundingName: MyUtil.getOrgnizationChainName(funding.funding_id),
          programName: programs[funding.program_id].name,
          phaseName: phases[funding.phase_id].name,
          affiliate_lock: fundingRoot.allow_affiliate_lock,
          demogQuestions: funding.demogQuestions,
          demogCompliance: funding.demogCompliance,
          pending: fundingRoot.pending
        };
        this.pageData.fundingsDisplay.push(fundingDisplay);
      }
    });
  }

  /** 
  * Reset funcing form.
  */
  private resetFundingForm() {
    // clear form
    this.pageData.funding = {};
    this.pageData.fundingForm.reset(this.pageData.funding);
    // close form
    this.pageData.formToggle = null;
    this.pageData.demogQuestions = [];
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('funding-list');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', {name: 'funding-list', data: this.navParams.data});
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', {name: 'funding-list', data: this.navParams.data});
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'choose-organization':
        this.chooseOrganization();
        return;
      case 'add-funding':
        this.addFunding();
        return;
      case 'remove-funding':
        this.removeFunding(item);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  private async chooseOrganization() {
    
      let allFundings = MyUtil.getAllFundings();
      let fundingRootIds = this.pageData.fundingRootIds;
      
      //Filter out any root fundings the user already has
      let selectableFundings = MyUtil.lodash
        .chain(allFundings)
        .cloneDeep()
        .filter((item) => {
          return (!fundingRootIds.includes(item.id) && !item.already_affiliated);
        })
        .keyBy("id")
        .value();

      let organizationsModal = MyUtil.createModal(OrganizationsPage, { top:  { name: 'Affiliations', children: selectableFundings}});

      (await organizationsModal).onDidDismiss().then((data:any) => {
        if (data.data) {
          this.pageData.organizations = [{
            id: data.data.id,
            name: MyUtil.getOrgnizationChainName(data.data.id)
          }];
          this.pageData.fundingForm.controls.funding_id.setErrors(null);
          this.pageData.fundingForm.controls.funding_id.setValue(data.data.id);
          this.pageData.fundingForm.controls.funding_id.markAsDirty();
          this.pageData.demogQuestions = [];

          // init program_ids for select options
          this.pageData.programs = MyUtil.lodash.orderBy(MyUtil.lodash.values(this.getFundingPrograms(data.data.id)), [item => item.name.toLowerCase()], ['asc']);
          this.pageData.fundingForm.controls.program_id.setValue(null);
    
          // init phase_ids for select options
          this.pageData.phases = MyUtil.lodash.orderBy(MyUtil.lodash.values(this.getFundingPhases(data.data.id)), [item => item.name.toLowerCase()], ['asc']);
          this.pageData.fundingForm.controls.phase_id.setValue(null);

          // get demographics for selected organisation (if exist)
          //TODO: change to pass in root_funding_id when back-end API refactor sorted
          let fundingRoot = MyUtil.getRootOrganization(data.data.id, true);
          this.pageData.demogQuestions = fundingRoot.demogQuestions;
          this.pageData.demogCompliance = fundingRoot.demogCompliance;
          this.demogQuestions = fundingRoot.demogQuestions;
        }
      });
      (await organizationsModal).present();
  }

  /**
   * get programs belong to certain funding id
   */
   private getFundingPrograms(fundingId: string) {
    //TODO: change to pass in root_funding_id when back-end API refactor sorted
    let rootFunding = MyUtil.getRootOrganization(fundingId, true);
    
    let programs = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.ALL_FUNDING_PROGRAMS])
      .cloneDeep()
      .filter((item) => {
        return (item.oid == rootFunding.id);
      })
      .keyBy("id")
      .value();
    return programs;
  }

  /**
   * get programs belong to certain funding id
   */
   private getFundingPhases(fundingId: string) {
    //TODO: change to pass in root_funding_id when back-end API refactor sorted
    let rootFunding = MyUtil.getRootOrganization(fundingId, true);
    
    let phases = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.ALL_FUNDING_PHASES])
      .cloneDeep()
      .filter((item) => {
        return (item.oid == rootFunding.id);
      })
      .keyBy("id")
      .value();
    return phases;
  }

  private addFunding() {
    this.pageData.fundingFormSubmitAttempt = true;
    if (!this.pageData.fundingForm.valid) {
      let errors = MyUtil.getFormValidationErrors(this.pageData.fundingForm, {
        program_id: 'Programme is required',
        phase_id: 'Year is required',
        funding_id: 'Bottom level funding organisation is required'
      });
      MyUtil.presentToast(MyUtil.errorsToMessage(errors), { cssClass: 'inkpath-toast' });
      return;
    }

    if(this.demogQuestions) {
      let demogItems = this.demogQuestions.filter(item => item.answer);
        if(demogItems.length < this.demogQuestions.length) {
          MyUtil.presentToast('Please complete the additional profile questions', { cssClass: 'inkpath-toast' });
          return;
        }
    }

    // avoid duplicate fundings
    //TODO: change to pass in root_funding_id when back-end API refactor sorted
    let fundingRoot = MyUtil.getRootOrganization(this.pageData.fundingForm.value.funding_id, true);
    if (this.pageData.fundingRootIds && this.pageData.fundingRootIds.indexOf(fundingRoot.id) !== -1) {
      MyUtil.presentToast(fundingRoot.name + ' has already been added', { cssClass: 'inkpath-toast' });
      return;
    }

    if (!this.pageData.profile.fundings) {
      this.pageData.profile.fundings = [];
    }

    // update with the form changes
    let newFundingData = this.pageData.fundingForm.value;

    // set the demographics data
    if(this.demogQuestions && this.demogQuestions.length) {
      newFundingData.demogQuestions = this.demogQuestions;
    }

    this.pageData.profile.fundings.push(newFundingData);

    this.saveUserProfile().then(() => {
      this.resetFundingForm();
      this.renderFundingsDisplay();
    });
  }

  /** 
  * Remove funding.
  */
  private removeFunding(idx: number) {
    if (this.pageData.profile.fundings && idx >= 0 && idx < this.pageData.profile.fundings.length) {
      MyUtil.presentAlert({
        title: 'Confirm removal of affiliation',
        message: 'Are you sure you wish to remove ' + this.pageData.fundingsDisplay[idx].fundingName + '?',
        buttons: [
          {
            text: 'Cancel',
            handler: () => {
              return;
            }
          },
          {
            text: 'Confirm',
            handler: () => {
              this.pageData.profile.fundings.splice(idx, 1);
              this.saveUserProfile().then(() => {
                this.renderFundingsDisplay();
              });
            }
          }
        ],
      });
    }
  }

  // save and sync profile, then reload master data accordingly
  // similar procedure happens when changing my fundings
  private saveUserProfile() {
    let loading = MyUtil.presentLoading();
    return MyDb.userSave({
        _id: MyUtil.DOC_ID.USER_PROFILE,
        // update timestamp to enable sync
        ts: MyUtil.getUnixTimeStamp(),
        // updated profile data
        data: this.pageData.profile
    }).then((doc) => {
      // update the cache with the changed doc data
      MyUtil.cache[MyUtil.DOC_ID.USER_PROFILE] = doc.data;

      return this.appapi.clearTimestampToForceFullSync().then(() => {
        return this.appapi.syncUserAll().then(async () => {
          (await loading).dismiss();

          // update with the server returned data in cache in case server has newer profile
          this.pageData.profile = MyUtil.lodash.cloneDeep(MyUtil.getProfile());

          MyUtil.presentToast('Settings data saved.', { cssClass: 'inkpath-toast' });
        });
      });
    }).catch(async err => {
      (await loading).dismiss();

      MyUtil.error(err);
    });
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
