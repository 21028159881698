import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { ContentListItemVO } from 'src/app/valueObjects/lists.vo';
import * as appStore from '../../store';
import { MyUtil } from 'src/libs/MyUtil';
@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  // Component variables.
  brandName$: Observable<string> = this.store.select(getBrandName);
  homeOrg = null;
  // Inputs.
  @Input() listItem: ContentListItemVO;

  /**
  * Clicked event emitter.
  */
  @Output() clicked: EventEmitter<ContentListItemVO> = new EventEmitter<ContentListItemVO>();

  constructor(private store: Store<appStore.AppViewState>) { }

  ngOnInit() {
    
    this.homeOrg = MyUtil.getRootOrganization();

  }

  /** 
  * Click handler.
  * @param item   Emits item data object that has been clicked.
  */
  clickHandler(item: ContentListItemVO) {
    this.clicked.emit(item);
  }
}
