import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Store } from "@ngrx/store";
import { NgxCopilotService } from 'ngx-copilot';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralModalComponent } from 'src/app/components/general-modal/general-modal.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { MyDb } from '../../../libs/MyDb';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'page-activity-edit',
  templateUrl: 'activity-edit.html',
  styleUrls: ['activity-edit.scss']
})
export class ActivityEditPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  isMobileView = this.uiux.isMobileView();
  title = this.pageData.isCreate ? 'Create Activity' : (this.pageData.isCopy ? 'Duplicate Activity' : 'Edit Activity');
  displayPoints: boolean = false;
  pointsDisabled: boolean = true;
  pageLabel = "ActivityEditPage";
  private fromGoalEdit: boolean;

  /**
  * Get brand name from store.
  */
  brandName$: Observable<string> = this.store.select(getBrandName);

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router,
    private store: Store<appStore.AppViewState>,
    private copilot: NgxCopilotService,
    private route: ActivatedRoute,
    public navParams: NavParams,
    public uiux: UiuxService,
    public sanitiser: DomSanitizer,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    public activityService: ActivityService,
    private viewCtrl: ModalController,
    public desktopChecker: DesktopChecker,
    private titleService:Title,
    private dateAdapter: DateAdapter<Date>) 
    {
      this.titleService.setTitle("Create/Edit Activity");
    }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }


  async ngOnInit(): Promise<void> {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      if (params.pageData) {
        this.routeData = JSON.parse(params.pageData)
      }
    });

    let id = this.routeData.id;
    this.pageData.activity = null;
    if (id) {
      this.pageData.activity =  MyUtil.getActivity(id);
    }

    if (this.pageData.activity == null) {
      this.pageData.activity = {};
      this.pageData.isCreate = true;
    }

    this.title = this.pageData.isCreate ? 'Create Activity' : 'Edit Activity';
    this.titleService.setTitle(this.title);

    if (this.routeData.copy === true) {
      this.pageData.isCopy = true;
    }

    this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();
    this.fromGoalEdit = !!this.navParams.get('fromGoalEdit');

    this.pageData.homeOrg = MyUtil.getRootOrganization();
    let dateFormat = this.pageData.homeOrg.org_date_format;
    if(dateFormat.toLowerCase() == 'm/d/y') {
      this.dateAdapter.setLocale('en-US');
    }    
    
    // define activity form validation
    let startAtValue = MyUtil.getMoementFromUnixTimeStamp(this.pageData.activity.start_at).utc().format('YYYY-MM-DD');
    let endAtValue = MyUtil.getMoementFromUnixTimeStamp(this.pageData.activity.end_at).utc().format('YYYY-MM-DD');
    this.pageData.dateMin = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - 86400 * 365 * 3);
    this.pageData.dateMax = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + 86400 * 365 * 3);
    this.pageData.activityForm = this.formBuilder.group({
      name: [this.pageData.activity.name, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
      activity_template_id: [this.pageData.activity.activity_template_id, Validators.compose([Validators.required])],
      skills: [this.pageData.activity.skills, Validators.compose([Validators.required])],
      start_at: [startAtValue, Validators.compose([Validators.required])],
      start_time: [this.pageData.activity.start_time, Validators.compose([])],
      end_at: [endAtValue, Validators.compose([Validators.required])],
      end_time: [this.pageData.activity.end_time, Validators.compose([])],
      venue: [this.pageData.activity.venue, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH)])],
      provider: [this.pageData.activity.provider, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH)])],
      description: [this.pageData.activity.description, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_DESC_LENGTH)])],
      private: [this.pageData.activity.visibility],
      points: [this.pageData.activity.points, Validators.compose([Validators.pattern(/^-?([1-9]\d*)?$/)])]
    });

    //Get activity templates from back-end
    await this.appapi.getActivityTemplates().then(activityTemplates => {
      this.pageData.activityTemplates = MyUtil.lodash.orderBy(MyUtil.lodash.values(activityTemplates), [item => item.name.toLowerCase()], ['asc']);
      this.refreshFormDisplay();
    })

    // expand help for first time landing on the page
    this.pageData.displayHelpIcon = true;
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.ACTIVITY_EDIT]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.ACTIVITY_EDIT, true);
    }
    
    this.startWalkthrough()
  }

  // validate the end date/time not earlier than start date/time
  private validateEndDate(): boolean {

    let result: boolean = false;

    if (this.pageData.activityForm && this.pageData.activityForm.value) {
      let start = this.pageData.activityForm.value.start_at;
      let startFormat = 'YYYY-MM-DD';
      if (start) {
        // convert the date format
       // if (!this.pageData.isMobileAppMode) {
          start = MyUtil.getMoment(start).format('YYYY-MM-DD');
       // }
        if (this.pageData.activityForm.value.start_time) {
          start = start + ' ' + this.pageData.activityForm.value.start_time;
          startFormat = 'YYYY-MM-DD HH:mm';
        }
        start = MyUtil.getUnixTimeStampFromString(start, startFormat);
      }
      let end = this.pageData.activityForm.value.end_at;
      let endFormat = 'YYYY-MM-DD';
      if (end) {
        // convert the date format
       // if (!this.pageData.isMobileAppMode) {
          end = MyUtil.getMoment(end).format('YYYY-MM-DD');
       // }
        if (this.pageData.activityForm.value.end_time) {
          end = end + ' ' + this.pageData.activityForm.value.end_time;
          endFormat = 'YYYY-MM-DD HH:mm';
        }
        end = MyUtil.getUnixTimeStampFromString(end, endFormat);
      }

      if (start && end && end >= start) {
        result = true;
      }
    }

    return result;
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('activity-edit');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-edit', data: this.navParams.data });
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'activity-edit', data: this.navParams.data });
  }

  
  /** 
  * Actions.
  * @param actionItem   Name of action (string).
  */
  async process(action: string) {
    switch (action) {
      case 'cancel':
        this.viewCtrl.dismiss();
        return;
      case 'choose-skill':
        let activityTemplateId = this.pageData.activityForm.value.activity_template_id;
        //let activityTemplate = this.pageData.activityTemplates[activityTemplateId];
        let activityTemplate = MyUtil.lodash.find(this.pageData.activityTemplates, (item) => {
          return item.id == activityTemplateId;
        });

        if (activityTemplate) {
          this.showSkillCheckbox(activityTemplate, this.pageData.activity.skills);
        } else {
          MyUtil.presentToast('Please choose activity type first', { cssClass: 'inkpath-toast' });
        }
        return;

      case 'save-activity':
        this.pageData.activityFormSubmitAttempt = true;
        if (!this.pageData.activityForm.valid) {
          let errors = MyUtil.getFormValidationErrors(this.pageData.activityForm, {
            name: 'Name is required',
            activity_template_id: 'Type is required',
            skills: 'Skills are required',
            start_at: 'Start date is required',
            end_at: 'End date is required',
            points: 'Points value must be a whole number and greater than 0'
          });
          //MyUtil.presentToast(MyUtil.errorsToMessage(errors), { cssClass: 'error' });

          // let modal = MyUtil.createModal(GeneralModalComponent,
          //   {
          //     message: this.sanitiser.bypassSecurityTrustHtml(MyUtil.errorsToMessage(errors)),
          //     buttons: [
          //       {
          //         text: "Close",
          //       }
          //     ]
          //   });
          // (await modal).onDidDismiss().then((data: any) => { });
          // (await modal).present();

          return;
        }

        if (!this.validateEndDate()) {
          MyUtil.presentToast('The end date and time cannot be earlier than the start date and time', { cssClass: 'inkpath-toast' });
          return;
        }

        // Save form changes.
        let loading = MyUtil.presentLoading();
        this.saveActivity().then(async (savedActivityId) => {
          (await loading).dismiss();
          let activity = MyUtil.getActivity(String(savedActivityId));
          if (this.fromGoalEdit) {
            //Close modal
            this.viewCtrl.dismiss({ activity: activity });
            return;
          } else {
            if (activity) {
              // Back to detail page.
              let message = 'This activity has been updated';
              if(this.pageData.isCreate) {
                message = 'This activity has been saved and added to your personal activity list';
              }

              MyUtil.presentToast(message, 
                { duration: MyUtil.CONST.DURATION_TOAST_LONG, 
                  cssClass: 'inkpath-toast'
                }
              );

              this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: activity.id })]);
            } else {
              this.router.navigate(['/']);
            }
          }          
        }).catch(async (err) => {
          (await loading).dismiss();
          MyUtil.error(err);
        });
        return;

      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }


  /** 
  * Refresh form display.
  */
  private refreshFormDisplay() {
    // show skill names
    let programIds = MyUtil.getProfileProgramIds();
    let skills = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.SKILLS]).filter(item => (!item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0)).filter((item) => {
      return (this.pageData.activity.skills && this.pageData.activity.skills.indexOf(item.id) !== -1);
    }).map('name').join(',').value();
    this.checkForPoints(this.pageData.activityForm.value.activity_template_id, this.pageData.activityForm.value.points);
    this.pageData.activityForm.controls['skills'].setValue(skills);
  }

  /** 
  * Show skill checkbox.
  * @param template (any)
  * @param skills (any)
  */
  private showSkillCheckbox(template: any, skills: any) {
    let programIds = MyUtil.getProfileProgramIds();
    let inputs = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.SKILLS]).filter(item => (!item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0)).filter((item) => {
      return (template && template.skills && template.skills.indexOf(item.id) !== -1);
    }).orderBy([item => item.name.toLowerCase()], ['asc']).map((item) => {
      let input = {
        type: 'checkbox',
        label: item.name,
        value: item.id,
        checked: (skills && skills.indexOf(item.id) !== -1),
      };
      return input;
    }).value();

    let title = "Select max " + template.max_skills + ' skills';

    MyUtil.presentAlert({
      title: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: data => {
            if (data && data.length > 0 && data.length <= template.max_skills) {
              this.pageData.activity.skills = data;
              this.refreshFormDisplay();
            } else {
              MyUtil.presentToast(title, { cssClass: 'inkpath-toast' });
              return false;
            }
          }
        }
      ]
    });
  }

  // check whether the chosen template has a points value
  checkForPoints(templateId, points = null) {
    // we also need to clear the skills field if the activity type changes (IPD-2813)
    this.pageData.activityForm.controls.skills.setValue(null);
    
    // check whether the organisation has ugc points set
    let org = MyUtil.getRootOrganization();
    this.displayPoints = false;
    this.pointsDisabled = true;
    this.pageData.activityForm.controls.points.setValue(null);
    if (org) {
      if (org.ugc_points_system === 'user') {
        this.displayPoints = !org.override_activity_hours;
        if (points) {
          this.pageData.activityForm.controls.points.setValue(points);
        } else {
          this.pageData.activityForm.controls.points.setValue(null);
        }
        this.pointsDisabled = !!org.override_activity_hours;
      } else if (org.ugc_points_system === 'template') {
        let activityTemplate = MyUtil.getActivityTemplate(templateId);
        if (activityTemplate && activityTemplate.points > 0) {
          this.displayPoints = !org.override_activity_hours;
          this.pageData.activityForm.controls.points.setValue(activityTemplate.points);
        } else {
          this.displayPoints = false;
          this.pageData.activityForm.controls.points.setValue(null);
        }
      }
    }
  }

  /** 
  * Save activoty..
  */
  private async saveActivity() : Promise<any> {

    let activity: any = {};
    let profile: any = MyUtil.getProfile();

    // generate an id if new activity or if creating a copy
    if (!this.pageData.activity.id || (this.routeData.copy === true)) {
      this.pageData.activity.id = MyUtil.newUUID();
    }

    // set proper oid
    if (!this.pageData.activity.oid) {
      this.pageData.activity.oid = profile.oid;
    }

    // set proper profile_id
    if (!this.pageData.activity.profile_id) {
      this.pageData.activity.profile_id = profile.id;
    }

    // copy values from activity or form
    activity.id = this.pageData.activity.id;
    let startAt = this.pageData.activityForm.value.start_at;
    if (typeof startAt === 'string') {
      activity.start_at = MyUtil.getUnixTimeStamp(startAt);
    } else {
      activity.start_at = MyUtil.getUnixTimeStamp(startAt.format('YYYY-MM-DD'));
    }
    activity.start_time = this.pageData.activityForm.value.start_time;
    let endAt = this.pageData.activityForm.value.end_at;
    if (typeof endAt === 'string') {
      activity.end_at = MyUtil.getUnixTimeStamp(endAt);
    } else {
      activity.end_at = MyUtil.getUnixTimeStamp(endAt.format('YYYY-MM-DD'));
    }
    activity.end_time = this.pageData.activityForm.value.end_time;

    activity.name = this.pageData.activityForm.value.name;
    activity.activity_template_id = this.pageData.activityForm.value.activity_template_id;
    activity.venue = this.pageData.activityForm.value.venue;
    //Missing: joining link
    activity.provider = this.pageData.activityForm.value.provider;
    activity.description = this.pageData.activityForm.value.description;
    //Missing: important info
    activity.visibility = this.pageData.activityForm.value.private ? 1 : 0;
    activity.points = this.pageData.activityForm.value.points ? this.pageData.activityForm.value.points : null;
    activity.skills = this.pageData.activity.skills;
   
    // Save the activity (API also adds to "My Activities" if not already added)
    let savedActivityId = 0;
    await this.appapi.saveUserGeneratedActivity(activity).then(result => {
      savedActivityId = result;
    });

     //Resync cache
    await this.appapi.syncSingleActivity(savedActivityId);
    return savedActivityId;
  }
  

  /** 
  * On start at change.
  */
  public onStartAtChange() {
    if (this.pageData.activityForm.value.end_at === 'Invalid date') {
      this.pageData.activityForm.controls.end_at.setValue(this.pageData.activityForm.value.start_at);
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }



  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('21');
      }, 1000);
    }
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }
}
