import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../providers/appapi';

@Injectable({
  providedIn: 'root'
})
export class GuestGuardService {

  constructor(
    public readonly appapi: Appapi,
    private readonly router: Router
  ) { }

 async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean> | boolean> {
    let canAccess = await MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser => {
      let isLoggedIn = (appUser && appUser.api_token);
      if(!isLoggedIn){
        return true;
      }else{
        this.router.navigate(['/home']);
        return false;
      }
    }).catch(error => {
      return true;
    })
    
    if(!canAccess){
      return true;
    }
    return canAccess;
    

  }
  
}
