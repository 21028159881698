import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from "../appapi";
import { Store } from '@ngrx/store';
import * as appStore from '../../store';
import { OnboardingService } from '../onboarding/onboarding-service';
import { OnboardingReminderModal } from 'src/app/components/onboarding-reminder/onboarding-reminder-modal';

/*
  Generated class for the AuthProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class AuthProvider {

  constructor(public appapi: Appapi,
    private zone: NgZone,
    private store: Store<appStore.AppViewState>,
    public onboardingService: OnboardingService,
    public router: Router) {
  }

  loginUser(userData, switchingOrg: boolean = false) {

    // checked for locked accounts
    if (userData.status === "0" || userData.status === 0) {
      MyUtil.presentToast('Your account is currently blocked. Please contact your organisation administrator.', { cssClass: 'inkpath-toast' });

      return this.appapi.forgetAppUser(true).then(() => {
        userData = {};
        this.router.navigate(['/reload-login']);
      });
    }

    let loading = MyUtil.presentLoading();
    MyUtil.firebaseLogEvent('login_success', { 'email': userData.email });

    let appUser: any = { _id: MyUtil.DOC_ID.APP_USER };
    MyUtil.lodash.merge(appUser, userData);
    //MyUtil.saveToLocalStorage('localOid', appUser.oid);

    MyDb.appSave(appUser).then(userDoc => {

      return this.appapi.initializeAppUser().then(async (user) => {
        if (this.appapi.isLoggedIn()) {
          this.appapi.syncUserAll().then(async () => {
            (await loading).dismiss();
            MyUtil.saveToLocalStorage('reflectionReminderSeen', "0");
            this.router.navigate(['/home']);
            return;
          });
        }
      });

    }).catch(async err => {
      (await loading).dismiss();
      MyUtil.error(err);
    })
  }

  /**
   * Auth for the RSC login page
   * @param userData 
   * @param loading 
   */
  loginRscUser(userData, loading = null) {

    MyUtil.firebaseLogEvent('login_success', { 'email': userData.email });

    let appUser: any = { _id: MyUtil.DOC_ID.APP_USER };
    MyUtil.lodash.merge(appUser, userData);
    MyUtil.saveToLocalStorage('localOid', userData.oid);

    MyDb.appSave(appUser).then(userDoc => {

      return this.appapi.initializeAppUser().then(async (user) => {
        if (this.appapi.isLoggedIn()) {
          this.appapi.syncUserAll().then(async () => {
            (await loading).dismiss();
            MyUtil.saveToLocalStorage('reflectionReminderSeen', "0");
            this.router.navigate(['/home']);
            return;
          });
        }
      });

    }).catch(async err => {
      (await loading).dismiss();
      MyUtil.error(err);
    });
  }

  logout() {
    MyUtil.presentAlert({
      title: 'Confirm logout',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Logout',
          handler: () => {
            this.appapi.forgetAppUser(true).then(() => {
              MyUtil.presentToast('Logged out successfully!', { cssClass: 'inkpath-toast' });

              // handle log out if for the RSC
              let hostname = window.location.hostname;

              if (hostname === MyUtil.context.RSC_URL) {
                MyUtil.removeFromLocalStorage('rsc-session');
                this.router.navigate(['/rsc/auth']);
              } else {
                this.router.navigate(['/LoginPage']);
              }

            });
          }
        }
      ],
    });
  }



}
