import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store } from "@ngrx/store";
import { NgxCopilotService } from 'ngx-copilot';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { MyDb } from '../../../libs/MyDb';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';
import { ActivitySelectPage } from '../activity-select/activity-select';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-goal-edit',
  templateUrl: 'goal-edit.html',
  styleUrls: ['goal-edit.scss'],
})
export class GoalEditPage implements OnInit, OnDestroy{

  // Component variables.
  @ViewChild('Content', {static: true}) content;
  pageData: any = {};
  routeData: any = {};
  pageLabel = "GoalEditPage"; 
  isMobileView = this.uiux.isMobileView();
  title = '';

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, 
    private  copilot:  NgxCopilotService,
    private route: ActivatedRoute,
    private store: Store<appStore.AppViewState>,
    public navParams: NavParams, 
    public formBuilder: UntypedFormBuilder,
    public uiux: UiuxService,
    public appapi: Appapi, 
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Create/Edit Goal");
      
  }

  ngOnInit(): void {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });
    
    this.pageData.goal = MyUtil.getGoal(this.routeData.id);
    
    if (this.pageData.goal == null) {
      this.pageData.goal = {};
      this.pageData.isCreate = true;
    }
    this.pageData.userGoalDoc = null;

    this.title = this.pageData.isCreate ? 'Create Goal' : 'Edit Goal';
    this.titleService.setTitle(this.title);

    // define goal form validation
    this.pageData.goalForm = this.formBuilder.group({
      name: [this.pageData.goal.name, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
      activities: [null, Validators.compose([Validators.required])],
      description: [this.pageData.goal.description, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_DESC_LENGTH), Validators.required])]
    });

    this.refreshFormDisplay();

    this.queryUserGoal(this.pageData.goal.id).then((doc) => {
       this.pageData.userGoalDoc = doc;
    });

    MyUtil.firebaseSetScreenName('goal-edit');
    MyUtil.firebaseLogEvent('view_did_enter', {name: 'goal-edit', data: this.navParams.data});

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.GOAL_EDIT]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.GOAL_EDIT, true);
    }
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', {name: 'goal-edit', data: this.navParams.data});
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  async process(action: string, item?: any) {
    switch (action) {
      case 'choose-activity':
        let activitySelectModal = MyUtil.createModal(ActivitySelectPage, { fromGoals: true, selected:  this.pageData.goal.activities });
        (await activitySelectModal).onDidDismiss().then(async (data:any) => {
          
          if (data && data.data !== null) {
            this.pageData.goal.activities = data.data;
            await this.refreshFormDisplay();
          }
          
        });
        (await activitySelectModal).present();
        break;

      case 'save-goal':
        this.pageData.goalFormSubmitAttempt = true;
        if (!this.pageData.goalForm.valid || this.pageData.invalidActivities) {
          let errors = MyUtil.getFormValidationErrors(this.pageData.goalForm, {
            name: 'Name is required',
            activities: 'Activities are required',
            description: 'Description is required',
          });
          MyUtil.presentToast(MyUtil.errorsToMessage(errors), { cssClass: 'inkpath-toast' });
          break;
        }

        // Save form changes.
        this.saveGoal().then(() => {
          // Back to detail page.
          let goal = MyUtil.getGoal(this.pageData.goal.id);
          if (goal) {
            this.router.navigate(['/GoalDetailPage', goal.id]);
          } 
        }).catch((err) => {
          MyUtil.error(err);
        });
        break;

      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        break;
    }
  }

  /** 
  * Refresh form display.
  */
  private refreshFormDisplay() {
    // Show activity names.
    let activities: any = [];
    MyUtil.lodash.forEach(this.pageData.goal.activities, (id) => {
      if(id){
          let activity = MyUtil.getActivity(id);
          if (activity) {
            activities.push(activity);
          }
      }
    })
    this.pageData.invalidActivities = false;
    activities = MyUtil.lodash.chain(activities).map('name').join(', ').value();
    this.pageData.goalForm.controls.activities.setValue(activities);
  }

  /** 
  * Query user goal.
  * @param id (any)
  */
  private queryUserGoal(id): any {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.USER_GOAL, id],
      include_docs: true
    };

    return MyDb.userQuery('by_type_goal_id', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);
      let resultDoc = null;

      if (docs && docs.length > 0) {
        resultDoc = docs[0];
      }

      return resultDoc;
    });
  }

  /** 
  * Save Goal.
  */
  private saveGoal() {

    let goal: any = {};

    let profile: any = MyUtil.getProfile();

    // generate an id if new goal
    if (!this.pageData.goal.id) {
      this.pageData.goal.id = MyUtil.newUUID();
    }
    // set proper oid
    if (!this.pageData.goal.oid) {
      this.pageData.goal.oid = profile.oid;
    }
    // set proper profile_id
    if (!this.pageData.goal.profile_id) {
      this.pageData.goal.profile_id = profile.id;
    }

    // copy values from goal or form
    goal.id = this.pageData.goal.id;
    goal.oid = this.pageData.goal.oid;
    goal.profile_id = this.pageData.goal.profile_id
    goal.name = this.pageData.goalForm.value.name;
    goal.activities = this.pageData.goal.activities;
    goal.description = this.pageData.goalForm.value.description;
    goal.priority = MyUtil.CONST.APP_META.GOAL_PRIORITY_OPTIONAL;
    goal.calc_type = MyUtil.CONST.APP_META.GOAL_CALC_TYPE_ATTEND_COUNT;
    goal.attend_count = this.pageData.goal.activities.length;

    // save the goal
    return MyDb.userLoad(MyUtil.DOC_ID.GOALS).then((goalsDoc: any) => {
      
        // initialise empty goals list
        if (!goalsDoc.data) {
          goalsDoc.data = {};
        }
        goalsDoc.data[goal.id] = goal;
        return MyDb.userSave(goalsDoc).then((goalsDoc: any) => {
     
          // update cache
          MyUtil.cache[goalsDoc._id] = goalsDoc.data;

          if (!this.pageData.userGoalDoc) {
            this.pageData.userGoalDoc = {};
            // init type and data
            this.pageData.userGoalDoc.type = MyUtil.DOC_TYPE.USER_GOAL;
            this.pageData.userGoalDoc.data = {
              goal_id: this.pageData.goal.id,
              status: MyUtil.CONST.APP_META.USER_GOAL_STATUS_ACTIVE,
              started_at: MyUtil.getUnixTimeStamp()
            }
          }

          // remove delete flag if any
          delete (this.pageData.userGoalDoc.delete);

          // remove ts if any for sync again
          delete (this.pageData.userGoalDoc.ts);

          // update date/time?
          this.pageData.userGoalDoc.data.start_at = goal.start_at;
          this.pageData.userGoalDoc.data.end_at = goal.end_at;
          // cache for data sync
          this.pageData.userGoalDoc.data.updated_goal = goal;

          return MyDb.userSave(this.pageData.userGoalDoc).then((doc) => {
      
            this.pageData.userGoalDoc = doc;
            // attempt server update
            return this.appapi.saveUserGoals().then((result) => {
              if(!!result) {
                MyUtil.presentToast('This goal has been added to your personal goal list', {duration: MyUtil.CONST.DURATION_TOAST_LONG, cssClass: 'inkpath-toast'});

                // load back to get corrent id
                return MyDb.userLoad(this.pageData.userGoalDoc._id).then((doc) => {
                  if (doc) {
                    this.pageData.userGoalDoc = doc;
                    this.pageData.goal.id = doc.data.goal_id;
                  }
                });
              }
            });
          }).catch(() => {
            this.pageData.userGoalDoc = null;
          });
        });
    });
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
