import { Injectable } from '@angular/core';
import { Appapi } from "./appapi";

@Injectable({
  providedIn: 'root',
})
export class FileService {

  constructor(public appapi: Appapi) {

  }

  uploadEvidenceFile(fileToUpload: File, meta: any): Promise<any> {
    
    const uri: string = '/upload/evidence';
    const formData: FormData = new FormData();

    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    } else {
      formData.append('image_data', meta.image_data);
    }
    formData.append('description', meta.description);
    formData.append('user_activity_id', meta.user_activity_id);

    return this.appapi.post(uri, formData);
  }

  retrieveEvidences(user_activity_id: any): Promise<any> {
    const uri: string = '/user-activity/' + user_activity_id + '/evidence';

    return this.appapi.get(uri, {}).then(result => {
      let data = null;      
      if (result['#status'] == 'success') {
        data = result['#data'];
      }
      return data;
    }).catch(err => {
      return [];
    });
  }

  removeEvidences(user_activity_id: any, file_id: any): Promise<any> {
    const uri: string = '/user-activity/' + user_activity_id + '/delete-evidence/' + file_id;

    return this.appapi.get(uri, {});
  }  
}
