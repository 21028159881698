import { Injectable } from "@angular/core";
import { MyDb } from "src/libs/MyDb";
import { MyUtil } from "src/libs/MyUtil";
import { Appapi } from "./appapi";

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(public appapi: Appapi) {}



  public saveBooking(formData: any): Promise<any> {

    let apiUrl = "/save/booking";
    if(formData['convert_booking']) {
      apiUrl = "/convert/booking";
    }

    return this.appapi
      .post(apiUrl, formData)
      .then((result) => {
        let data = [];
        if (result["#status"] === "success") {
          data = result["#data"];
        } else if (result["#status"] === "failed") {
          MyUtil.presentToast(
            MyUtil.responseToMessage(result["#message"], result["#errors"]), { cssClass: 'inkpath-toast' }
          );
          return null;
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
      });
  }


  public cancelBooking(id, labelKey): Promise<any> {
    return this.appapi
      .post("/cancel/booking", {
        bookable_id: id,
        cancel_reason: labelKey,
      })
      .then((result) => {
        let data = [];
        if (result["#status"] === "success") {
          data = result["#data"];
        } else if (result["#status"] === "failed") {
          MyUtil.presentToast(
            MyUtil.responseToMessage(result["#message"], result["#errors"]), { cssClass: 'inkpath-toast' }
          );
          return null;
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
      });
  }

  public isValidUrl(joiningLink: string) {
    let isUrl = false;
    try {
      let url = new URL(joiningLink);
      if (url) {
        isUrl = true;
      }
    } catch (error) {
      //do nothing
    }
    return isUrl;
  }

  public openJoiningLink(joiningLink: string) {
    if (this.isValidUrl(joiningLink)) {
      let browser = MyUtil.createInAppBrowser(joiningLink, "_system");
      browser.show;
    }
  }

  
}
