import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiuxService {

  constructor() { }
  /*
  *********************************************************************************************************
  * 	Set CSS Transition eg. this.setTransition(this.animation, [["all", 1200, "normal"]], 600, 600);
  * 	(viewChild, [
  *                 ["cssToTransition", TransitionTime, "normal/reverse", delayTime] // < Multiple CSS transitions
  *                 ["cssToTransition", TransitionTime, "normal/reverse", delayTime] // < and options
  *               ], pauseBetweenTime, initialDelayTimeForAll);
  *********************************************************************************************************
  */
  setTransition(elems, properties, dlyTime, offset) {
    elems = elems;
    let elementCount = elems.length;
    let maxDelay = (elementCount * dlyTime) + offset;

    elems.forEach((element, k) => {
      const htmlElement = element.nativeElement as HTMLElement;
      maxDelay = maxDelay - dlyTime;
      let css = "", dlyReverse = maxDelay + "ms";
      let dlyNormal = ((k * dlyTime) + offset) + "ms";

      for(var i = 0; i < properties.length; i++) {
        let p = properties[i];
        let end = (i != (properties.length - 1)) ? ',' : '';
        let dly = 0;
        let cases = {"normal": dlyNormal,"reverse": dlyReverse,"none": "0ms"};
        
        if (cases[p[2]]) {
          dly = cases[p[2]];
        }

        if(p[3]) {
          dly = p[3]
        }

        css += ' ' + p[0] + " " + p[1] + 'ms ' + dly + end;
      }

      let style = `
          -webkit-transition: ${css};
          -moz-transition: ${css};
          -ms-transition: ${css};
          -o-transition: ${css};
          transition: ${css};
      `;

      htmlElement.setAttribute("style", style)
    })
  }

  isMobileView(){
    return (window.innerWidth < 768);
  }

  
}