import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Appapi } from 'src/app/providers/appapi';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  showContact = false;
  brandName$: Observable<string> = this.store.select(getBrandName);
  branding = null;
  profileOrg = {
    public_contact_email: '',
    name: '',
    id: ''
  };
  loaded = false;

  constructor(
    public appapi: Appapi,
    public viewCtrl: ModalController,
    private store: Store<appStore.AppViewState>,
  ) { }

  ngOnInit() {
    
    this.loaded = false;
    this.profileOrg = MyUtil.getRootOrganization();
    this.profileOrg['mailString'] = `mailto:${this.profileOrg.public_contact_email}`;
    this.loaded = true;
  }
  

  sendAccountDeletionRequest() {
    const alert = MyUtil.presentAlert({
      title: 'Confirm Account Deletion Request',
      message: "Are you sure you want to delete your account? Your request will be passed to the associated organisation.",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            return;
          }
        },
        {
          text: 'Confirm',
          handler: () => {
            this.appapi.deleteUserRequest().then( (message) => {
              MyUtil.presentToast(message, { cssClass: 'inkpath-toast', duration: MyUtil.CONST.DURATION_TOAST_LONG });
            });
          }
        }
      ]
    });
  }

  // Close modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }
}
