import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { NavParams, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { NgxCopilotService } from 'ngx-copilot';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { RedirectProvider } from 'src/app/providers/redirect/redirect';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { ContentListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import {Title} from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';
import { OnboardingReminderModal } from 'src/app/components/onboarding-reminder/onboarding-reminder-modal';
import { OnboardingService } from 'src/app/providers/onboarding/onboarding-service';

@Component({
  selector: 'page-activities',
  templateUrl: 'activities.html',
  styleUrls: ['activities.scss']
})

export class ActivitiesPage implements OnInit, OnDestroy, AfterViewInit {

  routeData: any = {};
  navigated: boolean = false;
  currentRoute: string = '';

  constructor(
    private copilot: NgxCopilotService,
    public router: Router,
    public navParams: NavParams,
    public appapi: Appapi,
    public httpClient: HttpClient,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public coursesService: CoursesService,
    private file: File,
    private walkthroughService: WalkthroughService,
    public redirectProvider: RedirectProvider,
    public uiux: UiuxService,
    private store: Store<appStore.AppViewState>,
    private nativeHTTP: HTTP,
    public platform: Platform,
    private fileOpener: FileOpener,
    public onboardingService: OnboardingService,
    private titleService:Title) {
      
      this.titleService.setTitle("My Activities");
   }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  component = ListItemComponent;
  @ViewChildren('animation') private animation: QueryList<ElementRef>;
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  fileTransfer: FileTransferObject;
  myParam: string;
  displayHelpIcon = true;
  isMobileView = this.uiux.isMobileView()
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  upcomingCount$: BehaviorSubject<number> = new BehaviorSubject(-1);
  pastCount$: BehaviorSubject<number> = new BehaviorSubject(-1);
  completedCount$: BehaviorSubject<number> = new BehaviorSubject(-1);
  activeTab = 'upcoming';
  pageLabel = 'ActivitiesPage';
  upcomingContentList$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);
  pastContentList$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);
  completeContentList$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);
  animate: boolean = false;
  brandName$: Observable<string> = this.store.select(getBrandName);


  // After view init.
  ngAfterViewInit() {
    setTimeout(() => {
      this.uiux.setTransition(this.animation, [["all", 350, "normal"]], 50, 0);
      this.animate = true;
    }, 700);
  }

  // On init
  async ngOnInit() {

    this.loading$.next(true);

    if(this.router.url == '/home') {
      //Redirect from the app.component page - redirect again to the default url
      this.router.navigate(['/']);
      return;
    }

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    // check if app must be updated
    let upgradeMessage = null;
    await this.appapi.syncApp();
    upgradeMessage = MyUtil.getUpgradeMessage();
    if (upgradeMessage !== null) {
      // show alert
      MyUtil.presentAlert({
        title: 'App Requires Update',
        message: upgradeMessage,
        buttons: [
          {
            text: 'Ok',
          }
        ]
      });

      //Log user out
      this.appapi.forgetAppUser(true);
      this.router.navigate(['/LoginPage']);
      return;
    }

    //Check if user needs to complete profile, do onboarding etc 
    this.pageData.homeOrg = MyUtil.getRootOrganization();
    await this.checkForOnboardingRedirects().then((redirect) => {
      if(redirect) {
        return;
      }
    });

    this.navigated = true;

    // Sync and get activities and courses.
    this.appapi.syncAllActivitiesAndCourses().then(async () => {

      //Check for direct link
      if(this.redirectProvider.processRedirectTarget(this.router, null)) {
        return;
      }
        
      let upcomingList: ContentListItemVO[] = [];
      let pastList: ContentListItemVO[] = [];
      let completedList: ContentListItemVO[] = [];

      //Add user's activities to content list
      let nowTimestamp = MyUtil.getUnixTimeStamp();
      let userActivities = MyUtil.cache[MyUtil.DOC_ID.USER_ACTIVITIES];
      let clashableActivities = MyUtil.getClashableActivities();
      MyUtil.lodash.forEach(userActivities, (userActivity, activityId) => {

        let activity = MyUtil.getActivity(activityId.toString());
        if(activity) {
          //Exclude course activities
          if(!activity.course_id) {
            //Work out if this activity clashes with anything the user has also booked
            let activityBookingClash = MyUtil.getActivityClashingState(activity, clashableActivities);

            //Human-readable date range
            let period = MyUtil.getShortDisplayDateTime(this.pageData.homeOrg, 
              activity.start_at, 
              activity.start_time, 
              activity.end_at, 
              activity.end_time);

              //Format recommended skill
            activity.recommended_skill = '';
            if(activity.recommended_for_skill) {
              let skill = MyUtil.getSkill(activity.recommended_for_skill);
              if(skill) {
                activity.recommended_skill = skill.name;
              }
            }

            let activityItem: ContentListItemVO = {
              id: activityId,
              oid: activity.oid,
              is_course: false,
              name: activity.name,
              internal_reference: activity.internal_reference,
              description: '',
              profile_id: activity.profile_id,
              is_booked: userActivity.is_booked,
              on_waiting_list: userActivity.on_waiting_list,
              on_application_list: userActivity.on_application_list,
              added_not_booked: activity.booking_via_inkpath && !userActivity.has_reservation,
              completed_at: userActivity.completed_at,
              provider: activity.provider,
              period: period,
              start_ts: activity.start_ts,
              end_ts: activity.end_ts,
              recommended_skill: activity.recommended_skill,
              attendance_type_id: activity.attendance_type_id,
              is_self_study: activity.is_self_study,
              is_type_other: activity.is_type_other,
              badge_details: activity.badge_details,
              price: activity.price,
              skills: [],
              booking_clash: activityBookingClash,
              currency_symbol: activity.currency_symbol,
            }
            
            if (!activityItem.completed_at && activityItem.end_ts >= nowTimestamp) {
              upcomingList.push(activityItem);
            } else if (!activityItem.completed_at && activityItem.end_ts < nowTimestamp) {
              pastList.push(activityItem)
            } else if (!!activityItem.completed_at && activityItem.completed_at > 0) {
              completedList.push(activityItem);
            }
          }             
        }
      });

      //Add user's courses to content list
      let userCourses = MyUtil.cache[MyUtil.DOC_ID.USER_COURSES];
      MyUtil.lodash.forEach(userCourses, (userCourse, courseId) => {

        let course = MyUtil.getCourse(courseId.toString());
        if(course) {
          //Work out if any of the user's booked course activities clash with anything else the user has also booked
          let courseBookingClash = MyUtil.getCourseClashingState(course, clashableActivities);

          //Human-readable date range
          let period = MyUtil.getShortDisplayDateTime(this.pageData.homeOrg, 
            course.start_at, 
            course.start_time, 
            course.end_at, 
            course.end_time);

          let courseItem: ContentListItemVO = {
            id: courseId,
            oid: course.oid,
            is_course: true,
            name: course.name,
            internal_reference: course.internal_reference,
            description: '',
            profile_id: null,
            is_booked: userCourse.is_booked,
            on_waiting_list: userCourse.on_waiting_list,
            on_application_list: userCourse.on_application_list,
            added_not_booked: false,
            completed_at: userCourse.completed_at,
            provider: course.provider,
            period: period,
            start_ts: course.start_ts,
            end_ts: course.end_ts,
            recommended_skill: '',
            attendance_type_id: null,
            is_self_study: false,
            is_type_other: false,
            badge_details: course.badge_details,
            price: course.price,
            skills: [],
            booking_clash: courseBookingClash,
            currency_symbol: course.currency_symbol,
          }

          if (!courseItem.completed_at && courseItem.end_ts >= nowTimestamp) {
            upcomingList.push(courseItem);
          } else if (!courseItem.completed_at && courseItem.end_ts < nowTimestamp) {
            pastList.push(courseItem)
          } else if (!!courseItem.completed_at && courseItem.completed_at > 0) {
            completedList.push(courseItem);
          }
        }
      });

      //Sort by date
      upcomingList.sort((a:any, b:any) => a.start_ts - b.start_ts);
      this.upcomingCount$.next(upcomingList.length);
      this.upcomingContentList$.next(upcomingList);

      //Sort by reverse date
      pastList.sort((a:any, b:any) => b.start_ts - a.start_ts);
      this.pastCount$.next(pastList.length);
      this.pastContentList$.next(pastList);

      //Sort by reverse date
      completedList.sort((a:any, b:any) => b.start_ts - a.start_ts);
      this.completedCount$.next(completedList.length);
      this.completeContentList$.next(completedList);

      this.loading$.next(false);
    });

    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activities', data: this.navParams.data });
    MyUtil.firebaseSetScreenName('activities');

    this.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();

    // Activate walkthrough if not disabled.
    this.startWalkthrough();
  }



  /** 
   * Check if user needs to complete profile, do onboarding etc 
   */
  async checkForOnboardingRedirects() : Promise<any> {

    if (!MyUtil.validateProfile()) {
      this.router.navigate(['/UserSettingsPage', JSON.stringify({ "redirect": 'new-profile' })]);
      return true;
    }

    await this.appapi.checkAndRefreshProfile();
    this.pageData.profile = MyUtil.getProfile();

    //If onboarding enabled for org, check if user has completed it
    let gotoOnboarding = false;
    if (this.pageData.homeOrg.onboarding_enabled && this.pageData.profile.show_onboarding) { 
      //Check if there are any slides (skip if not)
      await this.onboardingService.getOnboardingPages().then( (response) => {
        if (response && response.pages && response.pages.length > 0) {
          this.router.navigate(['/OnboardingPage', '{}']);
          gotoOnboarding = true;
        }
      });

      if (gotoOnboarding) {
        return true;
      }
    };

    //Check if reflections need doing again
    if (!this.pageData.homeOrg.hide_onboarding_reflection && this.pageData.profile.onboarding_reminder) {
      //Do once on login only - check if user has already seen reminder this session
      let reflectionReminderSeen = MyUtil.retrieveFromLocalStorage('reflectionReminderSeen');
      if (reflectionReminderSeen != "1") {
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        let reminderDate = new Date(this.pageData.profile.onboarding_reminder.next_reminder);
        reminderDate.setHours(0, 0, 0, 0);
        // check reminder date.
        if (reminderDate <= now && this.pageData.profile.onboarding_reminder.reminder_sent == 0) {
          // check if we want to display the onboarding / reflection reminder.
          let onboardingReminderModal = MyUtil.createModal(OnboardingReminderModal, {
            title: "Reflection Reminder",
            message:
              "Please visit your previous onboarding reflection to make any updates.",
            buttons: [
              {
                text: "Close",
              },
              {
                text: "Revise Reflection",
              },
            ]
          });

          //Record that user has seen reminder this session
          MyUtil.saveToLocalStorage('reflectionReminderSeen', "1");
          let gotoReflections = false;
          (await onboardingReminderModal).present();
          (await onboardingReminderModal).onDidDismiss().then((labelKey) => {
            // Sync user profile to get updated reminder or we get stuck in a reminder loop.
            this.appapi.freshSyncUserProfile();
            if (labelKey.data !== undefined) {
              // Redirect to the reflections screen.
              this.router.navigate(['/ReflectionPage', JSON.stringify({ reminder: true })]);
              gotoReflections = true;
            }
          });

          if (gotoReflections) {
            return true;
          }
        }
      }
    }

    return false;
  }


  /** 
  * Set walkthrough state. 
  * @param pageName   Name of page.
  * @param value      Boolean - (Has been visited or not).
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value);
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition(stepNumber: string) {
    this.copilot.checkInit(stepNumber);
  }

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep(stepNumber: any) {
    try {
      this.copilot.next(stepNumber);
    } catch(err) {
      console.log(err);
    }
  }

  /** 
  * Finish copilot walkthroughs.
  */
  done() {
    this.copilot.removeWrapper();
  }

  // Start walkthrough.
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('1');
        // setTimeout(() => {
        //   document.getElementById('skip').focus();
        // }, 1000);
      }, 1000);
    }
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  async process(action: string, item?: any) {
    switch (action) {

      // View activity list.
      case 'view-activity-list':
        this.router.navigate(['/ActivityListPage', '{}']);
        return;

      // View activity details.
      case 'view-activity-details':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;

      case 'view-course-details':
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ id: item.id })]);
        return;

      // Create activity.
      case 'create-activity':
        if (!this.pageData.loading) {
          this.router.navigate(['/ActivityEditPage', JSON.stringify({})]);
        }
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  // Export XLS or PDF version of activities list
  exportActivitiesSelection() {

    var buttons = [
      {
        text: 'Export XLS',
        handler: (data: any) => {
          let url = this.appapi.getActivityExport('export-activities', data);
          this.exportActivities(url, '.xlsx');
        }
      },
      {
        text: 'Export PDF',
        handler: (data: any) => {

          if (this.validateDates(data)) {
            let url = this.appapi.getActivityExportPDF('export-activities', data);
            this.exportActivities(url, '.pdf');
          }

        }
      }
    ];

    if (this.pageData.homeOrg.allow_pdf_transcript == 0) {
      buttons = [
        {
          text: 'Export XLS',
          handler: (data: any) => {
            let url = this.appapi.getActivityExport('export-activities', data);
            this.exportActivities(url, '.xlsx');
          }
        },
      ]
    }

    let dateFormat = MyUtil.formatPHPDate(this.pageData.homeOrg.org_date_format);
    MyUtil.presentAlert({
      title: 'Export Activities',
      message: "Please choose your date range (leave blank for 'all time') and export option.",
      inputs: [
        {
          type: 'date',
          name: 'date_from',
          label: 'Date From',
          placeholder: dateFormat
        },
        {
          type: 'date',
          name: 'date_to',
          label: 'Date To',
          placeholder: dateFormat
        }
      ],
      buttons: buttons
    });

  }


  validateDates(data) {

    let dateFrom = Date.parse(data.date_from);
    let dateTo = Date.parse(data.date_to);
    let today = Date.now();

    if (dateFrom > today || dateTo > today) {
      let alert = MyUtil.presentAlert({
        title: 'Error',
        message: "Dates should be before today's date",
        buttons: [
          {
            text: 'OK',
          }
        ]
      });

      return false;
    }

    if (dateTo < dateFrom) {
      let alert = MyUtil.presentAlert({
        title: 'Error',
        message: "Please choose a 'To Date' which is later than the 'From Date'!",
        buttons: [
          {
            text: 'OK',
          }
        ]
      });

      return false;
    }

    return true;

  }


  async exportActivities(url, type) {
    let loading = MyUtil.presentLoading();

    if (MyUtil.isMobileAppMode()) {

      var fileSpec = '';
      var fileName = '';
      if (type === '.pdf') {
        fileName = 'My-Activities.pdf';
        fileSpec = 'application/pdf';
      } else {
        fileName = 'My-Activities.xlsx';
        fileSpec = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }

      let filePath = (this.platform.is('ios')) ? this.file.documentsDirectory + fileName : this.file.dataDirectory + fileName;

      this.nativeHTTP.downloadFile(url, {}, {}, filePath).then((entry) => {
        MyUtil.debug('download complete: ' + filePath);

        this.fileOpener.open(filePath, fileSpec).then(
          async () => {
            (await loading).dismiss();
            MyUtil.debug('File opened');
          }, async (err) => {
            (await (loading)).dismiss();
            MyUtil.debug('File to open');
            MyUtil.debug(JSON.stringify(err));
          }

        );
      }, async (error) => {
        (await loading).dismiss();
        MyUtil.debug(error);
      }
      );

    } else {
      // Browser download
      (await loading).dismiss();
      this.httpClient.get(url, {
        responseType: "blob"
      }).subscribe(
        (res) => {
          var fileURL = window.URL.createObjectURL(res);
          let tab = window.open();
          tab.location.href = fileURL;
        },
        (err) => {
          console.log('HTTP Error', err)
        },
        () => console.log('HTTP request completed.')
      )
    }
  }


  /** 
  * For mobile view tabs.
  * @param label   Label of tab.
  */
  changeTab(label: string) {
    this.activeTab = label;
  }

  /** 
  * Click handler for list items.
  * @param item   Item of list item clicked (VO of item).
  */
  clickHandler(item) {
    return !item.is_course ? this.process('view-activity-details', item) : this.process('view-course-details', item);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'about', data: this.navParams.data });
  }
}
