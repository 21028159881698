import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { DiagnosticService } from 'src/app/providers/diagnostic/diagnostic-service';
import { MyUtil } from 'src/libs/MyUtil';
import { ReflectionCompleteModal } from '../../components/reflection-complete-modal/reflection-complete-modal';
import { Appapi } from "../../providers/appapi";
import { DesktopChecker } from "../../providers/desktopChecker";
import { ReflectionService } from '../../providers/onboarding/reflection-service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-reflection',
  templateUrl: 'reflection.html',
  styleUrls: ['reflection.scss'],
})
export class ReflectionPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  pageTitle: string = '';
  pageHelpText: string;
  loading: any;
  hideForm: boolean = true;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public reflectionService: ReflectionService,
    public navCtrl: NavController,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    public diagnosticService: DiagnosticService,
    private titleService:Title) {
      this.titleService.setTitle("Reflection");
  }

  ngOnInit(): void {

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.REFLECTION]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.REFLECTION, true);
    }

    this.pageData.questions = [];
    this.pageData.reflectionSubmission = {};
    this.pageData.historicSubmissions = [];
    this.pageData.reflectionSubmission.reflection = "";
    this.pageData.reflectionSubmission.reflectionReminder = "";
    this.pageData.reflectionSubmission.questions = [];
    this.pageData.pageTitle = this.pageTitle;
    this.pageData.pageHelpText = this.pageHelpText;

    let profile = MyUtil.getProfile();
    if(profile) {
      this.pageData.profileName = profile.first_name;
    }

    this.getHistoricData(profile).then(resp => {
      if (resp && resp.length > 0) {
        this.pageData.historicSubmissions = resp;
        this.hideForm = true;

        this.pageData.historicSubmissions.forEach(item => {
          let dte = MyUtil.getUnixTimeStampFromString(item.created_at, 'YYYY-MM-DD HH:mm');
          item.created_at = MyUtil.formatUnixTimeStampDate(dte);
        });

      } else {
        this.hideForm = false;
      }

    });

    this.getQuestions().then(response => {
      if (response && response.skills && response.skills.length > 0) {
        this.pageData.questions = response.skills;
      }

      if (response && response.settings) {
        this.pageData.pageTitle = response.settings.title;
        this.pageData.pageHelpText = response.settings.help_text;
        this.pageData.onboardingReminderText = response.settings.onboarding_reminder_text;
      }
    });
  }

  /** 
  * Get historic data..
  * @param profile 
  */
  async getHistoricData(profile) {
    return await this.reflectionService.getHistoricOnboardingReflectionQuestionsAndAnswers(profile);
  }

  /** 
  * Get questions.
  */
  async getQuestions() {
    return await this.reflectionService.getOnboardingReflectionQuestions();
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(action) {
    switch (action) {
      case 'save':
        //validate
        this.saveReflection(this.pageData.reflectionSubmission);
        break;

      default:
        break;
    }
  }

  /** 
  * View submission.
  * @param id 
  */
  viewSubmission(id) {
    this.router.navigate(['/ReflectionDetailsPage', JSON.stringify({ id: id })]);
  }

  /** 
  * Create reflection.
  */
  createReflection() {
    this.hideForm = false;
  }


  /** 
  * Save reflection.
  * @param data
  */
  saveReflection(data) {

    // weird issue with length on the array, so have to fudge the count to check if all questions are set to false
    let count = 0;
    let falseCount = 0;
    data.questions.forEach((q, index) => {
      count++;
      if (q === false) {
        falseCount++;
      }
    });

    if (this.pageData.questions && this.pageData.questions.length > 0 && (count === falseCount)) {
      MyUtil.presentToast('Please select at least one skill option', { cssClass: 'inkpath-toast' });
      return;
    }

    // validate at least one question and reflection text is complete
    if (this.pageData.questions && this.pageData.questions.length > 0 && data.questions.length < 1) {
      MyUtil.presentToast('Please select at least one skill option', { cssClass: 'inkpath-toast' });
      return;
    }

    if (!data.reflection) {
      MyUtil.presentToast('Please complete your reflection', { cssClass: 'inkpath-toast' });
      return;
    }

    if (!data.reflectionReminder) {
      MyUtil.presentToast('Please select a reminder interval', { cssClass: 'inkpath-toast' });
      return;
    }

    let profile = MyUtil.getProfile();

    // save the data
    this.reflectionService.saveOnboardingReflectionSubmission(data, profile).then(async (res) => {
      // redirect to activities page if success
      if (res) {

        MyUtil.presentToast('Reflection saved.', { cssClass: 'inkpath-toast' });

        // check if we have any available diagnostics
        this.diagnosticService.getDiagnosticTests().then(async (diagnostics) => {
          if (diagnostics) {
            let reflectionCompleteModal = MyUtil.createModal(ReflectionCompleteModal, {
              title: "Where to first?",
            }, false);
            (await reflectionCompleteModal).onDidDismiss().then(label => {
              if (label.data !== undefined) {
                this.router.navigate(['/DiagnosticListPage', JSON.stringify({ "referer": "onboarding" })]);
              } else {
                this.router.navigate(['/home']);
              }
            });
            (await reflectionCompleteModal).present();
            return;
          }
        });
      } else {
        //MyUtil.presentToast('Error submitting your details. Please check your connection.', { cssClass: 'inkpath-toast' });
      }
    });
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
