
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Appapi } from './appapi';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor(public appapi: Appapi) { }

    private subject = new Subject<any>();

    private announcementCountSubject = new BehaviorSubject<number | undefined>(undefined);
    readonly announcementCount$ = this.announcementCountSubject.asObservable();
    readonly announcementTooltip$ = this.announcementCount$
        .pipe(
            map(count => {
                if(!count) {
                    return "Announcements";
                } else {
                    return "Announcements (" + count + " new)";
                }
            })
        );

    getUnreadAnnouncementCount() : void {
        this.appapi.getUnreadAnnouncementCount().then((data) => {
            if(data && data['unread_announcements'] !== undefined) {
                if(data['unread_announcements'] > 0) {
                    this.announcementCountSubject.next(data['unread_announcements']);
                } else {
                    this.announcementCountSubject.next(undefined);
                }
            }
        });
    }

    sendMessage(text){
        this.subject.next(text);
    }

    getMessage():Observable<any>{
        return this.subject.asObservable();
    }
}