import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileTransfer, FileTransferObject } from "@awesome-cordova-plugins/file-transfer/ngx";
import { NavParams } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as Moment from "moment";
import { NgxCopilotService } from "ngx-copilot";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CancelBookingModal } from "src/app/components/cancel-booking-modal/cancel-booking-modal";
import { ActivityService } from "src/app/providers/activity-service";
import { Appapi } from "src/app/providers/appapi";
import { CoursesService } from "src/app/providers/courses-service";
import { DesktopChecker } from "src/app/providers/desktopChecker";
import { FileService } from "src/app/providers/file-service";
import { UiuxService } from "src/app/services/uiux.service";
import { WalkthroughService } from "src/app/services/walkthrough.service";
import { MyDb } from "../../../libs/MyDb";
import { MyUtil } from "../../../libs/MyUtil";
import * as appStore from '../../store';
import { LocationMapPage } from "../location-map/location-map";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { TermsModalComponent } from "src/app/components/terms-modal/terms-modal.component";
import { RecurringEventsModalComponent } from 'src/app/components/recurring-events-modal/recurring-events-modal.component';

declare var google;

@Component({
  selector: "page-activity-detail",
  templateUrl: "activity-detail.html",
  styleUrls: ['activity-detail.scss'],

})
export class ActivityDetailPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  isMobileView = this.uiux.isMobileView();
  pageLabel = "ActivityDetailPage";
  fileTransfer: FileTransferObject = this.transfer.create();

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  id: any;

  constructor(
    public router: Router,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    public uiux: UiuxService,
    private store: Store<appStore.AppViewState>,
    private copilot: NgxCopilotService,
    private route: ActivatedRoute,
    public desktopChecker: DesktopChecker,
    public sanitiser: DomSanitizer,
    public activityService: ActivityService,
    public fileService: FileService,
    public transfer: FileTransfer,
    public file: File,
    public courseService: CoursesService,
    private titleService: Title) 
  {
    this.titleService.setTitle("Activity Detail");
  }

  /** 
   * Re initialize and specify step.
   * @param stepNumber   stepNumber: string.
   */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
   * Next step.
   * @param stepNumber   stepNumber: string.
   */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  async ngOnInit() {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }
    
    let loading = MyUtil.presentLoading();

    this.startWalkthrough()

    this.pageData.activityObject = {};
    this.pageData.activityDisplay = {};
    this.pageData.userActivityDoc = null;
    this.pageData.activityBooking = null;
    this.pageData.bookingDetails = null;
    this.pageData.hasCapacity = true;
    this.pageData.hasOnlineCapacity = true;
    this.pageData.noBookingWarningPopup = false;
    this.pageData.displayHelpIcon = true;
    this.pageData.clashingActivities = [];
    this.pageData.recurringGroup = null;
    this.pageData.showVenue = false;
    this.pageData.showJoiningLink = false;

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData)
    });

    this.pageData.activitySegments = "options";

    let key = MyUtil.cache[MyUtil.DOC_ID.APP_SETTINGS]["GOOGLE_MAP_API_KEY"];
    (function (d, s, id, key) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://maps.google.com/maps/api/js?key=" + key;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-map-js", key);

    // define note form validation
    this.pageData.activityNoteDoc = null;
    this.pageData.noteFormSubmitAttempt = false;
    this.pageData.noteEditMode = false;
    this.pageData.noteForm = this.formBuilder.group({
      note_text: ["", Validators.compose([Validators.required])],
    });

    //Get existing notes
    this.queryActivityNote(this.routeData.id).then(async (doc) => {
      if(doc && doc.data) {
        this.pageData.activityNoteDoc = doc;
        this.pageData.activityNoteDisplay = doc.data.text.replace(/\n/g, '<br/>');
      }
    });

    // only show add to calendar in mobile app mode
    this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();

    // only allow to upload evidence with connected
    this.pageData.isNetworkConnected = MyUtil.isNetworkConnected();

    MyUtil.firebaseSetScreenName("activity-detail");
    MyUtil.firebaseLogEvent("view_did_enter", {
      name: "activity-detail",
      data: this.routeData.id,
    });

    // attempt to load already existing activity object
    //TODO: Remove this bit when we get to sorting out caching/back-end refactoring - all activities should be in cache
    this.pageData.activity = this.routeData.activityObject;

    // if no actual object, default to cache lookup
    if (!this.pageData.activity || !this.pageData.activity.start_time) {
      this.pageData.activity = MyUtil.getActivity(this.routeData.id);
    }

    //check if this is a course activity
    //TODO: Remove this bit when we get to sorting out caching/back-end refactoring - all activities should be in cache
    this.pageData.isCourseActivity = !!this.routeData.isCourseActivity;

    this.handleCalendarLinkModal();

    //reset noBookingWarningPopup
    this.pageData.noBookingWarningPopup = false;
    this.pageData.userActivityDoc = null;
    this.pageData.userActivityEvidences = null;
    this.pageData.homeOrg = MyUtil.getRootOrganization();
    this.pageData.orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id)[0];

    this.activityService.queryUserActivity(this.routeData.id).then(async (doc) => {
      this.pageData.userActivityDoc = doc;
      if (MyUtil.isNetworkConnected && doc && doc.data && doc.data.id) {
        this.fileService.retrieveEvidences(doc.data.id).then((result) => {
          this.pageData.userActivityEvidences = result;
        });
      }

      // go to complete page with the attendance code if available
      let attendanceCode = this.routeData.attendanceCode;
      if (attendanceCode && !this.pageData.notGoToComplete) {
        if (doc && !doc.delete && doc.data.status !== MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE) {
          let completeAllowed = this.pageData.activity.start_at <= MyUtil.getLocalNowAsUTCTimeStamp() + 86400;
          if (completeAllowed) {
            this.completeActivity(this.routeData.id, attendanceCode);
          } else {
            MyUtil.presentToast('Activity cannot be completed yet', { cssClass: 'inkpath-toast' });
          }

          // avoid go to complete again when back from complete page
          this.pageData.notGoToComplete = true;
        }

        // avoid go to complete again when back from complete page
        this.pageData.notGoToComplete = true;
      }

      //Set variable to indicate activity is complete
      //SLN 2023-09-04 Added to simplify HTML ngIf= statements. Only used by "booking/waiting list" buttons/info, but could be used by others too. Not changed the others yet because 
      //outside the remit of the 2506 ticket. (Whole page needs refactoring tbh).
      this.pageData.activityComplete = doc && !doc.delete && doc.data.status == MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE;

      //Get any alternative dates (defined by the recurring group)
      if(this.pageData.activity.recurring_group_id) {
        await this.appapi.getRecurringGroup(this.pageData.activity.recurring_group_id, this.pageData.activity.id).then((result) => {
          this.pageData.recurringGroup = result;
        });
      }
    
      //Clashing activities
      this.activityService.listBookingClashes(this.routeData.id).then((clashingActivities) => {
        this.pageData.clashingActivities = Object.values(clashingActivities);
  
        //booked?
        if (MyUtil.isNetworkConnected()) {
          this.fetchBookingMetadata().then(async (data) => {
            if (data && data.booking_type == MyUtil.CONST.APP_META.BOOKING_TYPE_INTERNAL) {
              //if internal, check booked status
              this.checkUserBooking().then(async () => {
                //Work out if the venue/joining link should be shown
                //Requires booking
                if(this.pageData.bookingDetails && this.pageData.activityBooking && this.pageData.activityBooking.status == 3) {
                  if(this.pageData.activity.attendance_type == 4) {
                    //Hybrid event - show either venue or link depending on type of booking
                    this.pageData.showVenue = !this.pageData.activityBooking.hybrid_online && !!this.pageData.activity.venue;
                    this.pageData.showJoiningLink = this.pageData.activityBooking.hybrid_online && this.activityService.isValidUrl(this.pageData.activity.joining_link);
                    if(this.pageData.activityBooking.hybrid_online && !this.pageData.showJoiningLink) {
                      //If link not valid, show venue - historical hybrid events may have a non-clickable url in the venue
                      this.pageData.showVenue = !!this.pageData.activity.venue;
                    }
                  } else if (this.pageData.activity.attendance_type == 1) {
                    //Online event - just show link
                    this.pageData.showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
                    if(!this.pageData.showJoiningLink) {
                      //If link not valid, show venue - historical online events may have a non-clickable url in the venue
                      this.pageData.showVenue = !!this.pageData.activity.venue;
                    }
                  } else if(this.pageData.activity.attendance_type == 2) {
                    //In-person - just show venue
                    this.pageData.showVenue = !!this.pageData.activity.venue;
                  } else {
                    //Anything else (self-study, "other")
                    this.pageData.showVenue = !!this.pageData.activity.venue;
                    this.pageData.showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
                  }
                }

                //Booking close date - do we display the 3 letter timezone?
                if(this.pageData.bookingDetails && this.pageData.bookingDetails.show_booking_closes && this.pageData.bookingDetails.booking_closes_time) {
                  if(this.pageData.orgDetails.display_timezone_abbr === 1) {
                    let startAbbr = MyUtil.calculateTimezoneAbbr(this.pageData.activity.start_at, this.pageData.orgDetails.timezone_code);
                    this.pageData.bookingDetails.booking_closes_time += " " + startAbbr;
                  }
                }

                this.renderActivity();

                (await loading).dismiss();
                this.pageData.loaded = true;
              });
            } else {
              //Work out if the venue/joining link should be shown
              //Requires booking
              if(this.pageData.bookingDetails && this.pageData.activityBooking && this.pageData.activityBooking.status == 3) {
                if(this.pageData.activity.attendance_type == 4) {
                  //Hybrid event - show either venue or link depending on type of booking
                  this.pageData.showVenue = !this.pageData.activityBooking.hybrid_online && !!this.pageData.activity.venue;
                  this.pageData.showJoiningLink = this.pageData.activityBooking.hybrid_online && this.activityService.isValidUrl(this.pageData.activity.joining_link);
                  if(this.pageData.activityBooking.hybrid_online && !this.pageData.showJoiningLink) {
                    //If link not valid, show venue - historical hybrid events may have a non-clickable url in the venue
                    this.pageData.showVenue = !!this.pageData.activity.venue;
                  }
                } else if (this.pageData.activity.attendance_type == 1) {
                  //Online event - just show link
                  this.pageData.showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
                  if(!this.pageData.showJoiningLink) {
                    //If link not valid, show venue - historical online events may have a non-clickable url in the venue
                    this.pageData.showVenue = !!this.pageData.activity.venue;
                  }
                } else if(this.pageData.activity.attendance_type == 2) {
                  //In-person - just show venue
                  this.pageData.showVenue = !!this.pageData.activity.venue;
                } else {
                  //Anything else (self-study, "other")
                  this.pageData.showVenue = !!this.pageData.activity.venue;
                  this.pageData.showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
                }
              } else {
                // quick hack - if activity is in my activities list, show the links
                this.activityService.queryUserActivity(this.pageData.activity.id).then(res => {
                  if(res) {
                    this.pageData.showVenue = !!this.pageData.activity.venue;
                    this.pageData.showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
                  }
                });
                
              }

              this.renderActivity();

              (await loading).dismiss();
              this.pageData.loaded = true;
            }
          });
        };
      });
    });

    this.startWalkthrough();

  }


  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('19');
      }, 1000);
    }
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }


  /** 
  * Refresh page.
  */
  public refreshPage() {
    //Passing in a UNIX timestamp ensures the page is refreshed every time.
    //TODO: Remove "isCourseActivity" argument when we get to sorting out caching/back-end refactoring
    this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: this.pageData.activity.id, isCourseActivity: this.pageData.isCourseActivity, t: MyUtil.getUnixTimeStamp() })]);
  }


  ionViewDidLeave() {
    MyUtil.firebaseLogEvent("view_did_leave", {
      name: "activity-detail",
      data: this.routeData.id,
    });
  }

  async ionViewCanLeave() {
    //if has activity saved
    //and intwernal booking
    //and internal booking
    //and no booking made
    //and not marking as complete, also covered by noBookingWarningPopup flag
    if (
      !this.pageData.noBookingWarningPopup &&
      this.pageData.userActivityDoc &&
      !this.pageData.userActivityDoc.delete &&
      (!this.pageData.activityBooking || this.pageData.activityBooking.status == 4) &&
      (!this.pageData.activityBooking || this.pageData.activityBooking.status == 4) &&
      this.pageData.bookingDetails &&
      this.pageData.bookingDetails.booking_type == MyUtil.CONST.APP_META.BOOKING_TYPE_INTERNAL &&
      !this.pageData.bookingDetails.disable_text
    ) {
      const shouldLeave = await this.confirmLeave();
      if (!shouldLeave) {
        this.pageData.segment = 'options';
        this.pageData.segment = 'progress';
        this.process('add-goal');
      }
      return shouldLeave;
    }

    return true;
  }


  handleCalendarLinkModal() {

    // If is not an admin viewing the page from the admin area ignore the filters and only apply to calendar links.
    if (this.routeData.calendar) {
  
      // Filter by: phases.
      let prof = MyUtil.getProfile();
      let phasekeys = [prof.phase_id];
      if (!!prof.fundings) {
        let phases = Object.keys(prof.fundings);
        phases.forEach(phase => {
          phasekeys.push(prof.fundings[phase].phase_id);
        });
      }

      let containsPhases = false;
      if (this.pageData.activity?.phases && this.pageData.activity?.phases.length > 0) {
        this.pageData.activity?.phases.forEach(element => {
          if (phasekeys.indexOf(element) > -1) {
            containsPhases = true;
          }
        });
      } else {
        containsPhases = false;
      }
      // If user has no related phases or programmes relating to the activity show a message.
      if ( 
          !this.isPartOfUserOrganisation(this.pageData.activity) || 
          !this.isPartOfPhase(phasekeys, this.pageData.activity) || 
          !this.isPartOfProgram(MyUtil.getProfileProgramIds(), this.pageData.activity)
        ) {
          // redirect to home page with message
          MyUtil.presentToast(
            "You don't have access to this activity.", { cssClass: 'inkpath-toast' }
          );

          this.router.navigate(['/']);

      }
    }
  }

  // check if user has access to the activity organisation
  isPartOfUserOrganisation(activity) {
    let orgs = MyUtil.getOrgnizationChain();
    let access = false;

    orgs.forEach( org => {
      if(org.id === activity.oid) {
        access = true;
      }
    })
    
    return access;
  }

  // Check if is part of program.
  isPartOfProgram(programIds, element) {
    //return function (element) {
      let contains = false;
      if (element.programs && element.programs.length > 0) {
        element.programs.forEach(element => {
          if (programIds.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    //}
  }

  // Check if is part of program.
  isPartOfPhase(phases, element) {
    //return function (element) {
      let contains = false;
      if (element.phases && element.phases.length > 0) {
        element.phases.forEach(element => {
          if (phases.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    //}
  }

  /** 
  * Confirm leave.
  */
  confirmLeave(): Promise<Boolean> {
    let resolveLeaving;
    const canLeave = new Promise<Boolean>(
      (resolve) => (resolveLeaving = resolve)
    );
    const alert = MyUtil.presentAlert({
      title: "Booking Required",
      message:
        "If you wish to attend this activity you will need to book a place",
      buttons: [
        {
          text: "Book later",
          handler: () => resolveLeaving(true),
        },
        {
          text: "Book now",
          role: "cancel",
          handler: () => resolveLeaving(false),
        },
      ],
    });
    return canLeave;
  }


  /** 
  * Render activity.
  * */
  private renderActivity() {

    let dateFormat = MyUtil.formatPHPDate(this.pageData.orgDetails.org_date_format);

    //error handle obscure bug (unable to replicate)
    let startTime = null;
    let endTime = null;
    if (this.pageData.activity.start_time !== undefined) {
      startTime = this.pageData.activity.start_time;
    }

    if (this.pageData.activity.end_time !== undefined) {
      endTime = this.pageData.activity.end_time;
    }

    let startTimeDisplay = startTime
      ? MyUtil.formatHISTimeFormatToAMPM(startTime)
      : "";
    let endTimeDisplay = endTime
      ? MyUtil.formatHISTimeFormatToAMPM(endTime)
      : "";
    let timeDisplay =
      startTimeDisplay.length > 0 && endTimeDisplay.length > 0
        ? startTimeDisplay + "&#8209;" + endTimeDisplay
        : startTimeDisplay.length > 0
          ? startTimeDisplay
          : endTimeDisplay.length > 0
            ? endTimeDisplay
            : "";

    let startDisplay = MyUtil.formatUnixTimeStampDate(
      this.pageData.activity.start_at,
      dateFormat
    );

    let endDisplay = MyUtil.formatUnixTimeStampDate(
      this.pageData.activity.end_at,
      dateFormat
    );

    // do we display the 3 letter timezone?
    if(this.pageData.orgDetails.display_timezone_abbr === 1) {
      let startAbbr = MyUtil.calculateTimezoneAbbr(this.pageData.activity.start_at, this.pageData.orgDetails.timezone_code);
      // only show if there is a time to display
      if(timeDisplay !== "") {
        timeDisplay += " " + startAbbr;
      }
    }

    let skillsArray = MyUtil.lodash
      .chain(this.pageData.activity.skills)
      .map((id) => {
        return MyUtil.getSkill(id).name;
      })
      .filter((item) => {
        return (item != undefined);
      })
      .value();

    this.pageData.activityDisplay = {
      id: this.pageData.activity.id,
      name: this.pageData.activity.name,
      code: this.pageData.activity.code,
      description: this.pageData.activity.description,
      internal_reference: this.pageData.activity.internal_reference,
      important_info: this.pageData.activity.important_info,
      attendance_type: MyUtil.ATTENDANCE_TYPE_LABELS[this.pageData.activity.attendance_type],
      attendance_type_id: this.pageData.activity.attendance_type,
      venue: this.pageData.activity.venue,
      joining_link: this.pageData.activity.joining_link,
      provider: this.pageData.activity.provider,
      latitude:
        this.pageData.activity.latitude == 0
          ? null
          : this.pageData.activity.latitude,
      longitude:
        this.pageData.activity.longitude == 0
          ? null
          : this.pageData.activity.longitude,
      start_at: this.pageData.activity.start_at,
      end_at: this.pageData.activity.end_at,
      start_time: this.pageData.activity.start_time,
      end_time: this.pageData.activity.end_time,
      completeAllowed:
        this.pageData.activity.start_at <= MyUtil.getLocalNowAsUTCTimeStamp() + 86400, // one day before start,
      status_complete: MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE,
      attend_status_valid: MyUtil.CONST.APP_META.ATTEND_STATUS_VALID,
      period:
        startDisplay === endDisplay
          ? startDisplay + " " + timeDisplay
          : startDisplay +
          " " +
          startTimeDisplay +
          " to " +
          endDisplay +
          " " +
          endTimeDisplay,
      organisation: MyUtil.getOrgnizationChainName(this.pageData.activity.oid),
      organisationContact: MyUtil.getRootOrganization(this.pageData.activity.oid, true)?.public_contact_email,
      homepage: this.pageData.activity.homepage,
      skills: MyUtil.lodash
        .chain(skillsArray)
        .value()
        .join(", "),
      activity_template_id: this.pageData.activity.activity_template_id,
      visibility: this.pageData.activity.visibility,
      points: this.pageData.activity.points,
      contact_email: this.pageData.activity.contact_email,
      contact_name: this.pageData.activity.contact_name,
      external_feedback_url: this.pageData.activity.external_feedback_url,
      badge_details: this.pageData.activity.badge_details,
    };

    if (this.pageData.activity.price !== null && this.pageData.activity.price > 0) {
      //TODO: change to pass in root_oid when back-end API refactor sorted
      //Or - find a better way of getting the currency symbol. Get API to supply it directly?
      let rootOrg = MyUtil.getRootOrganization(this.pageData.activity.oid, true);
      if (rootOrg?.currency_symbol !== '') {
        this.pageData.activityDisplay.price = rootOrg.currency_symbol + this.pageData.activity.price;
      } else {
        this.pageData.activityDisplay.price = this.pageData.activity.price;
      }
    }
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  async process(action: string, item?: any) {
    switch (action) {
      case "add-calender":
        this.addToCalender();
        return;
      case "add-activity":
        this.addActivity();
        return;
      case "remove-activity":
        this.removeActivity();
        return;
      case "visit-booking":
        if(this.pageData.bookingDetails.terms_text) {
          //Display popup asking user to agree to Ts&Cs
          let modal = MyUtil.createModal(TermsModalComponent,
            {
              message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.bookingDetails.terms_text),
            });
          (await modal).onDidDismiss().then((data: any) => { 
            if (data.data !== undefined) {
              this.launchBooking();
            } 
          });
          (await modal).present();
        } else {
          //No Ts&Cs
          this.launchBooking();
        }
        return;
      case "visit-hybrid-online-booking":
        if(this.pageData.bookingDetails.terms_text) {
          //Display popup asking user to agree to Ts&Cs
          let modal = MyUtil.createModal(TermsModalComponent,
            {
              message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.bookingDetails.terms_text),
            });
          (await modal).onDidDismiss().then((data: any) => { 
            if (data.data !== undefined) {
              this.launchBooking(1);
            } 
          });
          (await modal).present();
        } else {
          //No Ts&Cs
          this.launchBooking(1);
        }
        return;
      case "convert-to-in-person":
        let onlineStatus = this.pageData.activityBooking.status == 1 ? (this.pageData.bookingDetails.online_capacity > 0 ? 'waiting list' : 'application list') : 'booking';
        let toInPersonStatus = this.pageData.hasCapacity ? 'booking' : (this.pageData.bookingDetails.capacity > 0 ? 'waiting list' : 'application list');
        let convertToInPersonMessage = "<b>Convert from online " +  onlineStatus + " to in-person " + toInPersonStatus + "?</b><br/><br/>This will release your online place and move you to the in-person " + toInPersonStatus + ".";
        if(this.pageData.hasOnlineCapacity && !this.pageData.hasCapacity) {
          convertToInPersonMessage = convertToInPersonMessage + " You will no longer have a confirmed place.";
        } else if(!this.pageData.hasOnlineCapacity) {
          convertToInPersonMessage = convertToInPersonMessage + " The online event is " + (this.pageData.bookingDetails.online_capacity == 0 ? 'by application only' : 'full') + " so you may not be able to return to an online booking.";
        }

        if(convertToInPersonMessage !== "") {
          MyUtil.presentAlert({
            message: convertToInPersonMessage,
            buttons: [
              {
                text: 'Cancel',
                handler: () => {
                  return;
                }
              },
              {
                text: 'Confirm',
                handler: () => {
                  this.launchBooking(0, true);
                }
              }
            ],
          });
        }
        return;
      case "convert-to-online":
        let toOnlineStatus = this.pageData.hasOnlineCapacity ? 'booking' : (this.pageData.bookingDetails.online_capacity > 0 ? 'waiting list' : 'application list');
        let inPersonStatus = this.pageData.activityBooking.status == 1 ? (this.pageData.bookingDetails.capacity > 0 ? 'waiting list' : 'application list') : 'booking';
        let convertToOnlineMessage = "<b>Convert from in-person " +  inPersonStatus + " to online " + toOnlineStatus + "?</b><br/><br/>This will release your in-person place and move you to the online " + toOnlineStatus + ".";
        if(this.pageData.hasCapacity && !this.pageData.hasOnlineCapacity) {
          convertToOnlineMessage = convertToOnlineMessage + " You will no longer have a confirmed place.";
        } else if(!this.pageData.hasCapacity) {
          convertToOnlineMessage = convertToOnlineMessage + " The in-person event is " + (this.pageData.bookingDetails.capacity == 0 ? 'by application only' : 'full') + " so you may not be able to return to an in-person booking.";
        }

        MyUtil.presentAlert({
          message: convertToOnlineMessage,
          buttons: [
            {
              text: 'Cancel',
              handler: () => {
                return;
              }
            },
            {
              text: 'Confirm',
              handler: () => {
                this.launchBooking(1, true);
              }
            }
          ],
        });
        return;
      case "visit-info":
        this.launchExternalInfo(item);
        return;
      case "cancel-booking":
        this.cancelUserBooking();
        return;
      case "visit-location":
        if (google && MyUtil.isNetworkConnected()) {
          this.launchMap();
        } else {
          MyUtil.presentToast(
            "Map is not available. Please check the connection and try again later.", { cssClass: 'inkpath-toast' }
          );
        }
        return;
      case "complete-activity":
        this.completeActivity(item, null);
        return;
      case "edit-user-generated-activity":
        this.editUserGeneratedActivity(item);
        return;
      case "copy-user-generated-activity":
        this.copyUserGeneratedActivity(item);
        return;
      case "remove-user-generated-activity":
        this.removeUserGeneratedActivity();
        return;
      case "add-note":
      case "edit-note":
        if (
          this.pageData.activityNoteDoc &&
          this.pageData.activityNoteDoc.data
        ) {
          this.pageData.noteForm.controls.note_text.setValue(
            this.pageData.activityNoteDoc.data.text
          );
        }
        this.pageData.noteEditMode = true;
        return;
      case "save-note":
        this.updateActivityNote().then(() => {
          this.pageData.noteEditMode = false;
        });
        return;
      case "upload-evidence-file":
        this.uploadEvidenceFile();
        return;
      case "view-evidence-file":
        this.viewEvidenceFile();
        return;
      case "remove-evidence-file":
        this.removeEvidenceFile();
        return;
      case "leave-feedback":
        window.open(this.pageData.activityDisplay.external_feedback_url, '_blank');
        return;
      case "more-dates":
        if(this.pageData.recurringGroup && this.pageData.recurringGroup.alternative_dates.length > 0) {
          //Display popup showing alternative dates for this activity
          let modal = MyUtil.createModal(RecurringEventsModalComponent,
            {
              message: '',
              eventName: this.pageData.activity.name,
              type: 'activity',
              alternativeDates: this.pageData.recurringGroup.alternative_dates 
            });
          (await modal).present();
        }
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }


  private addActivity() {
    let id = this.pageData.activity.id;
    let bookingType = this.pageData.activity.booking_type;
    this.activityService.addToUserActivity(id, bookingType).then(() => {
      this.refreshPage();
    });
  }


  private removeActivity() {
    if (!this.pageData.userActivityDoc || this.pageData.userActivityDoc.delete) {
      MyUtil.presentToast("Activity is not in personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    if (this.pageData.userActivityDoc.data.status == MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE 
      || (this.pageData.userActivityEvidences && this.pageData.userActivityEvidences[0])
    ) {
      // prepare message according context
      let msg = "This activity has been marked as complete. Are you sure you wish to remove this activity?";
      if (this.pageData.userActivityDoc.data.status == MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE 
        && this.pageData.userActivityEvidences 
        && this.pageData.userActivityEvidences[0]
      ) {
        msg = "Are you sure you wish to remove this activity, which has been marked as complete? Your attached file will be deleted.";
      } else {
        if (this.pageData.userActivityEvidences && this.pageData.userActivityEvidences[0]) {
          msg = "Are you sure you wish to remove this activity? Your attached file will be deleted.";
        }
      }

      MyUtil.presentAlert({
        title: "Are you sure?",
        message: msg,
        buttons: [
          {
            text: "Cancel",
            handler: () => {
              return;
            },
          },
          {
            text: "Confirm",
            handler: () => {
              this.deleteActivity();
            },
          },
        ],
      });
    } else {
      this.deleteActivity();
    }

    
  }

  private deleteActivity(): void {
    let userActivityDoc = this.pageData.userActivityDoc;
    let loading = MyUtil.presentLoading();

    // also remove any related evidence files - do this first, because depends on user_activities record still existing
    this.deleteEvidenceFile();

    this.activityService.deleteFromUserActivity(userActivityDoc).then(async () => {
      (await loading).dismiss();
      this.refreshPage();
    });
  }

  private completeActivity(id, attendanceCode) {
    // go to complete page, no pop up
    this.pageData.noBookingWarningPopup = true;

    let params = {
      id: id,
      backToGoal: !!this.routeData.backToGoal,
      attendanceCode: attendanceCode,
      activityObject: this.routeData.activityObject || undefined
    };
    this.router.navigate(['/ActivityCompletePage', JSON.stringify(params)]);
  }

  private editUserGeneratedActivity(id) {
    this.router.navigate(['/ActivityEditPage', JSON.stringify({ id: id })]);
  }

  private copyUserGeneratedActivity(id) {
    this.router.navigate(['/ActivityEditPage', JSON.stringify({ id: id, copy: true })]);
  }

  private removeUserGeneratedActivity() {
    let activity = this.pageData.activity;
    if (!activity || !activity.id) {
      return;
    }

    // not allow to delet if used by goal
    let referredGoals = MyUtil.getGoalsReferredActivityId(activity.id);
    if (!MyUtil.lodash.isEmpty(referredGoals)) {
      MyUtil.presentToast(
        "Activity could not be deleted because it is used by goals",
        { cssClass: 'inkpath-toast' }
      );
      return;
    }

    MyUtil.presentAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this activity?",
      buttons: [
        {
          text: "Cancel",
          handler: () => {
            return;
          },
        },
        {
          text: "Confirm",
          handler: () => {
            this.deleteUserGeneratedActivity(activity);
          },
        },
      ],
    });
  }

  private deleteUserGeneratedActivity(activity: any): void {
    // remove activity locally in the app
    return MyDb.userLoad(MyUtil.DOC_ID.ACTIVITIES).then((activitiesDoc: any) => {
      let loading = MyUtil.presentLoading();
      delete activitiesDoc.data[activity.id];
      return MyDb.userSave(activitiesDoc).then((activitiesDoc: any) => {
        // update cache
        MyUtil.cache[activitiesDoc._id] = activitiesDoc.data;

        // create mock doc, in case there's no user activity assigned
        if (!this.pageData.userActivityDoc) {
          this.pageData.userActivityDoc = {};
          // init type and data
          this.pageData.userActivityDoc.type = MyUtil.DOC_TYPE.USER_ACTIVITY;
          this.pageData.userActivityDoc.data = {
            activity_id: this.pageData.activity.id,
            status: MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_ACTIVE,
          };
        }

        // set delete flage in the cache for data sync
        this.pageData.userActivityDoc.data.updated_activity = {
          id: activity.id,
          delete: true,
        };

        // delete from user activity list and also delete the activity at the same time
        return this.activityService.deleteFromUserActivity(this.pageData.userActivityDoc).then(async () => {
          // this.navCtrl.pop();
          (await loading).dismiss();
          MyUtil.presentToast("Activity has been removed", { cssClass: 'inkpath-toast' });
          this.router.navigate(['/']);
        });
      });
    });
  }

  private launchExternalInfo(url) {
    let browser = MyUtil.createInAppBrowser(url, "_system");
    browser.show;
  }

  private  launchBooking(hybridOnline: number = 0, convertBooking: boolean = false) {
    //record that we are going to the booking page, rather than back, for ionViewCanLeave
    this.pageData.noBookingWarningPopup = true;
    //add conditionals in here for internal, external or none
    if (this.pageData.activity.booking_type == MyUtil.CONST.APP_META.BOOKING_TYPE_INTERNAL) {

      //If hybridOnline, remove any activity booking details that don't make sense in an online context (parking etc)
      let details = this.pageData.bookingDetails.details;
      let filteredDetails = [];
      if(hybridOnline === 1 && this.pageData.bookingDetails.details.length > 0) {
        filteredDetails = details.filter((detail) => {
          return detail.label != 'require_accommodation' 
            && detail.label != 'require_parking' 
            && detail.label != 'require_dietary' 
            && detail.label != 'require_wheelchair' 
            && detail.label != 'require_accessible_parking'
        })
      } else {
        filteredDetails = details;
      }

      if (filteredDetails.length == 0 && this.pageData.clashingActivities.length == 0) {
        //No booking requirements details and no clashng activities - no need for intermediary booking page
        let formData = {
          details: [],
          bookable_id: this.pageData.activity.id,
          hybrid_online: hybridOnline,
          convert_booking: convertBooking
        };

        let loading = MyUtil.presentLoading();
        this.activityService.saveBooking(formData).then((result) => {
          //only add if activity not already added
          this.activityService.queryUserActivity(this.pageData.activity.id).then(async (doc) => {
            if (!doc || doc.delete) {
              this.addActivity();
            } else {
              this.refreshPage();
            }

            (await loading).dismiss();
          });
        });
      } else {
        //Display booking page
        let bookingLabel = "Book place on activity";
        let hasCapacity = (hybridOnline === 1 ? this.pageData.hasOnlineCapacity : this.pageData.hasCapacity);
        if(convertBooking) {
          if(hybridOnline) {
            if(hasCapacity) {
              bookingLabel = "Convert booking to online";
            } else {
              bookingLabel = "Join online " + (this.pageData.bookingDetails?.online_capacity === 0 ? 'application' : 'waiting') + " list";
            }
          } else {
            if(hasCapacity) {
              bookingLabel = "Convert booking to in-person";
            } else {
              bookingLabel = "Join in-person " + (this.pageData.bookingDetails?.capacity === 0 ? 'application' : 'waiting') + " list";
            }
          }
        } else if(!hasCapacity) {
          bookingLabel = "Join " + (this.pageData.bookingDetails?.capacity === 0 ? 'application' : 'waiting') + " list";
        }

        let params = {
          id: this.pageData.activity.id,
          clashingActivities: this.pageData.clashingActivities,
          hybridOnline: hybridOnline,
          convertBooking: convertBooking,
          details: filteredDetails,
          bookingLabel: bookingLabel
        };

        this.router.navigate(['/ActivityBookingPage', JSON.stringify(params)]);
      }
    } else if (this.pageData.activity.booking_type == MyUtil.CONST.APP_META.BOOKING_TYPE_EXTERNAL) {
      this.addActivity();
      let browser = MyUtil.createInAppBrowser(
        this.pageData.activity.external_booking_url,
        "_system"
      );
      browser.show;
    } else {
      MyUtil.presentToast("The activity is not bookable.", { cssClass: 'inkpath-toast' });
    }
  }

  private async launchMap() {
    let data = this.pageData.activityDisplay;
    let locationModal = MyUtil.createModal(LocationMapPage, {
      venue: data.venue,
      lat: data.latitude,
      long: data.longitude,
    });
    (await locationModal).present();
  }

  private addToCalender() {
    let data = this.pageData.activity;
    let start = this.processActivityDateTime(
      data.start_at,
      data.start_time,
      false
    );
    let end = this.processActivityDateTime(data.end_at, data.end_time, false);
    let title = data.name;
    let eventLocation = data.venue;
    let notes = data.description;
    let options = MyUtil.calendarGetOptions();
    if (!data.start_time && !data.end_time) {
      options["allday"] = true;
      // add one day to the end to have all days included in the event
      // and ios not allow creating all day event with same start and end
      end = this.processActivityDateTime(data.end_at, data.end_time, true);
    }

    MyUtil.calendarCreateEventWithOptions(
      title,
      eventLocation,
      notes,
      start,
      end,
      options
    ).then(
      function (result) {
        // success
        MyUtil.presentToast("Activity has been added to the device calendar");
      },
      function (err) {
        MyUtil.presentToast(
          "Sorry, failed to add the activity to the device calendar", { cssClass: 'inkpath-toast' }
        );
        MyUtil.error(err);
      }
    );
  }

  private processActivityDateTime(date, time: string, adjust: boolean) {
    let dateTime: any;
    let moment = Moment(date * 1000);
    let year = moment.year();
    let month = moment.month();
    let day = moment.date();

    if (time) {
      let hour = Number(time.split(":")[0]);
      let minute = Number(time.split(":")[1]);

      dateTime = Moment([year, month, day, hour, minute]);
    } else {
      dateTime = Moment([year, month, day]);
    }

    // adjust to add one day
    if (adjust) {
      dateTime.add(1, "d");
    }

    return dateTime.toDate();
  }

  private queryActivityNote(id): any {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.ACTIVITY_NOTE, id],
      include_docs: true,
    };

    return MyDb.userQuery("by_type_activity_id", queryOptions).then(
      (queryResult) => {
        let docs = MyDb.flatQueryResult(queryResult);
        let resultDoc = null;

        if (docs && docs.length > 0) {
          resultDoc = docs[0];
        }

        return resultDoc;
      }
    );
  }

  private updateActivityNote() {
    if (!this.pageData.activityNoteDoc) {
      this.pageData.activityNoteDoc = {};
    }

    // set type and data
    this.pageData.activityNoteDoc.type = MyUtil.DOC_TYPE.ACTIVITY_NOTE;
    this.pageData.activityNoteDoc.data = {
      activity_id: this.pageData.activity.id,
      text: this.pageData.noteForm.value.note_text, // empty text means delete
    };

    // remove ts if any for sync again
    delete this.pageData.activityNoteDoc.ts;

    return MyDb.userSave(this.pageData.activityNoteDoc)
      .then((doc) => {
        this.pageData.activityNoteDoc = doc;
        this.pageData.activityNoteDisplay = doc.data.text.replace(/\n/g, '<br/>');

        // attempt server update
        return this.appapi.saveUserActivities().then((result1) => {
          if(!!result1) {
            return this.appapi.saveActivityNotes().then((result2) => {
              if(!!result2) {
                MyUtil.presentToast("Activity reflections saved", { cssClass: 'inkpath-toast' });
              }
            });
          }
        });
      })
      .catch(() => {
        this.pageData.activityNoteDoc = null;
      });
  }

  private uploadEvidenceFile() {
    this.pageData.noBookingWarningPopup = true;
    if (!MyUtil.isNetworkConnected()) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }

    if (
      !this.pageData.userActivityDoc ||
      !this.pageData.userActivityDoc.data ||
      !this.pageData.userActivityDoc.data.id
    ) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet, sync data with the server and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }

    if (this.pageData.activity.course_id) {
      //Pass in activity object if course activity - so it can be passed back to the page after (might not be in cache)
      this.router.navigate(['/ManagedFilePage', JSON.stringify({ activityObject: this.pageData.activity, 
                                                                userActivityId: this.pageData.userActivityDoc.data.id,
                                                                isCourseActivity: this.pageData.isCourseActivity
                                                              })]);
    } else {
      //Just pass in the activity id
      this.router.navigate(['/ManagedFilePage', JSON.stringify({ id: this.pageData.activity.id, userActivityId: this.pageData.userActivityDoc.data.id })]);
    }
  }

  private async viewEvidenceFile() {
    if (!MyUtil.isNetworkConnected()) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }

    if (
      this.pageData.userActivityDoc &&
      !this.pageData.userActivityDoc.deleted &&
      this.pageData.userActivityDoc.data &&
      this.pageData.userActivityDoc.data.id &&
      this.pageData.userActivityEvidences &&
      this.pageData.userActivityEvidences.length
    ) {
      let evidence = this.pageData.userActivityEvidences[0];
      MyUtil.debug(evidence);

      let loading = MyUtil.presentLoading();
      if (MyUtil.isMobileAppMode()) {
        const url = this.appapi.getEvidenceFileUrl(
          this.pageData.userActivityDoc.data.id,
          evidence.id
        );

        (await loading).dismiss();
        this.appapi.openAppHelpBrowser(url);
        //const fileTransfer: FileTransferObject = this.transfer.create();
        // this.fileTransfer
        //   .download(url, this.file.dataDirectory + evidence.name)
        //   .then(
        //     (entry) => {
        //       let localUrl = entry.toURL();
        //       MyUtil.debug("download complete: " + localUrl);
        //       (<any>window).performFileOpener(
        //         localUrl,
        //         evidence.type,
        //         async () => {
        //           (await loading).dismiss();
        //           MyUtil.debug("File opened");
        //         },
        //         async (err) => {
        //           (await loading).dismiss();
        //           MyUtil.debug("Fail to open");
        //           MyUtil.debug(err);
        //         }
        //       );
        //     },
        //     async (error) => {
        //       (await loading).dismiss();
        //       MyUtil.debug(error);
        //     }
        //   );
      } else {
        // Browser download
        (await loading).dismiss();
        const uri = this.appapi.getEvidenceFileUri(
          this.pageData.userActivityDoc.data.id,
          evidence.id
        );
        this.appapi.openAppHelpBrowser(uri);
      }
    }
  }

  private removeEvidenceFile() {
    if (!MyUtil.isNetworkConnected()) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }

    MyUtil.presentAlert({
      title: "Are you sure?",
      message: "Your file will be deleted.",
      buttons: [
        {
          text: "Cancel",
          handler: () => {
            return;
          },
        },
        {
          text: "Confirm",
          handler: () => {
            this.deleteEvidenceFile();
          },
        },
      ],
    });
  }

  private async deleteEvidenceFile() {
    if (
      this.pageData.userActivityDoc &&
      !this.pageData.userActivityDoc.deleted &&
      this.pageData.userActivityDoc.data &&
      this.pageData.userActivityDoc.data.id &&
      this.pageData.userActivityEvidences &&
      this.pageData.userActivityEvidences.length
    ) {
      let evidence = this.pageData.userActivityEvidences[0];
      MyUtil.debug(evidence);

      await this.fileService.removeEvidences(this.pageData.userActivityDoc.data.id, evidence.id)
        .catch((err) => {
          MyUtil.error(err);
        });

      // remove the local reference anyway
      this.pageData.userActivityEvidences = null;
    }
  }

  private checkUserBooking(): Promise<any> {
    return this.activityService
      .loadActivityBooking(this.pageData.activity.id)
      .then((result) => {
        this.pageData.activityBooking = result;
        return result;
      });
  }

  private async cancelUserBooking() {
    if (!MyUtil.isNetworkConnected()) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }

    let title = "Are you sure you want to cancel your booking?";
    let buttonText = "Cancel booking";
    if (this.pageData.activityBooking.status === 1) {
      let listType = 'waiting';
      if((this.pageData.activityBooking.hybrid_online && this.pageData.bookingDetails?.online_capacity === 0) || ((!this.pageData.activityBooking.hybrid_online && this.pageData.bookingDetails?.capacity === 0))) {
        listType = 'application';
      }
      title = "Are you sure you want to cancel your place on the " + listType + " list?";
      buttonText = "Cancel place";
    }

    let cancelBookingModal = MyUtil.createModal(CancelBookingModal, {
      title: title,
      message:
        "This activity will still be shown in your saved activities. Please select the reason for your cancellation.",
      buttons: [
        {
          text: "Close",
        },
        {
          text: buttonText,
        },
      ],
      labels: MyUtil.CANCEL_BOOKINGS_LABELS,
    });

    (await cancelBookingModal).onDidDismiss().then((labelKey: any) => {
      if (labelKey.data) {
        let loading = MyUtil.presentLoading();
        this.activityService.cancelBooking(this.pageData.activity.id, labelKey.data).then(async () => {
          (await loading).dismiss();
          this.refreshPage();
        });
      }
    });

    (await cancelBookingModal).present();
  }



  private fetchBookingMetadata(): Promise<any> {
    this.pageData.activity = (this.pageData.activity) ? this.pageData.activity : this.routeData.activityObject;

    return this.activityService.loadBookingMetadata(this.pageData.activity.id).then((result) => {
      if (result && result.bookable_id) {
        //Activity requires booking
        this.pageData.bookingDetails = result;
        this.pageData.hasCapacity = this.pageData.bookingDetails.has_capacity;
        this.pageData.hasOnlineCapacity = this.pageData.bookingDetails.has_online_capacity;
      } else {
        //Activity not bookable - user can just turn up/join
        this.pageData.bookingDetails = null;
        this.pageData.hasCapacity = true;
        this.pageData.hasOnlineCapacity = true;
        this.pageData.showJoiningLink = true;
      }

      return result;
    });
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
