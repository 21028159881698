import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileTransfer, FileTransferObject } from "@awesome-cordova-plugins/file-transfer/ngx";
import { NavParams } from "@ionic/angular";
import * as Moment from "moment";
import { NgxCopilotService } from "ngx-copilot";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CancelBookingModal } from "src/app/components/cancel-booking-modal/cancel-booking-modal";
import { ActivityService } from "src/app/providers/activity-service";
import { Appapi } from "src/app/providers/appapi";
import { CoursesService } from "src/app/providers/courses-service";
import { DesktopChecker } from "src/app/providers/desktopChecker";
import { FileService } from "src/app/providers/file-service";
import { UiuxService } from "src/app/services/uiux.service";
import { WalkthroughService } from "src/app/services/walkthrough.service";
import { MyUtil } from "../../../libs/MyUtil";
import { LocationMapPage } from "../location-map/location-map";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { TermsModalComponent } from "src/app/components/terms-modal/terms-modal.component";
import { RecurringEventsModalComponent } from 'src/app/components/recurring-events-modal/recurring-events-modal.component';

declare var google;

@Component({
  selector: "page-activity-detail",
  templateUrl: "activity-detail.html",
  styleUrls: ['activity-detail.scss'],

})
export class ActivityDetailPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  isMobileView = this.uiux.isMobileView();
  pageLabel = "ActivityDetailPage";
  fileTransfer: FileTransferObject = this.transfer.create();

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  id: any;

  constructor(
    public router: Router,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    public uiux: UiuxService,
    public desktopChecker: DesktopChecker,
    public sanitiser: DomSanitizer,
    public activityService: ActivityService,
    public fileService: FileService,
    public transfer: FileTransfer,
    public file: File,
    public courseService: CoursesService,
    private copilot: NgxCopilotService,
    private route: ActivatedRoute,
    private titleService: Title,) 
  {
    this.titleService.setTitle("Activity Detail");
  }

  /** 
   * Re initialize and specify step.
   * @param stepNumber   stepNumber: string.
   */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
   * Next step.
   * @param stepNumber   stepNumber: string.
   */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  async ngOnInit() {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }
    
    let loading = MyUtil.presentLoading();

    let key = MyUtil.cache[MyUtil.DOC_ID.APP_SETTINGS]["GOOGLE_MAP_API_KEY"];
    (function (d, s, id, key) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://maps.google.com/maps/api/js?key=" + key;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-map-js", key);

    this.startWalkthrough()

    this.pageData.noBookingWarningPopup = false;
    this.pageData.displayHelpIcon = true;
    this.pageData.clashingActivities = [];
    this.pageData.recurringGroup = null;
    this.pageData.showVenue = false;
    this.pageData.showJoiningLink = false;
    this.pageData.userActivityEvidences = null;

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData)
    });

    //Refresh from back-end
    if(!this.routeData.attendanceCode) {
      //Don't need to resync if redirected from the qr-scanner component - already been done
      await this.appapi.syncSingleActivity(this.routeData.id);
    }

    //Check activity exists
    this.pageData.activity = MyUtil.getActivity(this.routeData.id);
    if(!this.pageData.activity) {
      (await loading).dismiss();
      MyUtil.presentToast('Activity not found', { cssClass: 'error' });
      this.router.navigate(['/']);
      return;
    }

    this.handleCalendarLinkModal();

    //Get user activity/booking details
    this.pageData.userActivity = MyUtil.getUserActivity(this.pageData.activity.id);

    //Activity started?
    let currentTimestamp = MyUtil.getUnixTimeStamp();
    this.pageData.activityStarted = currentTimestamp > this.pageData.activity.start_ts;
    this.pageData.activityDateStarted = currentTimestamp > this.pageData.activity.start_date_ts;

    //Go to complete page if attendance code supplied and activity either added but not completed, or not added at all (to allow walk-ins)
    let attendanceCode = this.routeData.attendanceCode;
    if (attendanceCode && ((this.pageData.userActivity && !this.pageData.userActivity.completed_at) || !this.pageData.userActivity)) {
      if (this.pageData.activityDateStarted) {
        (await loading).dismiss();
        this.completeActivity(attendanceCode);
      } else {
        MyUtil.presentToast('Activity cannot be completed yet', { cssClass: 'inkpath-toast' });
      }
    }

    //Fetch other data
    //TODO: Could these all the done in one API call?
    await Promise.all([
      this.fetchRecurringGroupDetails(),
      this.fetchCapacityStatusDetails(),
      this.fetchActivityNotes(),
      this.fetchEvidenceFile()
    ]);

    this.pageData.homeOrg = MyUtil.getRootOrganization();
    this.pageData.activityOrganization = MyUtil.getOrgnizationChainName(this.pageData.activity.oid);

    //Re-sync profile - in case strike ban conditions changed
    await this.appapi.checkAndRefreshProfile();
    this.pageData.profile = MyUtil.getProfile();
    
    //Get any clashing activities
    let clashableActivities = MyUtil.getClashableActivities();
    this.pageData.clashingActivities = MyUtil.getClashingActivities(this.pageData.activity, clashableActivities);
    
    //Define note form validation
    this.pageData.noteFormSubmitAttempt = false;
    this.pageData.noteEditMode = false;
    this.pageData.noteForm = this.formBuilder.group({
      note_text: ["", Validators.compose([Validators.required])],
    });
    
    //Activity complete?
    this.pageData.activityComplete = !!this.pageData.userActivity && !!this.pageData.userActivity.completed_at;
  
    //Set whether joining details can be shown
    this.setShowVenue();
    this.setShowJoiningLink();

    //Skills list
    let skillsArray = MyUtil.lodash
      .chain(this.pageData.activity.skills)
      .map((id) => {
        return MyUtil.getSkill(id).name;
      })
      .filter((item) => {
        return (item != undefined);
      })
      .value();
      
    this.pageData.skillsList = MyUtil.lodash.chain(skillsArray).value().join(", ");

    //Human-readable date range
    this.pageData.period = null;
    if(!(this.pageData.homeOrg.hide_ss_date_fields && (this.pageData.activity.is_self_study || this.pageData.activity.is_type_other))) {
      this.pageData.period = MyUtil.getLongDisplayDateTime(this.pageData.homeOrg,
        this.pageData.activity.start_at,
        this.pageData.activity.start_time,
        this.pageData.activity.end_at,
        this.pageData.activity.end_time
      );
    }

    //Disabled text (booking open/closed)
    let nowTimestamp = MyUtil.getUnixTimeStamp();
    this.pageData.invalidBookingTimeframe = null;
    if(this.pageData.activity.booking_via_inkpath) {
      if(this.pageData.activity.booking_opens_ts > nowTimestamp) {
        this.pageData.invalidBookingTimeframe = "Booking opens at " + this.pageData.activity.booking_opens_formatted;
      } else if(this.pageData.activity.booking_closes_ts < nowTimestamp) {
        this.pageData.invalidBookingTimeframe = "Booking closed";
      }
    }

    //Strike ban text (exclude shared activities)
    this.pageData.strikeBanText = null;
    if(this.pageData.profile.strike_ban_text && (this.pageData.activity.root_oid == this.pageData.profile.root_oid)) {
      this.pageData.strikeBanText = this.pageData.profile.strike_ban_text;
    }

    (await loading).dismiss();
    this.pageData.loaded = true;

    MyUtil.firebaseSetScreenName("activity-detail");
    MyUtil.firebaseLogEvent("view_did_enter", {
      name: "activity-detail",
      data: this.routeData.id,
    });

    this.startWalkthrough();
  }


  /**
   * Gets any alternative dates (defined by the recurring group)
   * 
   * @returns Promise<any>
   */
  private fetchRecurringGroupDetails() : Promise<any> {
    this.pageData.recurringRulesRestricted = false;
    this.pageData.recurringGroup = null;
    if(!this.pageData.activity.course_id && this.pageData.activity.recurring_group_id) {
      return this.appapi.getRecurringGroup(this.pageData.activity.recurring_group_id, this.pageData.activity.id).then((result) => {
        this.pageData.recurringGroup = result;
        this.pageData.recurringRulesRestricted = this.pageData.recurringGroup.booking_restricted;
      });
    } else {
      return;
    }
  }

  /**
   * Get booking capacity details
   * 
   * @returns Promise<any>
   */
  private fetchCapacityStatusDetails() : Promise<any> {
    this.pageData.capacityStatus = null;
    if(this.pageData.activity.booking_via_inkpath) {
      return this.appapi.getBookingCapacityStatus(this.pageData.activity.id).then((capacityStatus) => {
        this.pageData.capacityStatus = capacityStatus;
      });
    } else {
      return;
    }
  }


  /**
   * Gets any submitted activity notes ("reflections")
   * 
   * @returns Promise<any>
   */
  private fetchActivityNotes() : Promise<any> {
    this.pageData.activityNoteDisplay = "";
    if (this.pageData.userActivity) {
      return this.appapi.getActivityNote(this.pageData.activity.id).then((noteDetails) => {
        this.pageData.activityNoteDisplay = noteDetails?.text.replace(/\n/g, '<br/>');
      });
    } else {
      return;
    }
  }


  /**
   * Gets any uploaded file
   * 
   * @returns Promise<any>
   */
  private fetchEvidenceFile() : Promise<any> {
    if (this.pageData.userActivity) {
      return this.fileService.retrieveEvidences(this.pageData.userActivity.id).then((fileDetails) => {
        this.pageData.userActivityEvidences = fileDetails;
      });
    } else {
      return;
    }
  }


  /**
   * Sets whether the venue can be displayed
   * Rules: 
   * If activity requires booking, user must have a firm booking, the booking must be *in-person* and the activity must have a venue set
   * If booking not required, activity must be "added", the activity must be attendable *in-person* and the activity must have a venue set
   */
  private setShowVenue() {

    let showVenue = false;
    if(this.pageData.userActivity) {
      //Activity is added
      if (this.pageData.activity.booking_via_inkpath) {
        //Activity is bookable
        if(this.pageData.userActivity.is_booked) {
          //User has a confirmed booking
          if(this.pageData.activity.is_hybrid) {
            if(!this.pageData.userActivity.is_hybrid_online) {
              //In-person booking for hybrid activity
              showVenue = !!this.pageData.activity.venue;
            }
          } else if(this.pageData.activity.attendance_type_id != MyUtil.CONST.APP_META.ATTENDANCE_TYPE_LIVE_ONLINE) {
            //Booking for in-person event
            showVenue = !!this.pageData.activity.venue;
          }
        }
      } else {
        //Activity not bookable
        if(this.pageData.activity.attendance_type_id != MyUtil.CONST.APP_META.ATTENDANCE_TYPE_LIVE_ONLINE) {
          //In-person or hybrid event - show venue if value is set
          showVenue = !!this.pageData.activity.venue;
        }
      }
    }

    this.pageData.showVenue = showVenue;
  }


   /**
   * Sets whether the joining link can be displayed
   * Rules: 
   * If activity requires booking, user must have a firm booking, the booking must be *online* and the activity must have a valid joining link url
   * If booking not required, activity must be "added", the activity must be attendable *online* and the activity must have a valid joining link url
   */
   private setShowJoiningLink() {

    let showJoiningLink = false;
    if(this.pageData.userActivity) {
      //Activity is added
      if (this.pageData.activity.booking_via_inkpath) {
        //Activity is bookable
        if(this.pageData.userActivity.is_booked) {
          //User has a confirmed booking
          if(this.pageData.activity.is_hybrid) {
            if(this.pageData.userActivity.is_hybrid_online) {
              //Online booking for hybrid activity
              showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
            }
          } else if(this.pageData.activity.attendance_type_id != MyUtil.CONST.APP_META.ATTENDANCE_TYPE_LIVE_IN_PERSON) {
            //Booking for online event
            showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
          }
        }
      } else {
        //Activity not bookable
        if(this.pageData.activity.attendance_type_id != MyUtil.CONST.APP_META.ATTENDANCE_TYPE_LIVE_IN_PERSON) {
          //Not in-person or is a hybrid event - show joining link if value is set and is a valid url
          showJoiningLink = this.activityService.isValidUrl(this.pageData.activity.joining_link);
        }
      }
    }

    this.pageData.showJoiningLink = showJoiningLink;
  }


  /** 
  * Refresh page.
  */
  public refreshPage() {
    //Passing in a UNIX timestamp ensures the page is refreshed every time.
    this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: this.pageData.activity.id, t: MyUtil.getUnixTimeStamp() })]);
  }


  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param options     Parameter to process in action.
  */
  async process(action: string, options?: any) {

    switch (action) {
      case "add-calender":
        this.addToCalender();
        return;
      case "add-activity":
        this.addToMyActivities();
        return;
      case "remove-activity":
        this.confirmRemoveFromMyActivities();
        return;
      case "visit-booking":
        if(this.pageData.activity.terms_text) {
          //Display popup asking user to agree to Ts&Cs
          let modal = MyUtil.createModal(TermsModalComponent,
            {
              message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.activity.terms_text),
            });
          (await modal).onDidDismiss().then((data: any) => { 
            if (data.data !== undefined) {
              this.launchBooking(options);
            } 
          });
          (await modal).present();
        } else {
          //No Ts&Cs
          this.launchBooking(options);
        }
        return;
      case "convert-booking":
        let convertFrom = options.hybridOnline ? 'in-person' : 'online';
        let convertTo = options.hybridOnline ? 'online' : 'in-person';

        let fromStatus = '';
        if(this.pageData.userActivity.is_booked) {
          fromStatus = 'booking';
        } else if(this.pageData.userActivity.on_waiting_list) {
          fromStatus = 'waiting list';
        } else if(this.pageData.userActivity.on_application_list) {
          fromStatus = 'application list';
        }

        let toStatus = '';
        if(options.firmBooking) {
          toStatus = 'booking';
        } else if(options.waitingList) {
          toStatus = 'waiting list';
        } else if(options.applicationList) {
          toStatus = 'application list';
        }

        if(fromStatus && toStatus) {
          let convertMessage = `<b>Convert from ${convertFrom} ${fromStatus} to ${convertTo} ${toStatus}?</b><br/><br/>This will release your ${convertFrom} place and move you to the ${convertTo} ${toStatus}.`;
          
          if(this.pageData.userActivity.is_booked && !options.firmBooking) {
            convertMessage = convertMessage + " You will no longer have a confirmed place.";
          } else if(this.pageData.userActivity.on_waiting_list) {
            convertMessage = convertMessage + ` The ${convertFrom} event is full, so you may not be able to return to an ${convertFrom} booking.`;
          } else if(this.pageData.userActivity.on_application_list) {
            convertMessage = convertMessage + ` The ${convertFrom} event is by application only, so you may not be able to return to an ${convertFrom} booking.`;
          }
        
          MyUtil.presentAlert({
            message: convertMessage,
            buttons: [
              {
                text: 'Cancel',
                handler: () => {
                  return;
                }
              },
              {
                text: 'Confirm',
                handler: () => {
                  this.launchBooking(options);
                }
              }
            ],
          });
        }
        return;
      case "visit-info":
        this.launchExternalInfo(options);
        return;
      case "cancel-booking":
        this.cancelUserBooking();
        return;
      case "visit-location":
        if (google) {
          this.launchMap();
        } else {
          MyUtil.presentToast(
            "Map is not available. Please check the connection and try again later.", { cssClass: 'inkpath-toast' }
          );
        }
        return;
      case "complete-activity":
        this.completeActivity();
        return;
      case "edit-user-generated-activity":
        this.editUserGeneratedActivity();
        return;
      case "copy-user-generated-activity":
        this.copyUserGeneratedActivity();
        return;
      case "remove-user-generated-activity":
        this.confirmDeleteUserGeneratedActivity();
        return;
      case "add-note":
      case "edit-note":
        if (this.pageData.activityNoteDisplay) {
          this.pageData.noteForm.controls.note_text.setValue(
            this.pageData.activityNoteDisplay.replace(/<br\s*\/?>/g, "\n")
          );
        }
        this.pageData.noteEditMode = true;
        return;
      case "save-note":
        this.updateActivityNote().then(() => {
          this.pageData.noteEditMode = false;
        });
        return;
      case "upload-evidence-file":
        this.uploadEvidenceFile();
        return;
      case "view-evidence-file":
        this.viewEvidenceFile();
        return;
      case "remove-evidence-file":
        this.confirmDeleteEvidenceFile();
        return;
      case "leave-feedback":
        window.open(this.pageData.activity.external_feedback_url, '_blank');
        return;
      case "more-dates":
        if(this.pageData.recurringGroup && this.pageData.recurringGroup.alternative_dates.length > 0) {
          //Display popup showing alternative dates for this activity
          let modal = MyUtil.createModal(RecurringEventsModalComponent,
            {
              message: '',
              eventName: this.pageData.activity.name,
              type: 'activity',
              alternativeDates: this.pageData.recurringGroup.alternative_dates 
            });
          (await modal).present();
        }
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }


  
  /**
   * Adds the activity to the user's My Activities
   */
  private addToMyActivities() {

    if(this.pageData.userActivity) {
      MyUtil.presentToast("Activity is already in your personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    this.appapi.addToMyActivities(this.pageData.activity.id).then((result) => {
      if (result['#status'] === 'success') {
        MyUtil.presentToast("This activity has been added to your personal activity list", {
          duration: MyUtil.CONST.DURATION_TOAST_LONG,
          cssClass: 'inkpath-toast'
        });
        this.refreshPage();
      }
    });
  }


  /**
   * Check if activity is complete or has uploaded file - and in either case get confirmation from user before removing
   * 
   * @returns 
   */
  private confirmRemoveFromMyActivities() {

    if (!this.pageData.userActivity) {
      MyUtil.presentToast("Activity is not in personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    if (this.pageData.activityComplete || (this.pageData.userActivityEvidences?.length > 0)) {
      // prepare message according context
      let msg = "This activity has been marked as complete. Are you sure you wish to remove this activity?";
      if (this.pageData.activityComplete && this.pageData.userActivityEvidences && this.pageData.userActivityEvidences[0]) {
        msg = "Are you sure you wish to remove this activity, which has been marked as complete? Your attached file will be deleted.";
      } else if (!this.pageData.activityComplete && this.pageData.userActivityEvidences && this.pageData.userActivityEvidences[0]) {
          msg = "Are you sure you wish to remove this activity? Your attached file will be deleted.";
      }

      MyUtil.presentAlert({
        title: "Are you sure?",
        message: msg,
        buttons: [
          {
            text: "Cancel",
            handler: () => {
              return;
            },
          },
          {
            text: "Confirm",
            handler: () => {
              this.removeFromMyActivities();
            },
          },
        ],
      });
    } else {
      this.removeFromMyActivities();
    }
  }


  /**
   * Revoves activity from My Activities 
   */
  private removeFromMyActivities(): void {

    // also remove any related evidence files - do this first, because depends on user_activities record still existing
    this.deleteEvidenceFile();

    this.appapi.removeFromMyActivities(this.pageData.activity.id).then((result) => {
      if (result['#status'] === 'success') {
        MyUtil.presentToast("This activity has been removed from your personal activity list", {
          duration: MyUtil.CONST.DURATION_TOAST_LONG,
          cssClass: 'inkpath-toast'
        });
        this.refreshPage();
      }
    });
  }


  /**
   * Navigates to the completion page
   * 
   * @param attendanceCode 
   */
  private async completeActivity(attendanceCode : number = null) {

    // go to complete page, no pop up
    this.pageData.noBookingWarningPopup = true;

    //Check if activity added - if not, add (to enable walk-ins)
    if (!this.pageData.userActivity) {
      await this.appapi.addToMyActivities(this.pageData.activity.id).catch(() => {
        return;
      });
    }

    let params = {
      id: this.pageData.activity.id,
      backToGoal: !!this.routeData.backToGoal,
      attendanceCode: attendanceCode,
    };

    this.router.navigate(['/ActivityCompletePage', JSON.stringify(params)]);
  }


  /**
   * Navigates to edit UGC page
   */
  private editUserGeneratedActivity() {
    this.router.navigate(['/ActivityEditPage', JSON.stringify({ id: this.pageData.activity.id })]);
  }


  /**
   * Navigates to edit UGC page with the "copy" flag set
   */
  private copyUserGeneratedActivity() {
    this.router.navigate(['/ActivityEditPage', JSON.stringify({ id: this.pageData.activity.id, copy: true })]);
  }


  /**
   * Gets the user to confirm if they want to delete their UGC activity
   */
  private confirmDeleteUserGeneratedActivity() {

    if(this.pageData.activity.profile_id != this.pageData.profile.id) {
      MyUtil.presentToast(
        "Cannot delete - this activity was not created by you",
        { cssClass: 'inkpath-toast' }
      );
      return;
    }

    // not allow to delet if used by goal
    let referredGoals = MyUtil.getGoalsReferredActivityId(this.pageData.activity.id);
    if (!MyUtil.lodash.isEmpty(referredGoals)) {
      MyUtil.presentToast(
        "Activity could not be deleted because it is used by goals",
        { cssClass: 'inkpath-toast' }
      );
      return;
    }

    MyUtil.presentAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this activity?",
      buttons: [
        {
          text: "Cancel",
          handler: () => {
            return;
          },
        },
        {
          text: "Confirm",
          handler: () => {
            this.deleteUserGeneratedActivity();
          },
        },
      ],
    });
  }


  /**
   * Deletes the UGC activity
   * 
   * //TODO - check all linked data cascades (notes, files etc)
   */
  private deleteUserGeneratedActivity(): void {

    this.appapi.deleteUserGeneratedActivity(this.pageData.activity.id).then((result) => {
      if (result['#status'] === 'success') {
        MyUtil.presentToast("Activity has been removed", {
          duration: MyUtil.CONST.DURATION_TOAST_LONG,
          cssClass: 'inkpath-toast'
        });
        this.router.navigate(['/']);
      }
    });
  }



  /**
   * Launches the external activity homepage
   * @param url 
   */
  private launchExternalInfo(url) {
    let browser = MyUtil.createInAppBrowser(url, "_system");
    browser.show;
  }



  /**
   * Start booking process
   * 
   * @param options (firmBooking : boolean, waitingList: boolean, applicationList : boolean, hybridOnline: boolean, convertBooking: boolean)
   */
  private  launchBooking(options : any) {

    //add conditionals in here for internal, external or none
    if (this.pageData.activity.booking_via_inkpath) {
      //Get the user requirement options
      this.appapi.getBookingOptions(this.pageData.activity.id).then((bookingOptions) => {
        if(options.hybridOnline && bookingOptions?.length > 0) {
          //Remove options not relevant to an online booking
          bookingOptions = bookingOptions.filter((detail) => {
            return detail.label != 'require_accommodation' 
              && detail.label != 'require_parking' 
              && detail.label != 'require_dietary' 
              && detail.label != 'require_wheelchair' 
              && detail.label != 'require_accessible_parking'
          })
        }
  
        if (bookingOptions?.length == 0 && this.pageData.clashingActivities.length == 0) {
          //No booking requirements details and no clashng activities - no need for intermediary booking page
          let formData = {
            details: [],
            bookable_id: this.pageData.activity.id,
            hybrid_online: options.hybridOnline,
            convert_booking: options.convertBooking,
          };
          
          //Save booking (NB. the backend also does an "add to My Activities" in the db)
          let loading = MyUtil.presentLoading();
          this.activityService.saveBooking(formData).then(async (result) => {
            if (result) {
              let message = "Booking confirmed";
              if(options.convertBooking) {
                let type = options.hybridOnline ? 'online' : 'in-person';
                message = `Booking converted to ${type}`;
                if (options.waitingList) {
                  message = `You have been added to the ${type} activity waiting list`;
                } else if (options.applicationList) {
                  message = `You have been added to the ${type} activity application list`;
                }
              } else {
                if (options.waitingList) {
                  message = "You have been added to the activity waiting list";
                } else if (options.applicationList) {
                  message = "You have been added to the activity application list";
                }
              }

              MyUtil.presentToast(message, {
                duration: MyUtil.CONST.DURATION_TOAST_LONG,
                cssClass: 'inkpath-toast'
              });
              this.refreshPage();  
            }
            (await loading).dismiss();
          }).catch(async () => {
            (await loading).dismiss();
          });
        } else {
          //Display booking page
          let params = {
            id: this.pageData.activity.id,
            clashingActivities: this.pageData.clashingActivities,
            options : options,
            details: bookingOptions,
          };
  
          this.router.navigate(['/ActivityBookingPage', JSON.stringify(params)]);
        }
      });
    } else if (this.pageData.activity.booking_external) {
      //Go to external page
      this.addToMyActivities();
      let browser = MyUtil.createInAppBrowser(
        this.pageData.activity.external_booking_url,
        "_system"
      );
      browser.show;
    } else {
      MyUtil.presentToast("The activity is not bookable.", { cssClass: 'inkpath-toast' });
    }
  }


  /** 
   * Launches Google Map of location
   */
  private async launchMap() {
    let data = this.pageData.activity;
    let locationModal = MyUtil.createModal(LocationMapPage, {
      venue: data.venue,
      lat: data.latitude,
      long: data.longitude,
    });
    (await locationModal).present();
  }


  //TODO: Check if this is still current
  private addToCalender() {
    let data = this.pageData.activity;
    let start = this.processActivityDateTime(
      data.start_at,
      data.start_time,
      false
    );
    let end = this.processActivityDateTime(data.end_at, data.end_time, false);
    let title = data.name;
    let eventLocation = data.venue;
    let notes = data.description;
    let options = MyUtil.calendarGetOptions();
    if (!data.start_time && !data.end_time) {
      options["allday"] = true;
      // add one day to the end to have all days included in the event
      // and ios not allow creating all day event with same start and end
      end = this.processActivityDateTime(data.end_at, data.end_time, true);
    }

    MyUtil.calendarCreateEventWithOptions(
      title,
      eventLocation,
      notes,
      start,
      end,
      options
    ).then(
      function (result) {
        // success
        MyUtil.presentToast("Activity has been added to the device calendar");
      },
      function (err) {
        MyUtil.presentToast(
          "Sorry, failed to add the activity to the device calendar", { cssClass: 'inkpath-toast' }
        );
        MyUtil.error(err);
      }
    );
  }

  private processActivityDateTime(date, time: string, adjust: boolean) {
    let dateTime: any;
    let moment = Moment(date * 1000);
    let year = moment.year();
    let month = moment.month();
    let day = moment.date();

    if (time) {
      let hour = Number(time.split(":")[0]);
      let minute = Number(time.split(":")[1]);

      dateTime = Moment([year, month, day, hour, minute]);
    } else {
      dateTime = Moment([year, month, day]);
    }

    // adjust to add one day
    if (adjust) {
      dateTime.add(1, "d");
    }

    return dateTime.toDate();
  }


  /**
   * Updates the activity reflection
   */
  private updateActivityNote() {

    return this.appapi.saveActivityNote(this.pageData.activity.id, this.pageData.noteForm.value.note_text).then((result) => {
      if (result['#status'] === 'success') {
        this.pageData.activityNoteDisplay = this.pageData.noteForm.value.note_text.replace(/\n/g, '<br/>');
        MyUtil.presentToast("Reflection saved", { cssClass: 'inkpath-toast' });
      }
    });
  }


  /**
   * Uploads a file and attaches it to the activity
   */
  private uploadEvidenceFile() {

    //TODO: Still needed?
    this.pageData.noBookingWarningPopup = true;

    if (!this.pageData.userActivity) {
      MyUtil.presentToast("Activity is not in personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    this.router.navigate(['/ManagedFilePage', JSON.stringify({ activityId: this.pageData.activity.id, userActivityId: this.pageData.userActivity.id })]);
  }

  /**
   * View uploaded file
   */
  private async viewEvidenceFile() {

    if (!this.pageData.userActivity) {
      MyUtil.presentToast("Activity is not in personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    if (this.pageData.userActivity && this.pageData.userActivityEvidences?.length > 0) {
      let evidence = this.pageData.userActivityEvidences[0];
      let loading = MyUtil.presentLoading();
      if (MyUtil.isMobileAppMode()) {
        const url = this.appapi.getEvidenceFileUrl(this.pageData.userActivity.id, evidence.id);
        (await loading).dismiss();
        this.appapi.openAppHelpBrowser(url);
      } else {
        // Browser download
        (await loading).dismiss();
        const uri = this.appapi.getEvidenceFileUri(this.pageData.userActivity.id, evidence.id);
        this.appapi.openAppHelpBrowser(uri);
      }
    }
  }


  /**
   * Gets the user to confirm if they wish to remove their uploaded file
   */
  private confirmDeleteEvidenceFile() {

    if (!this.pageData.userActivity) {
      MyUtil.presentToast("Activity is not in personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    MyUtil.presentAlert({
      title: "Are you sure?",
      message: "Your attached file will be removed. Are you sure?",
      buttons: [
        {
          text: "Cancel",
          handler: () => {
            return;
          },
        },
        {
          text: "Confirm",
          handler: () => {
            this.deleteEvidenceFile();
          },
        },
      ],
    });
  }


  /**
   * Removes the uploaded file from the activity
   */
  private async deleteEvidenceFile() {

    if (this.pageData.userActivity && this.pageData.userActivityEvidences?.length) {
      let evidence = this.pageData.userActivityEvidences[0];
      await this.fileService.removeEvidences(this.pageData.userActivity.id, evidence.id).then((result) => {
        MyUtil.presentToast("Your attached file has been removed", { cssClass: 'inkpath-toast' });
      }).catch((err) => {
        MyUtil.error(err);
      });

      // remove the local reference anyway
      this.pageData.userActivityEvidences = null;
    }
  }


  /**
   * Cancels the user's booking
   */
  private async cancelUserBooking() {

    if (!this.pageData.userActivity) {
      MyUtil.presentToast("Activity is not in personal list", { cssClass: 'inkpath-toast' });
      return;
    }

    if (!this.pageData.userActivity.has_reservation) {
      MyUtil.presentToast("Activity is not booked/reserved", { cssClass: 'inkpath-toast' });
      return;
    }

    let title = "Are you sure you want to cancel your booking?";
    let buttonText = "Cancel booking";

    //Waiting/application list text
    if (this.pageData.userActivity.on_waiting_list || this.pageData.userActivity.on_application_list) {
      if(this.pageData.userActivity.on_waiting_list) {
        title = "Are you sure you want to cancel your place on the waiting list?";
      } else if(this.pageData.userActivity.on_application_list) {
        title = "Are you sure you want to cancel your place on the application list?";
      }
      buttonText = "Cancel place";
    }

    let cancelBookingModal = MyUtil.createModal(CancelBookingModal, {
      title: title,
      message: "This activity will still be shown in your saved activities. Please select the reason for your cancellation:",
      buttons: [
        {
          text: "Close",
        },
        {
          text: buttonText,
        },
      ],
      labels: MyUtil.CANCEL_BOOKINGS_LABELS,
    });

    (await cancelBookingModal).onDidDismiss().then((labelKey: any) => {
      if (labelKey.data) {
        let loading = MyUtil.presentLoading();
        this.activityService.cancelBooking(this.pageData.activity.id, labelKey.data).then(async () => {
          (await loading).dismiss();
          this.refreshPage();
        });
      }
    });

    (await cancelBookingModal).present();
  }


  /** 
   * Checks if profile matches the activity's programme, phase and subsidiary - for direct calenadar and "restricted" links 
  */
  handleCalendarLinkModal() {

    // If is not an admin viewing the page from the admin area ignore the filters and only apply to calendar links.
    if (this.routeData.calendar) {
  
      // Filter by: phases.
      let prof = MyUtil.getProfile();
      let phasekeys = [prof.phase_id];
      if (!!prof.fundings) {
        let phases = Object.keys(prof.fundings);
        phases.forEach(phase => {
          phasekeys.push(prof.fundings[phase].phase_id);
        });
      }

      let containsPhases = false;
      if (this.pageData.activity?.phases && this.pageData.activity?.phases.length > 0) {
        this.pageData.activity?.phases.forEach(element => {
          if (phasekeys.indexOf(element) > -1) {
            containsPhases = true;
          }
        });
      } else {
        containsPhases = false;
      }
      // If user has no related phases or programmes relating to the activity show a message.
      if ( 
          !this.isPartOfUserOrganisation(this.pageData.activity) || 
          !this.isPartOfPhase(phasekeys, this.pageData.activity) || 
          !this.isPartOfProgram(MyUtil.getProfileProgramIds(), this.pageData.activity)
        ) {
          // redirect to home page with message
          MyUtil.presentToast(
            "You don't have access to this activity.", { cssClass: 'inkpath-toast' }
          );

          this.router.navigate(['/']);

      }
    }
  }

  // check if user has access to the activity organisation
  isPartOfUserOrganisation(activity) {
    let orgs = MyUtil.getOrgnizationChain();
    let access = false;

    orgs.forEach( org => {
      if(org.id === activity.oid) {
        access = true;
      }
    })
    
    return access;
  }

  // Check if is part of program.
  isPartOfProgram(programIds, element) {
    //return function (element) {
      let contains = false;
      if (element.programs && element.programs.length > 0) {
        element.programs.forEach(element => {
          if (programIds.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    //}
  }

  // Check if is part of program.
  isPartOfPhase(phases, element) {
    //return function (element) {
      let contains = false;
      if (element.phases && element.phases.length > 0) {
        element.phases.forEach(element => {
          if (phases.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    //}
  }


  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('19');
      }, 1000);
    }
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }


  ionViewDidLeave() {
    MyUtil.firebaseLogEvent("view_did_leave", {
      name: "activity-detail",
      data: this.routeData.id,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
