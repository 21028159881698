import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { ActivityVO, OrganisationVO } from 'src/app/valueObjects/core.vo';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from 'src/libs/MyUtil';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],
})
export class RecommendedComponent implements OnInit {
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  component = ListItemComponent;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  homeOrg:OrganisationVO;
  pageData: any = {};
  activitiesList$: BehaviorSubject<ActivityListItemVO[]> = new BehaviorSubject([]);
  goalsList$: BehaviorSubject<ActivityListItemVO[]> = new BehaviorSubject([]);
  activeTab = 'activities';

  constructor(
    public appapi: Appapi,
    public activityService: ActivityService, 
    public router: Router,
    private titleService:Title) {
      this.titleService.setTitle("Recommended");
  }

  ngOnInit() {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    // Set home organisation
    this.homeOrg = MyUtil.getRootOrganization();

    let orgDetails = MyUtil.getAppOrg(this.homeOrg.id);
    let dateFormat = MyUtil.formatPHPDate(orgDetails[0].org_date_format);
    
    // Sync Activities.
    this.appapi.syncAllActivities().then( async () => {
      this.appapi.syncAllGoals().then( async () => {
        // Activities
        await this.appapi.getRecommendedActivities().then((result) => {
          
          let activities = result['#data'];
          let recommendedActivities:ActivityListItemVO[] = [];
          activities.forEach((activity:ActivityVO) => {

            //Format the start and end times
            let startDisplay = MyUtil.formatUnixTimeStampDate(activity.start_at, dateFormat);
            let endDisplay = MyUtil.formatUnixTimeStampDate(activity.end_at, dateFormat);

            let startTime = MyUtil.formatHISTimeFormatToAMPM(activity.start_time);
            let endTime = MyUtil.formatHISTimeFormatToAMPM(activity.end_time);

            // do we display the 3 letter timezone?
            if(orgDetails[0].display_timezone_abbr === 1) {
              let startAbbr = MyUtil.calculateTimezoneAbbr(activity.start_at, orgDetails[0].timezone_code);
              let endAbbr = MyUtil.calculateTimezoneAbbr(activity.end_at, orgDetails[0].timezone_code);
              startTime += " " + startAbbr;
              endTime += " " + endAbbr;
            }

            activity.period = (startDisplay === endDisplay ? startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) : 'from ' + startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) + ' to ' + endDisplay + (endTime === "00:00" ? '' : ' ' + endTime));
            
            //Format recommended skill
            activity.recommended_skill = '';
            if(activity.recommended_for_skill.length > 0) {
              let skill = MyUtil.getSkill(activity.recommended_for_skill);
              if(skill) {
                activity.recommended_skill = skill.name;
              }
            }

            let activityItem:ActivityListItemVO ={
              id: activity.id,
              name: activity.name,
              booking_type: activity.booking_type,
              provider: activity.provider,
              period: activity.period,
              homeOrg: this.homeOrg,
              oid: activity.oid,
              start_at: `${activity.start_at}`,
              end_at: activity.end_at,
              badge_details: activity.badge_details,
              price: activity.price,
              internal_reference: activity.internal_reference,
              venue: activity.venue,
              activity_template_id: activity.activity_template_id,
              attendance_type: activity.attendance_type,
              code: activity.code,
              is_course: 0,
              recommended_skill: activity.recommended_skill
            } 
            recommendedActivities.push(activityItem);
          });
          // Fill activities list.
          this.activitiesList$.next(recommendedActivities);
        })

        // Goals
        await this.appapi.getRecommendedGoals().then((result) => {
          let goals = result['#data'];
          let recommendedGoals:ActivityListItemVO[] = [];
          goals.forEach(goal => {
            //Format recommended skill
            goal.recommended_skill = '';
            if(goal.recommended_for_skill > 0) {
              let skill = MyUtil.getSkill(goal.recommended_for_skill);
              if(skill) {
                goal.recommended_skill = skill.name;
              }
            }
            let goalItem:ActivityListItemVO = {
              id: goal.id,
              name: goal.name,
              booking_type: goal.booking_type,
              provider: goal.provider,
              period: goal.period,
              oid: goal.oid,
              start_at: `${goal.start_at}`,
              end_at: goal.end_at,
              badge_details: goal.badge_details,
              price: goal.price,
              internal_reference: goal.internal_reference,
              venue: goal.venue,
              activity_template_id: goal.activity_template_id,
              attendance_type: goal.attendance_type,
              code: goal.code,
              is_course: 0,
              recommended_skill: goal.recommended_skill
            } 
            recommendedGoals.push(goalItem);
          });
          // Fill activities goals list.
          this.goalsList$.next(recommendedGoals);
          // Hide loader.
          this.loading$.next(false);
        })
      });
    });
  }

  /** 
  * View Activity Details.
  * @param id  ID of Activity.
  */
  public viewActivityDetails(id: number) {
    this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: id })]);
    return;
  }

  /** 
  * View Goal Details.
  * @param id  ID of goal.
  */
  public viewGoalDetails(id: number) {
    this.router.navigate(['/GoalDetailPage', id]);
    return;
  }

    /** 
  * For mobile view tabs.
  * @param label   Label of tab.
  */
  changeTab(label: string) {
    this.activeTab = label;
  }

  // On Destroy
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
