import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DiagnosticService } from '../../providers/diagnostic/diagnostic-service';
import { Appapi } from '../../providers/appapi';
import { DesktopChecker } from '../../providers/desktopChecker';
import { NavController, NavParams } from '@ionic/angular';
import { MyUtil } from 'src/libs/MyUtil';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-diagnostic-summary-list',
  templateUrl: 'diagnostic-summary-list.html',
  styleUrls: ['diagnostic-summary-list.scss'],
})
export class DiagnosticSummaryListPage implements OnInit, OnDestroy{

  // Component variables.
  @ViewChild('Content', {static: true}) content;
  previousSubmissions: any;
  pageData: any = {};
  routeData: any = {};
  loading: any;
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public router: Router,
    private route: ActivatedRoute,
    public diagnosticService: DiagnosticService,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Previous Questionnaire Submissions");
  }

  ngOnInit(): void {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData =JSON.parse(params.pageData);
    });

    this.diagnosticService.getPreviousDiagnosticSubmissions(this.routeData.id).then( res => {
      this.previousSubmissions = res.diagnostics;
    });

    let homeOrg = MyUtil.getRootOrganization();
    this.pageData.dateFormat = MyUtil.formatPHPDateForPipe(homeOrg.org_date_format);
    
  }  

  /** 
  * View submission.
  */
  viewSubmission(iteration) {
    this.diagnosticService.getDiagnosticTestSubmission(this.routeData.id, iteration).then((submission)=>{
      this.router.navigate(['/DiagnosticSummaryPage', JSON.stringify({id: this.routeData.id, questionData: submission, fromPrevious: true})]);
    }) 
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
