import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import * as appStore from '../../store';
import { Appapi } from 'src/app/providers/appapi';
import { EventsService } from 'src/app/providers/events-service';
import { Router } from '@angular/router';
import { MyUtil } from 'src/libs/MyUtil';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy{

  pageData: any = {};

  constructor(
    private store: Store<appStore.AppViewState>,
    public appapi: Appapi,
    private router: Router,
    private eventsService: EventsService,
  ) { }
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
  * Get brand name from store.
  */
  brandName$: Observable<string> = this.store.select(getBrandName);

  // Inputs
  @Input() title: string;
  @Input() mobileTitle: string;
  @Input() walkthroughLabel: string;
  @Input() showHelpIcon: boolean;
  @Output() startWalkthrough:EventEmitter<boolean> = new EventEmitter();

  /**
  * Start walkthrough handler.
  */
  startWalkthroughHandler(){
    WalkthroughService.setWalkthroughState(this.walkthroughLabel, false);
    this.startWalkthrough.emit(true);
  }

  ngOnInit() {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.pageData.enableEnnouncements = false;

    let homeOrg = MyUtil.getRootOrganization();
    if(homeOrg.enable_announcements) {
      this.pageData.enableEnnouncements = true;
      this.pageData.newCount = '';
      this.pageData.announcementTitle = "Announcements";

      this.eventsService.getUnreadAnnouncementCount();
      
      /* this.appapi.getUnreadAnnouncementCount().then((data) => {
        if(data && data['unread_announcements'] !== undefined) {
          if(data['unread_announcements'] > 0) {
            this.pageData.newCount = data['unread_announcements'];
            this.pageData.announcementTitle = 'Announcements (' + this.pageData.newCount + ' new)';
          } else {
            this.pageData.newCount = '';
            this.pageData.announcementTitle = "Announcements";
          }
        }
      }); */
    }
    

  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
