import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { CoursesService } from 'src/app/providers/courses-service';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

@Component({
  selector: 'qr-scanner',
  templateUrl: 'qr-scanner.html',
  styleUrls: ['qr-scanner.scss']
})
export class QrScannerComponent implements OnInit {
  pageData: any = {};
  isSupported = false;
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, public navParams: NavParams,
    public coursesService: CoursesService,
    public appapi: Appapi, public activityService: ActivityService,
    private store: Store<appStore.AppViewState>) {}


  ngOnInit() {

    BarcodeScanner.isSupported().then((result) => {
      this.isSupported = result.supported;
    }).catch(() => {
      this.isSupported = false;
    })

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

    // only show add to calendar in mobile app mode
    this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();

    // get profile information
    this.pageData.initialisedProfile = MyUtil.getProfile();

    //Check if user has multiple profiles on this portal
    this.pageData.multiTenant = MyUtil.retrieveFromLocalStorage('multiTenant') == "1";
  }

  
  async scanQrCode() {

    if (!MyUtil.isMobileAppMode()) {
      //Web version
      this.enterAttendanceCode()
      return;
    } else {
      //Mobile
      // check for camera permissions
      let cameraAccess = await this.requestPermissions().then(granted => {
        if(!granted) {
          // ask the user to set the camera permissions
          let alert = MyUtil.presentAlert({
            title: 'Camera Access Required',
            message: "Please enable camera access for the Inkpath app. You should find this in your Settings page.",
            buttons: [
              {
                text: 'Cancel',
              }
            ]
          });

          return false;
        }
        return true;
      });

      if(cameraAccess) {
        // if we are on Android we need to check if the Google Barcode Module is installed
        if(MyUtil.ionic.platform.is("android")) {
          const googleBCModuleAvailable = await this.isGoogleBarcodeScannerModuleAvailable();
          
          if(!googleBCModuleAvailable) {
            // install the module
            await this.installGoogleBarcodeScannerModule();
          }

        }
          
        this.startScan();
      }

    }
  }


  enterAttendanceCode() {

    MyUtil.presentAlert({
      title: 'Enter The Attendance Code',
      message: "Please enter the attendance code of the activity.",
      inputs: [
        {
          name: 'code',
          placeholder: 'Attendance Code'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          handler: (data: any) => {
            MyUtil.debug(data);
            this.matchActivitiesOfAttendanceCode(data.code);
          }
        }
      ]
    });
  }


  private matchActivitiesOfAttendanceCode(code: string) {

    if (code) {
      let loading = MyUtil.presentLoading();
      let data = {
        attendanceCode: code
      };
      
      this.appapi.post('/find/activities-of-attendance-code', data).then(async (result) => {
        (await loading).dismiss();
        MyUtil.debug(result);

        if (result['#status'] === 'success') {
          if (result['#data'] && result['#data'].length > 0) {

            //Refresh from back-end
            let activityId = result['#data'][0];
            await this.appapi.syncSingleActivity(activityId);

            //Fetch data from cache
            let activity = MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES][activityId];
            if(activity) {
              //Course activity?
              if(activity.course_id) {
                //Refresh course from back-end
                await this.appapi.syncSingleCourse(activity.course_id);
                let course = MyUtil.cache[MyUtil.DOC_ID.COURSES][activity.course_id];
                if(!course) {
                  MyUtil.presentToast('Course not found', { cssClass: 'error' });
                  return;
                }

                //Check if both course and activity booked
                let userActivity = MyUtil.cache[MyUtil.DOC_ID.USER_ACTIVITIES][activityId];
                let isActivityBooked = !!userActivity?.is_booked;
                let userCourse = MyUtil.cache[MyUtil.DOC_ID.USER_COURSES][course.id];
                let isCourseBooked = !!userCourse?.is_booked;
                let onCourseList = !!userCourse?.on_waiting_list || !!userCourse?.on_application_list;

                //Check if available for booking
                let currentTimestamp = MyUtil.getUnixTimeStamp();
                let bookingWindowOpen = (currentTimestamp > course.booking_opens_ts && currentTimestamp < course.booking_closes_ts);
                let courseCapacityStatus = null;
                await this.appapi.getCourseCapacityStatus(course.id).then((capacityStatus) => {
                  courseCapacityStatus = capacityStatus;
                });
                let isFull = courseCapacityStatus?.is_full;

                // If user not booked on course at all and course is still bookable
                if(!isActivityBooked && !isCourseBooked && !onCourseList && !isFull && bookingWindowOpen) {
                  // “Please book onto this course, then try registering your session completion again” 
                  // → OK button sends user to course page
                  MyUtil.presentAlert({
                    message: "You are not booked into this session.\n Please book onto this course, then try registering your session completion again.",
                    buttons: [
                      {
                        text: "OK",
                        handler: () => {
                          this.router.navigate(['/CourseDetailPage', JSON.stringify({ id: activity.course_id })]);
                        },
                      },
                    ],
                  });

                  return;

                } else if(!isCourseBooked || !isActivityBooked) {
                  // Booking might be closed or they might be on waiting list, and user not booked on course or not on activity - the admin needs to sort this out!
                  // “Please contact your administrator to amend your booking and register participation // [public contact address]” 
                  // → OK button sends user to course page
                  MyUtil.presentAlert({
                    message: `You are not booked into this session.\n Please contact your administrator to amend your booking and register participation. ${MyUtil.getRootOrganization().public_contact_email}`,
                    buttons: [
                      {
                        text: "OK",
                        handler: () => {
                          this.router.navigate(['/CourseDetailPage', JSON.stringify({ id: activity.course_id })]);
                        },
                      },
                    ],
                  });

                  return;
                } else {
                  //All ok - user is booked on course and session
                  //Send on to the activity detail page - the attendance code will cause it to then redirect to the complete page
                  this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: activity.id, 'attendanceCode': code })]);
                }

              } else {
                //Unwrapped activity
                //Send on to the activity detail page - the attendance code will cause it to then redirect to the complete page
                this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: activity.id, 'attendanceCode': code })]);
              }
            } else {
              //Activity not found
              // if multi-org user
              if(this.pageData.multiTenant) {
                MyUtil.presentAlert({
                  message: 'Failed to match the activity. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one.',
                  buttons: [
                    {
                      text: "OK",
                    }
                  ]
                });
              } else {
                MyUtil.presentAlert({
                  message: 'Activity not found. Please check you have the correct code and try again.',
                  buttons: [
                    {
                      text: "OK",
                    }
                  ]
                });
              }
            }            
          } else {
            MyUtil.presentAlert({
              message: 'Activity not found. Please check you have the correct code and try again.',
              buttons: [
                {
                  text: "OK",
                }
              ]
            });
          }
        }
      }).catch(async err => {
        (await loading).dismiss();
        MyUtil.error(err);
      });
    }
  }



  // Capacitor Scanner
  startScan = async () => {
    const barcode = await BarcodeScanner.scan();
  
    // if the result has content
    if (barcode.barcodes) {
      this.matchActivitiesOfAttendanceCode(barcode.barcodes[0].displayValue);
    }
  };

  async requestPermissions(): Promise<boolean> {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === 'granted' || camera === 'limited';
  }

  isGoogleBarcodeScannerModuleAvailable = async () => {
    const { available } =
      await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
    return available;
  };
  
  installGoogleBarcodeScannerModule = async () => {
    await BarcodeScanner.installGoogleBarcodeScannerModule();
  };

  stopScan = () => {
    BarcodeScanner.stopScan();
  };
}
