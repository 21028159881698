import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Appapi } from "./appapi";
import { MyUtil } from "src/libs/MyUtil";

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(public appapi: Appapi, public httpClient: HttpClient) {}


  public bookPlaceOnCourse(courseId: number, postObj: any):Promise<any> {
    return this.appapi.post("/course/" + courseId + "/book", postObj, postObj)
      .then(async (result) => {
        return result;
      })
      .catch((err) => {
        MyUtil.error(err);
      });
  }


  public convertHybridCourseBooking(courseId: number, postObj: any):Promise<any> {
    let data;
    return this.appapi.post("/course/" + courseId + "/convert", postObj)
      .then(async (result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }
  

  public cancelBooking(courseId: number, labelKey: number):Promise<any>{
    let data = [];
    return this.appapi.post("/course/" + courseId + "/cancel-booking", {cancel_reason: labelKey})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

}
