import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import {
  timeout
} from 'rxjs/operators';
import { MyDb } from '../../libs/MyDb';
import { MyUtil } from '../../libs/MyUtil';
@Injectable({
  providedIn: 'root',
})
export class Appapi {
  static syncFlags: any = {};
  appUser: any;

  constructor(public httpClient: HttpClient,
    private router: Router,
  ) {
    // do something
  };

  initializeAppUser(): Promise<any> {

    return MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(doc => {

      this.appUser = doc;
      if (this.appUser.id) {
        MyDb.initUserDb(this.appUser.id);

        MyUtil.subscribeEvent(MyUtil.EVENT.APP_RESUME, () => {
          //@TODO revise this: try to sync for every wake up if using wifi
          if (MyUtil.isNetworkWifi()) {
            MyUtil.debug('sync for wifi connection');
            let loading = MyUtil.presentLoading();
            this.syncUserAll().then(async () => {
              (await loading).dismiss();
            }).catch(async err => {
              MyUtil.error(err);
              (await loading).dismiss();
            });
          } else {
            MyUtil.debug('ignore sync for not wifi connection');
          }
        });

        // initialize firebase plugin
        MyUtil.firebaseSetUserId(this.appUser.id);
        MyUtil.firebaseSetUserProperty('email', this.appUser.email);

        return MyDb.userDbInfo();
      }
    }).catch(err => {
      this.appUser = { _id: MyUtil.DOC_ID.APP_USER };
    });
  }

  forgetAppUser(destroy: boolean = false): Promise<any> {

    if (!this.appUser) {
      return Promise.resolve('app user not found');
    }

    return MyDb.appRemove(this.appUser).then(() => {
      delete this.appUser;
      this.appUser = { _id: MyUtil.DOC_ID.APP_USER };

      let isForgotten = MyDb.forgetUserDb(destroy);

      this.isLoggedInAndSynchronized();
      return isForgotten;
    });
  }

  isLoggedIn(): boolean {
    return (this.appUser && this.appUser.api_token);
  }

  private isLoggedInAndSynchronizedSubject = new BehaviorSubject<boolean>(false);
  readonly isLoggedInAndSynchronized$ = this.isLoggedInAndSynchronizedSubject.asObservable();

  isLoggedInAndSynchronized(): void {
    if (this.isLoggedIn()) {
      if (MyUtil.cache[MyUtil.DOC_ID.USER_PROFILE]) {
        let profile = MyUtil.getProfile();
        if (profile) {
          if (MyUtil.cache[MyUtil.DOC_ID.ORGANIZATION_TREE]) {
            if (Object.keys(MyUtil.cache[MyUtil.DOC_ID.ORGANIZATION_TREE]).length > 0) {
              if (MyUtil.cache[MyUtil.DOC_ID.ORGANIZATION_TREE][profile.root_oid]) {
                this.isLoggedInAndSynchronizedSubject.next(true);
                return;
              }
            }
          }
        }
      }
    }

    this.isLoggedInAndSynchronizedSubject.next(false);
  }

  isInitialized(): boolean {
    return (this.appUser);
  }

  loadAppHelpStatus(): Promise<any> {
    return MyDb.appLoad(MyUtil.DOC_ID.APP_HELP_STATUS).then(doc => {
      MyUtil.context.helpStatus = doc;
    }).catch(err => {
      MyUtil.context.helpStatus = { _id: MyUtil.DOC_ID.APP_HELP_STATUS };
    });
  }

  setAppHelpStatus(key: string, value: any): Promise<any> {
    MyUtil.context.helpStatus[key] = value;
    return MyDb.appSave(MyUtil.context.helpStatus).then(doc => {
      return doc;
    }).catch(err => {
      MyUtil.error(err);
    });;
  }

  openAppLawBrowser(uri: string): InAppBrowserObject {
    let url = MyUtil.context.API_SERVER_URL + '/law/' + uri;
    let target = '_blank';
    let options = 'location=no;hidden=no;clearcache=yes;clearsessioncache=yes;zoom=no;hardwareback=yes;closebuttoncaption=Close;toolbar=no';
    MyUtil.debug(url);
    return MyUtil.createInAppBrowser(url, target, options);
  }

  openAppHelpBrowser(uri: string): InAppBrowserObject {
    let url = MyUtil.context.API_SERVER_URL + '/app-help?uid=' + this.appUser.id + '&token=' + this.appUser.api_token + '&redirect=' + uri;
    let target = '_blank';
    let options = 'location=no;hidden=no;clearcache=yes;clearsessioncache=yes;zoom=no;hardwareback=yes;closebuttoncaption=Close;toolbar=no';
    MyUtil.debug(url);
    return MyUtil.createInAppBrowser(url, target, options);
  }

  getActivityExport(uri: string, data: any) {
    let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + '/activities-export?uid=' + this.appUser.id + '&token=' + this.appUser.api_token + '&date_from=' + data.date_from + '&date_to=' + data.date_to + '&redirect=' + uri;
    return url;
  }
  getActivityExportPDF(uri: string, data: any) {
    let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + '/usertranscript/pdftranscript?uid=' + this.appUser.id + '&token=' + this.appUser.api_token + '&date_from=' + data.date_from + '&date_to=' + data.date_to + '&redirect=' + uri;
    return url;
  }

  openBrowser(uri: string): InAppBrowserObject {
    let url = uri;
    let target = '_blank';
    let options = 'location=no;hidden=no;clearcache=yes;clearsessioncache=yes;zoom=no;hardwareback=yes;closebuttoncaption=Close;toolbar=no';
    MyUtil.debug(url);
    return MyUtil.createInAppBrowser(url, target, options);
  }

  getEvidenceFileUrl(user_activity_id, file_id) {
    let url = MyUtil.context.API_SERVER_URL + '/' + this.getEvidenceFileUri(user_activity_id, file_id);
    url = url + '?uid=' + this.appUser.id + '&token=' + this.appUser.api_token;
    MyUtil.debug(url);
    return url;
  }

  getEvidenceFileUri(user_activity_id, file_id) {
    let uri = 'managedfile/show-evidence/' + user_activity_id + '/' + file_id;
    return uri;
  }

  get(uri: string, data: any, context?: any) {
    let options = {
      uri: uri,
      method: 'GET',
      data: data
    }
    return this.invokeApi(options, context);
  }

  post(uri: string, data: any, context?: any, appendServerURL?: boolean) {

    if (appendServerURL === undefined) {
      appendServerURL = true;
    }
    let options = {
      uri: uri,
      method: 'POST',
      data: data
    }
    return this.invokeApi(options, context, appendServerURL);
  }

  delete(uri: string, data: any, context?: any) {
    let options = {
      uri: uri,
      method: 'DELETE',
      data: data
    }
    return this.invokeApi(options, context);
  }

  blob(uri: string): Promise<any> {
    let options = {
      uri: uri,
      method: 'GET',
      more: {
        responseType: "blob"
      }
    };
    return this.invokeApi(options);
  }

  invokeApi(options: any, context?: any, appendServerURL?: boolean): Promise<any> {

    if (appendServerURL === undefined) {
      appendServerURL = true;
    }
    return new Promise((resolve, reject) => {
      MyUtil.debug(['invoke app api', options]);
      let url = (appendServerURL) ? MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + options.uri : options.uri;
      //let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + options.uri;
      if (MyUtil.context.DEBUG) {
        url = url + '?XDEBUG_SESSION_START=dbgp';
      }

      //Make sure oid passed in
      if (!options.data.oid) {
        let localOid = MyUtil.retrieveFromLocalStorage('localOid');
        if (localOid) {
          options.data.oid = localOid;
        }
      }

      let headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest', // I am a ajax call
        'Accept': 'application/json', // I wants Json response
      };

      if (this.isLoggedIn()) {
        headers['Authorization'] = 'Bearer ' + this.appUser.api_token;
      }
      if (options.data.rsctoken) {
        headers['Authorization'] = 'Bearer ' + options.data.rsctoken;
      }

      let params = null;
      let observable: Observable<Object> = null;
      switch (options.method) {
        case 'GET':
          params = {
            headers: headers,
            params: options.data
          };

          if (options.more) {
            params = MyUtil.lodash.merge(params, options.more);
          }

          observable = this.httpClient.get(url, params);
          break;
        case 'DELETE':
          params = {
            headers: headers,
            params: options.data
          };

          if (options.more) {
            params = MyUtil.lodash.merge(params, options.more);
          }

          observable = this.httpClient.delete(url, params);
          break;
        case 'POST':
          if (options.data instanceof FormData) {
            delete headers['Content-Type'];
            options.data.append('oid', options.data.oid);
          }

          params = {
            headers: headers
          };

          if (options.more) {
            params = MyUtil.lodash.merge(params, options.more);
          }

          observable = this.httpClient.post(url, options.data, params);
          break;
      }

      observable.pipe(timeout(MyUtil.context.API_TIMEOUT))
        .subscribe(result => {
          MyUtil.debug(['invoke app api result', result]);

          //Server error handling
          if (result['#status'] == 'error') {
            //Something went wrong on the server - display generic message to user with Helpdesk reporting info
            let errorMessage = 'Unexpected error.';
            let logCode = '';
            if (result['#data'] && result['#data']['logcode']) {
              logCode = result['#data']['logcode'];
            }
            MyUtil.showErrorAlert(errorMessage, true, logCode);

            reject(result['#message']);
          } else {
            resolve(result);
          }
        }, err => {
          MyUtil.error(['invoke app api err', err]);

          // handle error in general, e.g. 401 Unauthorized
          if (err && err.status === 401) {
            let args = {
              'handleUnauthorized': (context ? context.handleUnauthorized : null)
            };

            // If Unauthorised forward to login screen
            this.forgetAppUser(true).then(() => {
              this.router.navigate(['/reload-login']);
            });
            MyUtil.publishEvent(MyUtil.EVENT.APP_UNAUTHORIZED, args);

            /* } else if (err && err.status === 413) {
              //Content too large
              //MyUtil.publishEvent(this.const.EVENT.TOO_LARGE, {});
            } else {
              let args = {
                'handleServerUnavailable': (context ? context.handleServerUnavailable : null)
              };
              //MyUtil.publishEvent(this.const.EVENT.SERVER_UNAVAILABLE, args);
            } */
          } else {
            //Something else went wrong with the request - display generic message to user with Helpdesk reporting info
            let errorMessage = 'Unexpected error.';
            let logCode = '';
            if (err['error'] && err['error']['#data'] && err['error']['#data']['logcode']) {
              logCode = err['error']['#data']['logcode'];
            }
            MyUtil.showErrorAlert(errorMessage, true, logCode);
          }

          reject(err);
        });
    });
  }

  //Just sync the app settings (to check for critical updates)
  syncApp(): Promise<any> {
    return this.syncAppComponent(MyUtil.DOC_ID.APP_SETTINGS, '/sync/app', {}, this.processMergeAndCache);
  }

  //Just sync the universities settings (for the login page)
  syncUniversities(): Promise<any> {
    return this.syncAppComponent(MyUtil.DOC_ID.APP_UNIVERSITIES, '/sync/universities', {}, this.processMergeAndCache);
  }

  /* sync app all from server */
  syncAppAll(): Promise<any> {
    return Promise.all([
      this.syncAppComponent(MyUtil.DOC_ID.APP_SETTINGS, '/sync/app', {}, this.processMergeAndCache),
      this.syncAppComponent(MyUtil.DOC_ID.APP_META, '/sync/meta', {}, this.processRefreshAndCache),
      this.syncAppComponent(MyUtil.DOC_ID.APP_PAGES, '/sync/pages', {}, this.processMergeAndCache),
      this.loadAppHelpStatus(),
    ]);
  }

  /* sync user all from server */
  syncUserAll(): Promise<any> {
    return Promise.all([
      this.syncUserProfile(),
      this.syncOrganization(),
      this.syncUserComponent(MyUtil.DOC_ID.SKILLS, '/sync/skills', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.PROGRAMS, '/sync/programs', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.PHASES, '/sync/phases', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
    ]).then(() => {
      this.isLoggedInAndSynchronized();
    });
  }

  syncOrganization(): Promise<any> {
    return this.syncUserComponent(MyUtil.DOC_ID.ORGANIZATION_TREE, '/sync/organizations', {}, this.prepareDeltaUpdate, this.processUserOrganizations);
  }


  syncAllActivities(): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.ACTIVITIES, '/sync/activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.USER_ACTIVITIES, '/sync/user-activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
    ]);
  }

  syncUserActivities(): Promise<any> {
    return this.syncUserComponent(MyUtil.DOC_ID.USER_ACTIVITIES, '/sync/user-activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache);
  }

  syncSingleActivity(activityId: number): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.ACTIVITIES, '/sync-one/activity/' + activityId, {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.USER_ACTIVITIES, '/sync-one/user-activity/' + activityId, {}, this.prepareDeltaUpdate, this.processMergeAndCache),
    ]);
  }

  syncSingleCourse(courseId: number): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.COURSES, '/sync-one/course/' + courseId, {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.USER_COURSES, '/sync-one/user-course/' + courseId, {}, this.prepareDeltaUpdate, this.processMergeAndCache),
    ]);
  }

  syncAllActivitiesAndCourses(): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.ACTIVITIES, '/sync/activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.USER_ACTIVITIES, '/sync/user-activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.COURSES, '/sync/courses', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.USER_COURSES, '/sync/user-courses', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
    ]);
  }

  syncAllGoals(): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.GOALS, '/sync/goals', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserGoals(),
    ]);
  }

  syncGoalsAndActivities(): Promise<any> {
    return Promise.all([
      this.syncAllGoals(),
      this.syncAllActivities()
    ]);
  }

  syncAppComponent(docId: string, uri: string, defaultData: any, processResult: any) {
    return this.syncComponent(docId, uri, defaultData, MyDb.appLoadWithDefault, null, processResult, MyDb.appSave, false);
  }

  syncUserComponent(docId: string, uri: string, defaultData: any, processUpdate: any, processResult: any) {
    return this.syncComponent(docId, uri, defaultData, MyDb.userLoadWithDefault, processUpdate, processResult, MyDb.userSave, true);
  }

  syncUserProfile() {
    return this.syncUserComponent(MyUtil.DOC_ID.USER_PROFILE, '/sync/profile', {}, (doc) => { return doc.data; }, this.processRefreshAndCache);
  }

  freshSyncUserProfile() {
    return this.syncUserComponent(MyUtil.DOC_ID.USER_PROFILE, '/sync/profile?refresh=true', {}, (doc) => { return doc.data; }, this.processRefreshAndCache);
  }

  syncUserGoals() {
    return this.queryUserGoals().then(queryResult => {
      let keys = (queryResult.length > 0 ? MyUtil.lodash.chain(queryResult).map('id').value() : []);
      let fields = (queryResult.length > 0 ? MyUtil.lodash.keys(queryResult[0]) : []);

      return this.syncUserComponent('user_personal_goals_no_cache', '/sync/user-goals', {}, () => {
        return {
          keys: keys,
          fields: fields
        };
      }, this.processUserGoals);
    });
  }




  // general sync function for app or user
  private syncComponent(docId: string, uri: string, defaultData: any, processLoad: any, processUpdate: any, processResult: any, processSave: any, needLoggedIn: boolean = true) {
    return this.allowSync(docId, needLoggedIn).then(() => {
      Appapi.syncFlags[docId] = true;

      // wrapper defaultData into default Doc
      let defaultDoc = {
        data: (defaultData ? defaultData : {})
      };
      return processLoad(docId, defaultDoc).then(doc => {
        // put to cache
        //@TODO determine those won't cached
        MyUtil.cache[doc._id] = doc.data;

        let data = {
          ts: doc.ts,
          data: null
        };

        // if need update server, prepare data to send here
        if (typeof (processUpdate) === 'function') {
          data.data = processUpdate(doc);
        }

        //@TODO check connection availability
        return this.post(uri, data).then(result => {
          //@TODO handle other status, e.g. updated, error, etc
          if (result['#status'] === 'success' && !MyUtil.lodash.isEmpty(result['#data'])) {
            // process doc and result['#data'], e.g. convert, update the cache
            processResult(doc, result['#data']);

            // write the update back
            return processSave(doc).then(() => {
              Appapi.syncFlags[docId] = false;
            }).catch(err => {
              // do something for save error
              Appapi.syncFlags[docId] = false;
            });
          } else { // updated, saved, etc.
            MyUtil.debug('no update for ' + docId);
            processResult(doc, null); // for cache purpose
            Appapi.syncFlags[docId] = false;
          }
        }).catch(err => {
          // do something for post error
          processResult(doc, null); // for cache purpose
          Appapi.syncFlags[docId] = false;
          throw err; // enable next level to process the error, e.g. unauthorize
        });
      }).catch(err => {
        // do something for load error
        Appapi.syncFlags[docId] = false;
        throw err; // enable next level to process the error, e.g. unauthorize
      });
    }).catch(err => {
      // prevent proceed for unauthorized but hide other errors
      if (err && err.status === 401) {
        throw err;
      }
    });
  }

  // check sync flag, login status, connection status, etc for sync
  // remember to set and remove flag in each sync
  private allowSync(docId: string, needLoggedIn: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Appapi.syncFlags[docId]) {
        let message = 'ignore sync ' + docId;
        MyUtil.debug(message);
        reject(message);
      } else {
        if (needLoggedIn) {
          if (this.isLoggedIn()) {
            let message = 'allow user sync ' + docId;
            MyUtil.debug(message);
            resolve(message);
          } else {
            let message = 'ignore user sync ' + docId;
            MyUtil.debug(message);
            reject(message);
          }
        } else {
          let message = 'allow sync ' + docId;
          MyUtil.debug(message);
          resolve(message);
        }
      }
    });
  }

  processMergeAndCache(existing: any, updated: any): void {
    if (updated && updated.ts && updated.data) {
      existing.ts = updated.ts;
      if (!existing.data) {
        // init data
        existing.data = {};
      }

      // use assign to avoid recursive merge which keep the deleted sub items
      MyUtil.lodash.assign(existing.data, updated.data);
    }

    if (existing && existing.data && updated) {
      // clean data
      if (!MyUtil.lodash.isEmpty(updated.delete)) {
        MyUtil.lodash.forEach(updated.delete, (key) => {
          delete (existing.data[key]);
        });
      }
    }

    MyUtil.cache[existing._id] = existing.data;
  }

  processRefreshAndCache(existing: any, updated: any): void {
    if (updated && updated.ts && updated.data) {
      existing.ts = updated.ts;
      existing.data = updated.data;
    }

    MyUtil.cache[existing._id] = existing.data;
  }

  prepareDeltaUpdate(doc) {
    let data = { keys: [], fields: [] };

    // prepare keys to detect deleted
    data.keys = MyUtil.lodash.keys(doc.data);

    // prepare fields to detect fields add or remove
    if (!MyUtil.lodash.isEmpty(doc.data)) {
      data.fields = MyUtil.lodash.keys(MyUtil.lodash.values(doc.data)[0]);
    }
    return data;
  }

  processUserOrganizations(existing: any, updated: any): void {
    if (updated && updated.ts && updated.data) {
      existing.ts = updated.ts;
      if (!existing.data) {
        // init data
        existing.data = {};
      }

      // use assign to avoid recursive merge which keep the deleted sub items
      MyUtil.lodash.assign(existing.data, updated.data);
    }

    if (existing && existing.data && updated) {
      // clean data
      if (!MyUtil.lodash.isEmpty(updated.delete)) {
        MyUtil.lodash.forEach(updated.delete, (key) => {
          delete (existing.data[key]);
        });
      }
    }

    // cache
    MyUtil.cache[existing._id] = MyUtil.lodash.cloneDeep(existing.data);
    // build sub-tree
    MyUtil.lodash.forEach(MyUtil.cache[existing._id], (obj) => {
      if (obj.oid) {
        if (MyUtil.cache[existing._id][obj.oid]) {
          if (!MyUtil.cache[existing._id][obj.oid].children) {
            MyUtil.cache[existing._id][obj.oid].children = {};
          }
          MyUtil.cache[existing._id][obj.oid].children[obj.id] = obj;
        }
      }
    });

    if (!MyUtil.lodash.isEmpty(MyUtil.cache[existing._id])) {
      MyUtil.lodash.forEach(MyUtil.cache[existing._id], (org) => {
        let tempOrg = org;
        let level = 0;
        while (tempOrg.oid != null) {
          tempOrg = MyUtil.cache[existing._id][tempOrg.oid];
          level = level + 1;
        }
        org.level = level;
      });
    }
  }

  private processUserGoals(existing: any, updated: any): void {
    if (updated) {
      // save app missing user goals
      MyUtil.lodash.forEach(updated.data, (data, id) => {
        if (MyUtil.lodash.isObject(data) && data.id && data.ts) {
          // process data ids
          data.id = parseInt(data.id);
          data.goal_id = parseInt(data.goal_id);
          data.status = parseInt(data.status);
          data.started_at = (data.started_at ? parseInt(data.started_at) : null);
          data.completed_at = (data.completed_at ? parseInt(data.completed_at) : null);
          let ts = parseInt(data.ts);
          delete (data.ts);

          let doc = {
            _id: data.app_id,
            _rev: data.app_rev,
            ts: ts,
            type: MyUtil.DOC_TYPE.USER_GOAL,
            data: data
          };
          MyDb.userSave(doc);
        }
      });

      // clean server not existing user goals
      MyUtil.lodash.forEach(updated.delete, (id) => {
        let queryOptions: any = {
          key: [MyUtil.DOC_TYPE.USER_GOAL, id],
          include_docs: true
        };

        MyDb.userQuery('by_type_id', queryOptions).then(queryResult => {
          let docs = MyDb.flatQueryResult(queryResult);

          if (docs && docs.length > 0) {
            MyUtil.lodash.forEach(docs, (doc) => {
              if (doc._id) {
                MyDb.userRemove(doc);
              }
            });
          }
        });
      });
    }

    // remove memo cache if any
    delete (MyUtil.cache[existing._id]);
  }


  /**
   * query all user goals excluding deleted
   */
  queryUserGoals(): any {
    return this.queryUserDocByType(MyUtil.DOC_TYPE.USER_GOAL);
  }

  /**
   * query all user doc by type excluding deleted
   */
  private queryUserDocByType(type: string): any {
    let queryOptions: any = {
      key: type,
      include_docs: true
    };

    return MyDb.userQuery('by_type', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);
      let result = [];

      if (docs && docs.length > 0) {
        result = MyUtil.lodash.chain(docs).filter((item: any) => {
          return item && (!item.delete);
        }).map('data').value();
      }

      return result;
    });
  }


  /**
   * make usre user activities saved firstly !!!
   * save all user goals to server and
   * update the user db for deleted or ts, id
   */
  saveUserGoals() {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.USER_GOAL, null],
      include_docs: true
    };

    // get all not synchronized user goals, ts is null or undefined
    return MyDb.userQuery('by_type_ts', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);

      if (docs.length == 0) {
        //If no data then nothing to do
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      } else {
        return this.post('/save/user-goals', { data: docs }).then((result) => {

          return new Promise((resolve, reject) => {

            if (result['#status'] === 'success') {

              let allTasks: Array<any> = [];

              // updated user generated goals
              if (!MyUtil.lodash.isEmpty(result['#goals'])) {
                allTasks.push(this.updateUserGeneratedGoals(result['#goals']));
              }

              // update saved ts and data.id
              MyUtil.lodash.forEach(result['#data'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data.id && data.ts) {
                  allTasks.push(this.updateUserDocTsAndMore(data, docId));
                }
              });

              // clean deleted
              MyUtil.lodash.forEach(result['#delete'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data._rev) {
                  allTasks.push(this.removeUserDoc(data, docId));
                }
              });

              // make sure all tasks finished
              Promise.all(allTasks).then(() => {
                resolve(allTasks);
              }).catch(err => {
                MyUtil.error(err);
                reject(err);
              });
            } else {
              MyUtil.error(result['#message']);
              reject(result['#message']);
            }
          });
        }).catch(err => {
          MyUtil.error(err);
        });
      }
    });
  }



  private updateUserGeneratedGoals(goalIdMappings: any): any {
    return MyDb.userLoad(MyUtil.DOC_ID.GOALS).then((goalsDoc: any) => {
      MyUtil.lodash.forEach(goalIdMappings, (goalIdMapping, uuid) => {
        // replace uuid with server side id
        if (goalsDoc.data[uuid]) {
          let goal = goalsDoc.data[uuid];
          goal.id = goalIdMapping.id;
          delete goalsDoc.data[uuid];
          goalsDoc.data[goal.id] = goal;
        }
      });

      return MyDb.userSave(goalsDoc).then((goalsDoc: any) => {
        // update cache
        MyUtil.cache[goalsDoc._id] = goalsDoc.data;
      });
    });
  }



  /**
   * Update user doc according to server return
   * UserActivity, UserGoal, ActivityNote
   */
  private updateUserDocTsAndMore(data: any, docId: any): any {
    let doc: any = {
      _id: docId,
      data: {}
    };

    // set ts from server
    if (data.ts) {
      doc.ts = parseInt(data.ts);
    }

    // set id from server
    if (data.id) {
      doc.data.id = parseInt(data.id);
    }

    // set updated_at from server
    if (data.updated_at) {
      doc.data.updated_at = parseInt(data.updated_at);
      doc.ts = data.updated_at;
    }

    // replace uuid with server side activity id
    if (data.activity_id) {
      doc.data.activity_id = parseInt(data.activity_id);
      if (doc.data.updated_activity) {
        doc.data.updated_activity = null;
      }
    }

    // replace uuid with server side goal id
    if (data.goal_id) {
      doc.data.goal_id = parseInt(data.goal_id);
      if (doc.data.updated_goal) {
        doc.data.updated_goal = null;
      }
    }

    // set attend status if any
    if (data.attend_status) {
      doc.data.attend_status = parseInt(data.attend_status);
    }

    // merge ts and data.id
    return MyDb.userMerge(doc);
  }

  /**
   * Remove user doc according to server return
   * UserActivity, UserGoal, ActivityNote
   */
  private removeUserDoc(data: any, docId: any): any {
    let doc = {
      _id: docId,
      _rev: data._rev
    };

    // remove rev
    return MyDb.userRemove(doc);
  }

  getUserBadges() {
    return this.get('/sync-badges', {});
  }

  getQuestionnaire() {
    return this.get('/questionnaire', {});
  }

  restartQuestionnaire(oid: number) {
    return this.post('/questionnaire/restart', { oid: oid });
  }

  saveQuestion(question) {
    return this.post('/question', {
      id: question.id,
      skill_level: question.skill_level,
      interest: question.interest,
      comments: question.comments,
    });
  }

  getMostRecentQuestionnaireResults() {
    return this.get('/questionnaire-results', {});
  }

  requestQuestionnaireResultsEmail() {
    return this.get('/questionnaire-results/request-email', {});
  }

  getCareerPathsByType() {
    return this.get('/careers/by-type', {});
  }

  getCareerPathsMatchingRequirements() {
    return this.get('/careers/matching-requirements', {});
  }

  getSavedCareerPaths() {
    return this.get('/careers/pdp/list', {});
  }

  saveCareerToPDP(careerPathId) {
    return this.post('/careers/pdp/add/' + careerPathId, {});
  }

  removeCareerFromPDP(careerPathId) {
    return this.post('/careers/pdp/remove/' + careerPathId, {});
  }

  setActiveCareer(careerPathId) {
    return this.post('/careers/pdp/set-active/' + careerPathId, {});
  }

  setQuestionnaireSkippedFlag() {
    return this.post('/questionnaire-set-skipped', {});
  }

  deactivateCareer(careerPathId) {
    return this.post('/careers/pdp/deactivate/' + careerPathId, {});
  }

  getBrandedSlides(pattern) {

    return this.get('/branding/slides?pattern=' + pattern, {});

  }

  getBrandedFavicon(pattern) {

    return this.get('/branding/favicon?pattern=' + pattern, {});

  }

  getRecommendedActivities() {
    return this.get('/find/recommended-activities', {});
  }

  getRecommendedGoals() {
    return this.get('/sync/recommended-goals', {});
  }

  getAlternativeProfilesData(branding: string): Promise<any> {

    let profile = MyUtil.getProfile();
    if (!branding) {
      branding = 'inkpath';
    }

    return MyDb.userLoad(MyUtil.DOC_ID.USER_PROFILE).then((doc: any) => {
      return this.get('/profile/' + profile.id + '/alt-list/' + branding, {}).then((result) => {
        return result["#data"]['alternativeProfilesData'];
      })
        .catch((err) => {
          MyUtil.error(err);
          return [];
        });
    }).catch((err) => {
      MyUtil.error(err);
      return [];
    });
  }

  /**
  * Set the reflection reminder sent flag
  */
  processReflectionReminderSeenFlag() {
    return this.post('/onboarding/reflection/reminder/seen', {});
  }

  //Refresh profile data if profile changed since the last profile sync (ie. editied by the admin)
  checkAndRefreshProfile(): Promise<any> {
    return this.hasProfileChanged().then(changed => {
      if (changed) {
        return this.syncUserProfile();
      }
    });
  }

  //Check if the profile has changed 
  hasProfileChanged(): Promise<any> {
    let profile = MyUtil.getProfile();
    return MyDb.userLoad(MyUtil.DOC_ID.USER_PROFILE).then((doc: any) => {
      let ts = doc.ts;
      let url = '/profile/' + profile.id + '/changed';
      return this.post(url, { ts: ts }).then((result) => {
        return result["#data"]['changed'] == '1';
      }).catch((err) => {
        MyUtil.error(err);
        return false;
      });
    }).catch((err) => {
      MyUtil.error(err);
      return false;
    });
  }


  deleteUserRequest(): Promise<any> {
    let profile = MyUtil.getProfile();
    let url = '/profile/' + profile.id + '/delete-request';

    return this.post(url, {}).then((result) => {
      return result["#message"];
    });
  }

  getRecurringGroup(groupId: number, eventId: number): Promise<any> {
    return this.get('/recurring-group/' + groupId + '/' + eventId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getUnreadAnnouncementCount(): Promise<any> {
    return this.get('/announcement/get-unread-count', {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getAnnouncementList(): Promise<any> {
    return this.get('/announcement/get-list', {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  markAnnouncementAsRead(announcementId: number): Promise<any> {
    return this.post('/announcement/read', { announcementId: announcementId }).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  addToMyActivities(activityId: number): Promise<any> {
    return this.post('/add/user-activity', { activity_id: activityId }).then(result => {
      return result;
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  removeFromMyActivities(activityId: number): Promise<any> {
    //NB. can't use HTTP "delete" method because server rejects it
    return this.post('/remove/user-activity', { activity_id: activityId }).then(result => {
      return result;
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getActivityNote(activityId: number): Promise<any> {
    return this.get('/activity/note/' + activityId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  saveActivityNote(activityId: number, noteText: string) {
    return this.post('/save/activity-note', { activity_id: activityId, text: noteText }).then(result => {
      return result;
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getBookingOptions(activityId: number): Promise<any> {
    return this.get('/booking/options/' + activityId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getBookingCapacityStatus(activityId: number): Promise<any> {
    return this.get('/booking/capacity-status/' + activityId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getCourseCapacityStatus(courseId: number): Promise<any> {
    return this.get('/course/capacity-status/' + courseId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  saveUserGeneratedActivity(data: any): Promise<any> {
    //NB. can't use Http "delete" method because server rejects it
    return this.post('/save/ugc-activity', data).then(result => {
      let savedActivityId = 0;
      if (result && result['#status'] == 'success' && result['#data']) {
        savedActivityId = Number(result['#data']);
      }
      return savedActivityId;
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  deleteUserGeneratedActivity(activityId: number): Promise<any> {
    //NB. can't use Http "delete" method because server rejects it
    return this.post('/delete/ugc-activity', { activity_id: activityId }).then(result => {
      return result;
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  markActivityAsComplete(data: any) {
    return this.post('/activity/complete', data).then(result => {
      return result;
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getCourseBookingOptions(courseId: number): Promise<any> {
    return this.get('/course/options/' + courseId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getActivityTemplates(): Promise<any> {
    return this.get('/activity/templates', {}).then(result => {
      if (result && result["#data"] && result["#data"]['data']) {
        return result["#data"]['data'];
      } else {
        return [];
      }
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  takedownPre2024RefactorCache() {
    //Check if there's anything in the USER_ORGANIZATIONS_OLD cache. If there is, clear all the data from the old cache library
    let organizations = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS_OLD];
    if (organizations && organizations?.length > 0) {

      let docsToRemove = [
        MyUtil.DOC_ID.USER_ORGANIZATIONS_OLD,
        MyUtil.DOC_ID.USER_SKILLS_OLD,
        MyUtil.DOC_ID.USER_PROGRAMS_OLD,
        MyUtil.DOC_ID.USER_PHASES_OLD,
        MyUtil.DOC_ID.ACTIVITIES_OLD,
        MyUtil.DOC_ID.ACTIVITY_TEMPLATES_OLD,
        MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS_OLD,
        MyUtil.DOC_ID.USER_FUNDING_SKILLS_OLD,
        MyUtil.DOC_ID.USER_FUNDING_PROGRAMS_OLD,
        MyUtil.DOC_ID.USER_FUNDING_PHASES_OLD,
        MyUtil.DOC_ID.FUNDING_ACTIVITIES_OLD,
        MyUtil.DOC_ID.USER_FUNDING_GOALS_OLD,
        MyUtil.DOC_ID.ALL_FUNDING_ORGANIZATIONS_OLD,
        MyUtil.DOC_ID.ALL_FUNDING_PROGRAMS_OLD,
        MyUtil.DOC_ID.ALL_FUNDING_PHASES_OLD,
        MyUtil.DOC_ID.REPEATING_GROUPS_OLD
      ]

      docsToRemove.forEach(async docId => {
        await MyDb.userLoad(docId).then(async (doc: any) => {
          if (MyUtil.cache[docId] !== undefined) {
            MyUtil.cache[docId] = null;
          }

          await MyDb.userRemove(doc);
        }).catch(() => {
          if (MyUtil.cache[docId] !== undefined) {
            MyUtil.cache[docId] = null;
          }
        });
      })
    }
  }

}
