import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { NgxCopilotService } from 'ngx-copilot';
import { BehaviorSubject, combineLatest, Observable, scheduled, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import * as appStore from '../../store';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { OrganisationVO } from 'src/app/valueObjects/core.vo';
import { ContentListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from '../../../libs/MyUtil';
import { Store } from '@ngrx/store';
import { getActivityListFilterState, getBrandName } from 'src/app/store/selectors/view.selector';
import { FindActivitiesFilters } from 'src/app/store/reducers/view.reducer';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { FindActivitiesModalComponent, findActivitiesTabStateVO } from 'src/app/components/find-activities-modal/find-activities-modal.component';
import { DateAdapter, MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'page-activity-list',
  templateUrl: 'activity-list.html',
  styleUrls: ['activity-list.scss']
})

export class ActivityListPage implements OnInit, OnDestroy {

  constructor(public router: Router,
    private route: ActivatedRoute,
    private copilot: NgxCopilotService,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public zone: NgZone,
    public uiux: UiuxService,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public coursesService: CoursesService,
    private store: Store<appStore.AppViewState>,
    public courseService: CoursesService,
    private titleService: Title,
    private dateAdapter: DateAdapter<Date>) 
  {
    this.titleService.setTitle("Find Activities");
  }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  menuOpened() {
    setTimeout(() => {
      document.getElementById('organisations').focus();
    }, 0);
  }

  // Component variables.
  component = ListItemComponent;
  @ViewChild('Content', { static: true }) content;
  routeData: any = {};
  pageLabel = "ActivityListPage";
  displayHelpIcon = true;
  isMobileView = this.uiux.isMobileView();
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);;
  resultsCount$: BehaviorSubject<number> = new BehaviorSubject(-1);
  activityFilterState$: Observable<FindActivitiesFilters> = this.store.select(getActivityListFilterState);
  tabSelection$: BehaviorSubject<string> = new BehaviorSubject('scheduled');
  scheduledContent$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);
  selfStudyContent$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);

  filterForm = this.formBuilder.group({
    searchTerm: '',
    organisations: [],
    attendanceType: [],
    skills: [],
    start: '',
    end: '',
  });

  organisations = [];
  attendanceTypes = MyUtil.getAttendanceTypeChain();
  profile = null;
  programIds = [];
  phaseIds = [];
  skills = [];
  isDirtyForm = false;
  findActivitiesFilters: FindActivitiesFilters;
  scheduledActivitiesCount = 0;
  selfStudyActivitiesCount = 0;
  findActivitiesTabState:findActivitiesTabStateVO = JSON.parse(MyUtil.retrieveFromLocalStorage('findActivitiesTabState'));
  modalShown = false;
  homeOrg: OrganisationVO;
  orgFilterCount = 'all';
  attendanceFilterCount = 'all';
  skillsFilterCount = 'all';

 

  /**
   * Filters the scheduled event list
   */
  filteredScheduledContentList$: Observable<ContentListItemVO[]> = combineLatest([
    this.scheduledContent$,
    this.filterForm.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200)),
  ]).pipe(takeUntil(this.destroy$), map(([scheduledContent, formValues]) => {

    const start = new Date(formValues.start).getTime() / 1000;
    const stillUtc = moment.utc(formValues.end).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD');
    const end = new Date(local).getTime() / 1000;

    if (start > end) {
      MyUtil.presentToast('Date range invalid. End date must be greater than start date.', { cssClass: 'inkpath-toast' });
      return;
    }

    this.resultsCount$.next(-1);

    // Limit to 50 days
    let dateLimit = new Date();
    let days = 50;
    dateLimit.setDate(dateLimit.getDate() - days);
    let dateLimitTimestamp = dateLimit.getTime();
    if (Math.round(dateLimitTimestamp / 1000) > start) {
      MyUtil.presentToast('Historical activities are limited to 50 days unless they already exist in your activities list and goals.', { cssClass: 'inkpath-toast' });
    }

    let filteredContentList: ContentListItemVO[] = scheduledContent.filter(scheduledEvent => {

      // Filter by: selected attendance types 
      if (formValues.attendanceType) {
        // Stop from filtering out courses, as courses do not have an attendance type.
        if (!scheduledEvent.is_course) {
          if (formValues.attendanceType.indexOf(`${scheduledEvent.attendance_type_id}`) === -1) {
            return false;
          }
        }
      }

      // Filter by: date range.
      //SEARCH START <= ACTIVITY END <= SEARCH END 
      if (!((start <= scheduledEvent.end_ts && scheduledEvent.end_ts <= end) || (start <= scheduledEvent.start_ts && scheduledEvent.start_ts <= end))) {
        return false;
      }

      // Filter by: search term
      if (formValues.searchTerm && formValues.searchTerm !== null) {
        if ((scheduledEvent.name?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1 || scheduledEvent.name?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === undefined)
        && (scheduledEvent.description?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1 || scheduledEvent.description?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === undefined)
      ) {
          return false;
        }
      }

      // Filter by: selected organisations
      if (formValues.organisations && formValues.organisations?.length > 0 && this.branding != 'rsc') {
        if (formValues.organisations.indexOf(scheduledEvent.oid) === -1) {
          return false;
        }
      } else if (formValues.organisations?.length === 0) {
        return false;
      }

      // Filter by: selected skills
      if (formValues.skills && formValues.skills?.length > 0) {
        if (scheduledEvent.skills) {
          if (formValues.skills.filter((skillId) => {
            return (scheduledEvent.skills.indexOf(parseInt(skillId)) !== -1);
          }).length === 0) {
            return false;
          };
        }
      } else {
        if (scheduledEvent.skills) {
          return false;
        }
      }

      return true;
    });
    
    this.scheduledActivitiesCount = filteredContentList?.length ? filteredContentList.length : 0;

    // Sort by date
    return filteredContentList.sort((a: any, b: any) => a.start_ts - b.start_ts)
  }));


  /**
   * Filters the self-study list
   */
  filteredSelfStudyContentList$: Observable<ContentListItemVO[]> = combineLatest([
    this.selfStudyContent$,
    this.filterForm.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200)),
  ]).pipe(takeUntil(this.destroy$), map(([selfStudyContent, formValues]) => {

    const start = new Date(formValues.start).getTime() / 1000;
    const stillUtc = moment.utc(formValues.end).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD');
    const end = new Date(local).getTime() / 1000;

    if (start > end) {
      MyUtil.presentToast('Date range invalid. End date must be greater than start date.', { cssClass: 'inkpath-toast' });
      return;
    }

    this.resultsCount$.next(-1);

    // Limit to 50 days
    let dateLimit = new Date();
    let days = 50;
    dateLimit.setDate(dateLimit.getDate() - days);
    let dateLimitTimestamp = dateLimit.getTime();
    if (Math.round(dateLimitTimestamp / 1000) > start) {
      MyUtil.presentToast('Historical activities are limited to 50 days unless they already exist in your activities list and goals.', { cssClass: 'inkpath-toast' });
    }

    let filteredContentList: ContentListItemVO[] = selfStudyContent.filter(selfStudyEvent => {

      // Filter by: search term
      if (formValues.searchTerm && formValues.searchTerm != null) {
        if ((selfStudyEvent.name?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1 || selfStudyEvent.name?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === undefined)
        && (selfStudyEvent.description?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1 || selfStudyEvent.description?.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === undefined)
      ) {
          return false;
        }
      }

      // Filter by: selected organisations
      if (formValues.organisations && formValues.organisations?.length > 0 && this.branding !== 'rsc') {
        if (formValues.organisations.indexOf(selfStudyEvent.oid) === -1) {
          return false;
        }
      } else if (formValues.organisations?.length === 0) {
        return false;
      }

      // Filter by: selected skills
      if (formValues.skills && formValues.skills.length > 0) {
        if (selfStudyEvent.skills) {
          if (formValues.skills.filter((skillId) => {
            return (selfStudyEvent.skills.indexOf(parseInt(skillId)) !== -1);
          }).length === 0) {
            return false;
          };
        }
      } else {
        if (selfStudyEvent.skills) {
          return false;
        }
      }

      return true;
    });
    
    this.selfStudyActivitiesCount = filteredContentList?.length ? filteredContentList.length : 0;

    // Sort by date
    return filteredContentList.sort((a: any, b: any) => a.start_ts - b.start_ts)
  }));


  /**
   * Returns the content for the selected tab
   */
  contentListItems$: Observable<ContentListItemVO[]> = combineLatest([
    this.filteredScheduledContentList$,
    this.filteredSelfStudyContentList$,
    this.tabSelection$
  ]).pipe(takeUntil(this.destroy$), map(([scheduledContent, selfStudyContent, tabSelection]) => {

    let selectedTabContent: ContentListItemVO[] = [];
    if (tabSelection === "self-study") {
      selectedTabContent = selfStudyContent;
    } else {
      selectedTabContent = scheduledContent;
    }

    if(selfStudyContent?.length > 0 && scheduledContent?.length > 0) {
      if(!this.modalShown) {
        this.tabSelectModalHandler(tabSelection);
        this.modalShown = true;
      }
    }

    this.setFormDirtyState();

    let selectedTabContentLength = selectedTabContent?.length ? selectedTabContent.length : 0
    this.resultsCount$.next(selectedTabContentLength);
    return selectedTabContent;
  }));



  // On init.
  async ngOnInit() {

    this.loading$.next(true);

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

    // Set route data.
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      //May have route data if coming from the Goals or Skills pages
      this.routeData = JSON.parse(params.pageData);
    });

    if(this.findActivitiesTabState){
      this.setSelectedTab(this.findActivitiesTabState.tab);
    }

    this.activityFilterState$.pipe(takeUntil(this.destroy$)).subscribe((findActivitiesFilters: FindActivitiesFilters) => {
      this.findActivitiesFilters = findActivitiesFilters;
    });

    // need to remove the self-study option
    this.attendanceTypes = this.attendanceTypes.filter(item => {
      if(item.key != 3) {
        return item;
      }
    });

    // Sync all user data to get any updates to organisation, profile, programs, phases or skills that might affect search results
    this.appapi.syncUserAll().then(async () => {

      //Refresh profile and org data
      this.profile = MyUtil.getProfile();
      this.homeOrg = MyUtil.getRootOrganization();
      let dateFormat = MyUtil.formatPHPDate(this.homeOrg.org_date_format);
      if(dateFormat == 'mm/dd/yyyy') {
        this.dateAdapter.setLocale('en-US');
      }

      //Get organisations for the filter form
      let homeOrganisations = MyUtil.getOrgnizationChain();
      let sharingOrganizations = MyUtil.getContentSharingOrganizations()
      this.organisations = homeOrganisations.concat(sharingOrganizations);

      // Set the skills array ready for populating the lists.
      this.setSkillsList();

      // Set Form Default Values.
      this.setFormValues();
      
      //Sync activities and courses.
      this.appapi.syncAllActivitiesAndCourses().then(async () => {

        //Do initial filter
        //Split into scheduled and self-study
        let selfStudyContent: ContentListItemVO[] = [];
        let scheduledContent: ContentListItemVO[] = [];

        //Get list of all activities that could cause clashing
        let clashableActivities = MyUtil.getClashableActivities();

        //Filter activities on values unaffected by user's filters
        let allActivities = MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES];
        MyUtil.lodash.forEach(allActivities, (activity) => {

          //Skip if this is a course activity or private
          if(!activity.visible || activity.course_id) {
            //continue;
            return true;
          }

          //Skip if self-study and not "current" (ie. if start is in the future or end is in the past)
          if(activity.is_self_study) {
            let nowTimestamp = MyUtil.getUnixTimeStamp();
            if(activity.start_ts > nowTimestamp || activity.end_ts < nowTimestamp) {
              //continue;
              return true;
            }
          }
          
          //Skip if activity has programs and none match the user's program 
          if(activity.programs?.length > 0) {
            if(!activity.programs.includes(this.profile.program_id)) {
              //continue;
              return true;
            }
          }

          //Skip if activity has phases and none match the user's phase 
          if(activity.phases?.length > 0) {
            if(!activity.phases.includes(this.profile.phase_id)) {
              //continue;
              return true;
            }
          }
          
          //Get any user activity/booking details
          let userActivity = MyUtil.cache[MyUtil.DOC_ID.USER_ACTIVITIES][activity.id];

          //Work out if this activity clashes with anything the user has also booked
          let activityBookingClash = MyUtil.getActivityClashingState(activity, clashableActivities);

          //Human-readable date range
          let period = MyUtil.getShortDisplayDateTime(this.homeOrg, 
            activity.start_at, 
            activity.start_time, 
            activity.end_at, 
            activity.end_time);

          //Format recommended skill
          activity.recommended_skill = '';
          if(activity.recommended_for_skill) {
            let skill = MyUtil.getSkill(activity.recommended_for_skill);
            if(skill) {
              activity.recommended_skill = skill.name;
            }
          }

          let activityItem: ContentListItemVO = {
            id: activity.id,
            oid: activity.oid,
            is_course: false,
            name: activity.name,
            internal_reference: activity.internal_reference,
            description: activity.description,
            profile_id: activity.profile_id,
            is_booked: userActivity ? userActivity.is_booked : false,
            on_waiting_list: userActivity ? userActivity.on_waiting_list : false,
            on_application_list: userActivity ? userActivity.on_application_list : false,
            added_not_booked: userActivity ? (activity.booking_via_inkpath && !userActivity.has_reservation) : false,
            completed_at: userActivity ? userActivity.completed_at : null,
            provider: activity.provider,
            period: period,
            start_ts: activity.start_ts,
            end_ts: activity.end_ts,
            recommended_skill: activity.recommended_skill,
            attendance_type_id: activity.attendance_type_id,
            is_self_study: activity.is_self_study,
            is_type_other: activity.is_type_other,
            badge_details: activity.badge_details,
            price: activity.price,
            skills: activity.skills,
            booking_clash: activityBookingClash,
            currency_symbol: activity.currency_symbol,
          }

          //Add to self-study or scheduled lists depending on attendance type
          if(activity.is_self_study) {
            selfStudyContent.push(activityItem);
          } else {
            scheduledContent.push(activityItem);
          }
        });    

        //Filter courses on values unaffected by user's filters
        let allCourses = MyUtil.cache[MyUtil.DOC_ID.COURSES];
        MyUtil.lodash.forEach(allCourses, (course) => {

          //Skip if private course
          if(!course.visible) {
            return true;
          }

          //Skip if course has programs and none match the user's program 
          if(course.programs?.length > 0) {
            if(!course.programs.includes(this.profile.program_id)) {
              return true;
            }
          }

          //Skip if cpurse has phases and none match the user's phase 
          if(course.phases?.length > 0) {
            if(!course.phases.includes(this.profile.phase_id)) {
              return true;
            }
          }

          //Get any booking details
          let userCourse = MyUtil.cache[MyUtil.DOC_ID.USER_COURSES][course.id];

          //Work out if any of the user's booked course activities clash with anything else the user has also booked
          let courseBookingClash = MyUtil.getCourseClashingState(course, clashableActivities);

          //Human-readable date range
          let period = MyUtil.getShortDisplayDateTime(this.homeOrg, 
            course.start_at, 
            course.start_time, 
            course.end_at, 
            course.end_time);

          let courseItem: ContentListItemVO = {
            id: course.id,
            oid: course.oid,
            is_course: true,
            name: course.name,
            internal_reference: course.internal_reference,
            description: course.description,
            profile_id: null,
            is_booked: userCourse ? userCourse.is_booked : false,
            on_waiting_list: userCourse ? userCourse.on_waiting_list : false,
            on_application_list: userCourse ? userCourse.on_application_list : false,
            added_not_booked: false,
            completed_at: userCourse ? userCourse.completed_at : null,
            provider: course.provider,
            period: period,
            start_ts: course.start_ts,
            end_ts: course.end_ts,
            recommended_skill: '',
            attendance_type_id: null,
            is_self_study: false,
            is_type_other: false,
            badge_details: course.badge_details,
            price: course.price,
            skills: course.skills,
            booking_clash: courseBookingClash,
            currency_symbol: course.currency_symbol,
          }

          //Add to scheduled list (no such thing as a self-study course)
          scheduledContent.push(courseItem);
        });

        this.scheduledContent$.next(scheduledContent);
        this.selfStudyContent$.next(selfStudyContent);

        this.loading$.next(false);
      });
    });

    MyUtil.firebaseSetScreenName('activity-list');
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-list', data: this.navParams.data });

    // Start walkthrough.
    this.startWalkthrough();
  }


  /** 
  * Sets skills list
  */
  setSkillsList() {
    let skills = MyUtil.getSkills()
    for (const key in skills) {
      if (skills.hasOwnProperty(key)) {
        this.skills.push(skills[key])
      }
    }
  }


  setFormValues() {
    if(this.findActivitiesFilters) {
      this.setDirtyFormValues();
    } else {
      this.setDefaultFormValues();
    }
  }


  setDirtyFormValues() {

    this.isDirtyForm = true;
    this.filterForm.controls.searchTerm.setValue(this.findActivitiesFilters.searchTerm);
    this.filterForm.controls.organisations.setValue(this.findActivitiesFilters.organisations);
    this.setFilterCount(this.findActivitiesFilters.organisations, 'organisations');
    this.filterForm.controls.attendanceType.setValue(this.findActivitiesFilters.attendanceType);
    this.setFilterCount(this.findActivitiesFilters.attendanceType, 'attendanceType');
    this.filterForm.controls.skills.setValue(this.findActivitiesFilters.skills);
    this.setFilterCount(this.findActivitiesFilters.skills, 'skills');
    this.filterForm.controls.start.setValue(this.findActivitiesFilters.start);
    this.filterForm.controls.end.setValue(this.findActivitiesFilters.end);
  }


  // Set Default Form Values.
  setDefaultFormValues() {

    let defaultOrganisations = ["all"];
    this.organisations.forEach((organisation) => {
      defaultOrganisations.push(organisation.id)
    })

    let defaultAttendance = ["all"];
    this.attendanceTypes.forEach((attendance) => {
      defaultAttendance.push(attendance.key)
    })

    let defaultSkills = [];
    if (this.routeData.skills?.length > 0) {
      defaultSkills = this.routeData.skills;
      this.isDirtyForm = true;
    } else {
      defaultSkills.push("all");
      this.skills.forEach((skill) => {
        defaultSkills.push(skill.id)
      });
    }

    // Set form controls.
    this.filterForm.controls.organisations.setValue(defaultOrganisations);
    this.setFilterCount(defaultOrganisations, 'organisations');
    this.filterForm.controls.attendanceType.setValue(defaultAttendance);
    this.setFilterCount(defaultAttendance, 'attendanceType');
    this.filterForm.controls.skills.setValue(defaultSkills);
    this.setFilterCount(defaultSkills, 'skills');

    const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
    let defaultStart = (this.routeData?.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(nowTimestamp);
    let defaultEnd = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + (86400 * 365 * 75));
    this.filterForm.controls.start.setValue(defaultStart);
    this.filterForm.controls.end.setValue(defaultEnd);

    this.filterForm.markAsPristine();
    this.filterForm.markAsTouched();
  }


  /** 
  * Resets form.
  */
  resetForm() {   
    setTimeout(() => { 
      this.routeData = {};
      this.filterForm.reset();
      this.setDefaultFormValues();
    }, 200);

    this.isDirtyForm = false;
  }


  /** 
  * Displays "Reset" button
  */
  setFormDirtyState() {
    if(!this.isDirtyForm) {
      MyUtil.lodash.some(this.filterForm.controls, value => {
        if (!value.pristine || value.touched) {
          this.isDirtyForm = true;
          return true;
        }
      });
    }
  }



  /*
  Tab select modal handler.
  */
  public async tabSelectModalHandler(currentTab: string = '') {

    if(this.scheduledActivitiesCount > 0 && this.selfStudyActivitiesCount > 0) {
      let fortnightAwayFromTabStateSet = new Date(this.findActivitiesTabState?.timestamp + 12096e5).getTime();
      let now = Date.now();

      // Open modal if less that two weeks from last set.
      if(now > fortnightAwayFromTabStateSet || !this.findActivitiesTabState?.timestamp){     

        let modal = MyUtil.createModal(FindActivitiesModalComponent, {
          scheduledCount: this.scheduledActivitiesCount,
          selfStudyCount: this.selfStudyActivitiesCount
        });

        (await modal).onDidDismiss().then((data: any) => { 
          if (data.data !== undefined) {
            this.modalShown = true;
            this.setSelectedTabModal(data.data);
            this.findActivitiesTabState = JSON.parse(MyUtil.retrieveFromLocalStorage('findActivitiesTabState'));
          } 
        });

        (await modal).present();
      }
    } else if(currentTab == 'scheduled' && this.selfStudyActivitiesCount > 0) {
      this.setSelectedTabModal('self-study');
    } else if (currentTab == 'self-study' && this.scheduledActivitiesCount > 0) {
      this.setSelectedTabModal('scheduled');
    }
  }


  toggleSelectAllOrgs(event: MatOptionSelectionChange) {

    if(event.isUserInput) {
      let defaultOrganisations = [];
      if(event.source.selected) {
        defaultOrganisations = ["all"];
        this.organisations.forEach((organisation) => {
          defaultOrganisations.push(organisation.id)
        });
      }

      this.filterForm.controls.organisations.setValue(defaultOrganisations);
      this.setFilterCount(defaultOrganisations, 'organisations');
      this.filterForm.markAsDirty();
      this.isDirtyForm = true;
    }
  }

  toggleSelectAllAttendance(event: MatOptionSelectionChange) {

    if(event.isUserInput) {
      let defaultAttendance = [];
      if(event.source.selected) {
        defaultAttendance = ["all"];
        this.attendanceTypes.forEach((attendance) => {
          defaultAttendance.push(attendance.key)
        });
      }
        
      this.filterForm.controls.attendanceType.setValue(defaultAttendance);
      this.setFilterCount(defaultAttendance, 'attendanceType');
      this.filterForm.markAsDirty();
      this.isDirtyForm = true;
    }
  }

  toggleSelectAllSkills(event: MatOptionSelectionChange) {
    if(event.isUserInput) {
      let defaultSkills = [];
      if(event.source.selected) {
        if (this.skills.length > 0) {
          defaultSkills = ["all"];
          this.skills.forEach((skill) => {
            defaultSkills.push(skill.id)
          });
        }
      }

      this.filterForm.controls.skills.setValue(defaultSkills);
      this.setFilterCount(defaultSkills, 'skills');
      this.filterForm.markAsDirty();
      this.isDirtyForm = true;
    }
  }

  unsetSelectAll(event: MatOptionSelectionChange, type: string) {

    if(event.isUserInput) {
      let control = null;
      if(type === 'organisations') {
        control = this.filterForm.controls.organisations;
      } else if(type == 'attendanceType') {
        control = this.filterForm.controls.attendanceType;
      } else if(type === 'skills') {
        control = this.filterForm.controls.skills;
      }

      let selectedValues : Array<any> = [].concat(control.value);
      if(!event.source.selected) {
        if(selectedValues.indexOf('all') !== -1) {
          selectedValues.splice(selectedValues.indexOf('all'), 1);
        };
      
        if(selectedValues.indexOf(event.source.value) !== -1) {
          selectedValues.splice(selectedValues.indexOf(event.source.value), 1);
        }

        control.setValue(selectedValues);
      } else {
        selectedValues.push(event.source.value)
      }
      
      this.setFilterCount(selectedValues, type);
    }
  }

  private setFilterCount(selectedValues, type: string) {

    let filterCount = 'all';
    if(selectedValues.indexOf('all') === -1) {
      if(selectedValues.length === 0) {
        filterCount = 'none';
      } else if(selectedValues.length === 1) {
        filterCount = '1';
      } if(selectedValues.length > 1) {
        filterCount = '1+';
      }
    }

    if(type === 'organisations') {
      this.orgFilterCount = filterCount;
    } else if(type == 'attendanceType') {
      this.attendanceFilterCount = filterCount;
    } else if(type === 'skills') {
      this.skillsFilterCount = filterCount;
    }
  }


  // Set selected tab
  setSelectedTab(tabName) {
    this.tabSelection$.next(tabName);

    // Set tab in storage but keep timestamp of modal click.
    let findActivitiesTabState:findActivitiesTabStateVO = {
      tab: tabName,
      timestamp: this.findActivitiesTabState?.timestamp
    }

    MyUtil.saveToLocalStorage('findActivitiesTabState', JSON.stringify(findActivitiesTabState));
    this.findActivitiesTabState = JSON.parse(MyUtil.retrieveFromLocalStorage('findActivitiesTabState'));
  }


  // Set selected tab
  setSelectedTabModal(tabName) {
    this.tabSelection$.next(tabName);
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'view-activity':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;
      case 'create-activity':
        this.router.navigate(['/ActivityEditPage', '{}']);
        return;
      case 'view-course':
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ id: item.id })]);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }


  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'activity-list', data: this.navParams.data });
  }

  // On destroy.
  ngOnDestroy() {
    this.tabSelection$.unsubscribe();
    this.scheduledContent$.unsubscribe();
    this.selfStudyContent$.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


    /** 
  * Set walkthrough state. 
  * @param pageName   Name of page.
  * @param value      Boolean - (Has been visited or not).
  */
    setWalkthroughStateHandler(pageName: string, value: boolean) {
      WalkthroughService.setWalkthroughState(pageName, value)
    }
  
    /** 
    * Re initialize and specify step.
    * @param stepNumber   stepNumber: string.
    */
    initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);
  
    /** 
    * Next step.
    * @param stepNumber   stepNumber: string.
    */
    nextStep = (stepNumber: any) => this.copilot.next(stepNumber);
  
    /** 
    * Finish copilot walkthroughs.
    */
    done = () => this.copilot.removeWrapper();
  
    /** 
    * Start walkthroughs.
    */
    startWalkthrough() {
      if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
        setTimeout(() => {
          this.copilot.checkInit('18');
        }, 1000);
      }
      this.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
    }

}