import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { Appcalc } from 'src/app/providers/appcalc';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from '../../../libs/MyUtil';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ActivityService } from 'src/app/providers/activity-service';

@Component({
  selector: 'page-skill-details',
  templateUrl: 'skill-details.html',
  styleUrls: ['skill-details.scss']
})
export class SkillDetailsPage implements OnInit {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public navParams: NavParams,
    public appapi: Appapi,
    public appcalc: Appcalc,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    private titleService:Title) {
      this.titleService.setTitle("Skill Details"); 
  }

  ngOnInit(): void {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    let loading = MyUtil.presentLoading();    

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.SKILL_DETAILS]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.SKILL_DETAILS, true);
    }

    let id = this.routeData.id;
    this.pageData.progressColor = this.routeData.color;
    this.pageData.skill = MyUtil.getSkill(id);
    this.pageData.skillDisplay = {};
    this.pageData.skillSegments = 'progress';
    this.pageData.homeOrg = MyUtil.getRootOrganization();
    
    // render use updated data
    this.appcalc.refreshCompletedActivities$().subscribe(async () => {
      // add the missing course activities
      this.renderPage();
      (await loading).dismiss();
    });

    MyUtil.firebaseSetScreenName('skill-details');
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'skill-details', data: this.navParams.data });
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'skill-details', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'filter-activity-list-to-skill':
        this.filterActivitiesBySkill();
        return;
      case 'activity-detail':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;
      case 'course-detail':
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ id: item.id })]);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  /** 
  * Render page.
  */
  renderPage() {

    let data = this.pageData.skill;
    let id = this.pageData.skill.id;

    this.appcalc.refreshPerformancePerSkills$(0).subscribe((performance) => {
      let skillProgress = MyUtil.lodash.filter(performance, (item) => {
        return parseInt(item.id) == id;
      });

      //Get first item of array, if present
      let skillProgressItem = null;
      if (skillProgress.length > 0) {
        skillProgressItem = skillProgress[0];
      } else {
        skillProgressItem = {
          value: 0,
          activities: [],
          courses: [],
        }
      }

      this.pageData.skillDisplay = {
        name: data.name,
        code: data.code,
        description: data.description,
        hours_logged: (skillProgressItem && skillProgressItem.value) ? skillProgressItem.value : 0,
        activities: MyUtil.lodash.chain(skillProgressItem.activities).map((item) => {
          let activity = MyUtil.getActivity(item.activity_id);
          if(activity) {
            activity.period = MyUtil.getShortDisplayDateTime(this.pageData.homeOrg, 
              activity.start_at, 
              activity.start_time, 
              activity.end_at, 
              activity.end_time);
  
            return {
              id: activity.id,
              name: activity.name,
              period: activity.period,
              venue: activity.venue,
              time_logged: item.time_logged,
              activity_template_id: activity.activity_template_id
            };
          }
        }).value(),
        courses: MyUtil.lodash.chain(skillProgressItem.courses).map((item) => {
          let course = MyUtil.getCourse(item.course_id);
          if(course) {
            course.period = MyUtil.getShortDisplayDateTime(this.pageData.homeOrg, 
              course.start_at, 
              null, 
              course.end_at, 
              null);

            return {
              id: course.id,
              name: course.name,
              period: course.period,
              time_logged: item.time_logged
            };
          }          
        }).value(),
      }

      this.pageData.loaded = true;
    });
  }

  /** 
  * Filter activities by skill.
  */
  private filterActivitiesBySkill() {
    this.router.navigate(['/ActivityListPage', JSON.stringify({ skills: [this.pageData.skill.id] })]);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
