import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { MyUtil } from 'src/libs/MyUtil';
import { SwiperComponent } from 'swiper/angular';
import { Appapi } from "../../providers/appapi";
import { DesktopChecker } from "../../providers/desktopChecker";
import { takeUntil } from 'rxjs/operators';
import { DiagnosticService } from '../../providers/diagnostic/diagnostic-service';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appStore from '../../store';
import { getBrandName, getDiagnosticsState } from 'src/app/store/selectors/view.selector';

// import Swiper core and required modules
import SwiperCore, { Swiper, Virtual, EffectFade } from 'swiper';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Diagnostic } from 'src/app/valueObjects/diagnostics.vo';
SwiperCore.use([EffectFade, Virtual]);

@Component({
  selector: 'page-diagnostic-questions',
  templateUrl: 'diagnostic-questions.html',
  styleUrls: ['diagnostic-questions.scss'],
})
export class DiagnosticQuestionsPage implements OnInit, OnDestroy {



  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables  
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  loading: any;
  questionData: any;
  branding = null;
  answersComplete = "0%";
  currentSection = 0;
  brandName$: Observable<string> = this.store.select(getBrandName);
  sectionViewFormGroup: FormGroup;
  fields = [];
  hasSections = false;
  diagnostics$: Observable<Diagnostic[]> = this.store.select(getDiagnosticsState);
  diagnostic: any;
  segments: any;
  characterLimit = 100;

  constructor(
    public router: Router,
    public navCtrl: NavController,
    public navParams: NavParams,
    private el: ElementRef,
    public appapi: Appapi,
    public sanitiser: DomSanitizer,
    private store: Store<appStore.AppViewState>,
    private route: ActivatedRoute,
    public desktopChecker: DesktopChecker,
    public diagnosticService: DiagnosticService,
    private titleService: Title) {
    this.titleService.setTitle("Diagnostic Questions");
  }

  /**
  * Calculate Questionnaire length.
  */
  questionnaireLength = 0;
  calculateQuestionnaireLength() {
    if (this.diagnostic.segments) {
      this.diagnostic.segments.forEach((section, k) => {
        section.questions.forEach((question, k) => {
          this.questionnaireLength++;
        })
      })
    } else {
      this.diagnostic.questions.forEach((question, k) => {
        this.questionnaireLength++;
      })
    }
  }

  async ngOnInit() {

    this.pageData.showExplainer = false;

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.REFLECTION]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.REFLECTION, true);
    }

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    this.diagnostics$.pipe(takeUntil(this.destroy$)).subscribe(diagnostics => {
      let currentDiagnostic = diagnostics.filter((diagnostic) => {
        return diagnostic.id === this.routeData.id;
      })
      this.diagnostic = currentDiagnostic[0];
    })

    // Build section view form.
    this.buildForm();

    // get any available diagnostic tests
    this.pageData.diagnosticAnswers = [];
    this.pageData.diagnosticTest = [];

    // get this data from the API - needs to have the diagnostic test ID too, for reference
    const testId = this.routeData.id;
    this.pageData.diagnosticTest = this.routeData.test;
    this.pageData.resumed = this.routeData.resumed;
    this.pageData.startIndex = this.routeData.startIndex;

    await this.getQuestions(testId).then(async res => {

      let questions = Object.keys(res).map(index => {
        let question = res[index];
        return question;
      });

      this.pageData.diagnosticQuestions = questions;
      this.pageData.diagnosticQuestions.forEach((element, i) => {

        let answers = null;

        answers = Object.keys(element.answers).map(index => {
          let answer = element.answers[index];
          return answer;
        });
        element.answers = answers;

        this.pageData.diagnosticAnswers[element.id] = null;

        if (i === (this.pageData.diagnosticQuestions.length - 1)) {
          element.last = true;
          element.next = () => { return this.process('skip', element.id); }
        } else {
          element.last = false;
          element.next = () => { return this.process('slide-next', element.id); }
        }

        if (i > 0) {
          element.prev = () => { return this.process('slide-prev', element.id); }
        }

        element.first = (i === 0);
        element.showExplainer = (i === 0);
        element.pause = () => { return this.process('pause', element.id); }

      });

      //Get previous submissions if resuming a paused test
      if (this.pageData.resumed) {
        await this.diagnosticService.getDiagnosticTestSubmission(this.pageData.diagnosticTest.id).then(submissions => {

          submissions.forEach((submission) => {
            if (submission.answer.free_text) {
              this.pageData.diagnosticAnswers[submission.question_id] = submission.free_text;
            } else {
              this.pageData.diagnosticAnswers[submission.question_id] = submission.answer.id.toString();
            }
          });

          //Fast forward to where the user left off
          if (this.pageData.startIndex > 0 && this.swiper) {
            for (let i = 0; i < this.pageData.startIndex; i++) {
              this.swiper.swiperRef.slideNext(1000);
            }
          }
        });
      }
    }).then(() => {
      this.formatQuestionnaireSectionData();
      this.calculateQuestionnaireLength();
      this.setFormValues();
    })


    // Sections form value changes - update progress.
    this.sectionViewFormGroup.valueChanges.subscribe((r) => {
      this.setProgress(r)
    })

  }

  /**
  * Set progress.
  */
  setProgress(formValues) {
    let count = 0;

    // Count for completed sections for the jump to menu.
    // Reset array before repopulating.
    Object.values(this.sectionAnswers).forEach((value) => {
      value['allQuestions'] = [];
      value['answeredQuestions'] = [];
    });
    // Set question counts for jump menu and progress bar.
    Object.entries(formValues).forEach((value) => {
      if (this.diagnostic?.segments) {
        // Sections
        let sectionId = value[0].split('-')[0];
        let questionId = value[0].split('-')[1];
        let answer = value[1];
        let questionData = {};
        questionData[questionId] = answer;
        // update array
        this.pageData.diagnosticAnswers[questionId] = answer;
        // Bail out if no segments
        this.sectionAnswers[sectionId].allQuestions.push(questionData);
        if (answer !== '') {
          this.sectionAnswers[sectionId].answeredQuestions.push(questionData);
          count++;
        }
      }else{
        // No sections
        let questionId = value[0];
        let answer = value[1];
        let questionData = {};
        questionData[questionId] = answer;
        // update array
        this.pageData.diagnosticAnswers[questionId] = answer;
        if (answer !== '') {
          count++;
        }
      }

    });
    console.log(count, this.questionnaireLength)
    this.answersComplete = this.valueAsPercent(count, this.questionnaireLength);
  }

  /** 
  * Get questions.
  * @param testId  
  */
  async getQuestions(testId) {
    return await this.diagnosticService.getDiagnosticTestQuestions(testId);
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param id 
  */
  process(action, id) {

    let unansweredQuestion = false;
    if(this.diagnostic?.segments){
      Object.values(this.pageData.diagnosticAnswers).forEach((v) => {
        if (v === '') {
          unansweredQuestion = true;
        }
      })
    }else{
      unansweredQuestion = !this.pageData.diagnosticAnswers[id]
    }
    
    if (unansweredQuestion && !(action == 'slide-prev' || action == 'pause')) {
      MyUtil.presentToast('Please complete your answer.', { cssClass: 'inkpath-toast' });
      return;
    }

    switch (action) {
      case 'slide-prev':
        this.swiper.swiperRef.slidePrev(30);
        return;
      case 'slide-next':
        this.swiper.swiperRef.slideNext(30);

        return;
      case 'skip':
        // save the submission and redirect
        this.diagnosticService.saveDiagnosticTestAnswers(this.routeData.id, this.pageData.diagnosticAnswers, this.pageData.resumed).then(questionData => {
          MyUtil.presentToast('Questionnaire submission saved.', { cssClass: 'inkpath-toast' });
          if (this.branding === 'rsc') {
            this.router.navigate(['/RscDiagnosticSummaryComponent', JSON.stringify({ id: this.pageData.diagnosticTest.id, selectedTest: this.pageData.diagnosticTest, questionData: questionData })]);
          } else {
            this.router.navigate(['/DiagnosticSummaryPage', JSON.stringify({ id: this.pageData.diagnosticTest.id, questionData: questionData })]);
          }
        });
        return;
      case 'pause':
        //Check if any questions answered
        let dataToSave = false;
        this.pageData.diagnosticAnswers.forEach((answer, i) => {
          if (answer !== null) {
            dataToSave = true;
          }
        });
        if (dataToSave) {
          this.diagnosticService.saveDiagnosticTestAnswers(this.routeData.id, this.pageData.diagnosticAnswers, this.pageData.resumed).then(questionData => {
            MyUtil.presentToast('Diagnostic submission paused and saved - you can resume at any time.', { cssClass: 'inkpath-toast' });
            this.router.navigate(['/DiagnosticListPage', '{}']);
          });
        } else {
          this.router.navigate(['/DiagnosticListPage', '{}']);
        }
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /** 
  * Percentage of element as pixels.
  */
  percentAsValue(percentage, element) { return Math.round((percentage * element) / 100) }

  /** 
  * Pixels of element as Percentage.
  */
  valueAsPercent(value1, value2) {
    let perc = Math.round((value1 / value2) * 100);
    if(isNaN(perc)){
      perc = 0;
    }
    return `${perc}%`;
  }

  /** 
  * Section View: Set Active Section.
  */
  setActiveSection(index) {
    this.currentSection = index;
    let elems = document.querySelectorAll(".section-container");
    [].forEach.call(elems, function (el) {
      el.classList.remove('active-section');
    });

    let targets = document.querySelectorAll(".section-" + index);
    [].forEach.call(targets, function (el) {
      el.classList.add('active-section');
    });
  }

  /** 
  * Sanitize HTML.
  */
  shortHTML(input) {
    let cleanText = input.replace(/<\/?[^>]+(>|$)/g, "");
    if (input.length >= this.characterLimit) {
      cleanText = cleanText.substring(0, this.characterLimit) + "...";
    }
    return cleanText;
  }

  /** 
  * Section View: Previous.
  */
  prevSection() {
    this.setActiveSection(this.currentSection - 1);
  }

  /** 
  * Section View: Next.
  */
  nextSection() {
    if (this.currentSection !== this.segments?.length - 1) {
      this.setActiveSection(this.currentSection + 1);
    } else {
      this.process('skip', this.routeData.id)
    }
  }

  /** 
  * Section View: Set Active Section.
  */
  buildForm() {
    // if (!this.diagnostic?.segments) {
    //   return;
    // }
    const formGroupFields = this.getFormControlsFields();
    this.sectionViewFormGroup = new FormGroup(formGroupFields);
  }

  /** 
  * Section View: Create form fields from data.
  */
  sectionAnswers = {}
  getFormControlsFields() {

    const formGroupFields = {};
    if (this.diagnostic?.segments) {
      // Build segment form.
      for (const section of this.diagnostic?.segments) {
        var name = section.id;
        this.sectionAnswers[name] = {
          allQuestions: [],
          answeredQuestions: [],
        };
        for (const question of section.questions) {
          formGroupFields[section.id + '-' + question.id] = new FormControl("");
        }
      }
    } else {
      // Build normal question form.
      for (const question of this.diagnostic?.questions) {
        formGroupFields[question.id] = new FormControl("");
      }
    }
    return formGroupFields;
  }

  /**
  * Set form values.
  */
  setFormValues() {
    if (this.diagnostic?.segments) {
      for (const section of this.diagnostic?.segments) {
        for (const question of section.questions) {
          if (this.pageData.diagnosticAnswers[question.id]) {
            this.sectionViewFormGroup.controls[section.id + '-' + question.id].setValue(this.pageData.diagnosticAnswers[question.id]);
          }
        }
      }
    } else {
      for (const question of this.diagnostic?.questions) {
        if (this.pageData.diagnosticAnswers[question.id]) {
          this.sectionViewFormGroup.controls[question.id].setValue(this.pageData.diagnosticAnswers[question.id]);
        }
      }
    }
    this.setProgress(this.sectionViewFormGroup.value);
  }

  /**
  * Format QuestionnaireData.
  */
  formatQuestionnaireSectionData() {
    if (!this.diagnostic.segments) {
      return;
    }
    this.segments = this.diagnostic.segments.map((segment) => {
      console.log('segment', segment)
      let updatedSegment = { ...segment };
      let segmentQuestions = [];
      this.pageData?.diagnosticQuestions.forEach((v, k) => {
        if (v.diagnostic_segment_id === segment.id) {
          segmentQuestions.push(v);
        }
      })
      updatedSegment.questions = segmentQuestions;
      return updatedSegment;
    })
  }

}
