import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityModalComponent } from 'src/app/components/accessibility-modal/accessibility-modal.component';
import { ContactModalComponent } from 'src/app/components/contact-modal/contact-modal.component';
import { Appapi } from 'src/app/providers/appapi';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { SsoLoginProvider } from 'src/app/providers/sso-login/sso-login';
import { getBrandName, getDisplayMode, getSSO, getUsername, getOrganization } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';
import { EventsService } from 'src/app/providers/events-service';
import { MyDb } from 'src/libs/MyDb';

declare var window: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  pageData: any = {};
  displayMode$: Observable<string> = this.store.select(getDisplayMode);
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);
  username$: Observable<string> = this.store.select(getUsername);
  organization$: Observable<string> = this.store.select(getOrganization);
  sso$: Observable<string> = this.store.select(getSSO);
  isSso: boolean = false;
  profileDisabled = false;
  activeAccountMenu = false;
  activeOrganisationMenu = false;

  // menuOpened() {
  //   setTimeout(() => {
  //     let accountButton = document.getElementById('switch-org');
  //     if(accountButton){
  //       accountButton.focus();
  //     }
  //   }, 0);
  // }

  constructor(
    private router: Router,
    public appapi: Appapi,
    public auth: AuthProvider,
    public ssoLogin: SsoLoginProvider,
    private store: Store<appStore.AppViewState>,
    private eventsService: EventsService,
  ) { }

  readonly announcementCount$ = this.eventsService.announcementCount$;
  readonly announcementTooltip$ = this.eventsService.announcementTooltip$;

  async ngOnInit() {

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

    this.sso$.pipe(takeUntil(this.destroy$)).subscribe(sso => {
      this.isSso = !!sso['id'];
    })

    this.pageData.enable_recommendations = false;
    this.pageData.alternativeProfilesData = [];
    this.pageData.profileNameLabel = '';
    this.pageData.orgLabel = '';
    this.pageData.loaded = false;

    this.pageData.initialisedProfile = MyUtil.getProfile();
    this.pageData.homeOrg = MyUtil.getRootOrganization();
    if (!this.pageData.homeOrg) {
      return;
    }

    //Get multi-tenant profiles
    this.pageData.alternativeProfilesData = [];
    let loading = MyUtil.presentLoading();
    this.appapi.getAlternativeProfilesData(this.branding).then(async (alternativeProfilesData) => {

      this.pageData.enable_recommendations = this.pageData.homeOrg.enable_recommendations;
      
      this.pageData.profileNameLabel = '';
      if (this.pageData.initialisedProfile.first_name && this.pageData.initialisedProfile.last_name) {
        this.pageData.profileNameLabel = this.pageData.initialisedProfile.first_name + ' ' + this.pageData.initialisedProfile.last_name;
      }

      this.pageData.alternativeProfilesData = alternativeProfilesData;
      if (this.pageData.alternativeProfilesData?.length > 0) {
        if (this.pageData.profileNameLabel !== '') {
          this.pageData.profileNameLabel += ' @ '
        }
        this.pageData.profileNameLabel += this.pageData.homeOrg.name;
        this.pageData.orgLabel = this.pageData.homeOrg.name;
        MyUtil.saveToLocalStorage('multiTenant', "1");
      } else {
        MyUtil.saveToLocalStorage('multiTenant', "0");
      }

      this.store.dispatch(new appStore.usernameSet(this.pageData.profileNameLabel));
      this.store.dispatch(new appStore.organizationSet(this.pageData.orgLabel));

      this.pageData.showNextSteps = false;

      this.pageData.loaded = true;
      (await loading).dismiss();
    });
  }


    /** 
  * Set display mode.
  * @param mode   mode: string.
  */
  setDisplayMode(mode: string) {
    this.store.dispatch(new appStore.DisplayModeSet(mode));
    MyUtil.saveToLocalStorage('displayMode', mode);
  }

  /** 
  * Toggle Account Menu.
  */
  toggleAccountMenuClick(event) {
    this.activeAccountMenu = !this.activeAccountMenu;
    if(this.activeAccountMenu) {
      this.activeOrganisationMenu = false;
    }
  }


    /** 
  * Toggle Organisation Menu.
  */
  toggleOrganisationMenuClick(event) {
    this.activeOrganisationMenu = !this.activeOrganisationMenu;
    if(this.activeOrganisationMenu) {
      this.activeAccountMenu = false;
    }
  }

  /** 
  * Check profile complete.
  */
  checkProfileComplete() {
    return (MyUtil.validateProfile()) ? true : false;
  }
  

  /** 
  * Contact click handler.
  */
  public async contactClickHandler() {
    let contactModal = MyUtil.createModal(ContactModalComponent, {});
    (await contactModal).onDidDismiss().then((data: any) => { });
    (await contactModal).present();
  }

  /** 
  * Actions.
  * @param actionItem   Name of action (string).
  */
  async process(actionItem) {
    this.store.dispatch(new appStore.MenuStateSet('inactive-mobile-menu'));
    this.store.dispatch(new appStore.FindActivitiesFilterStateSet(undefined));

    if (MyUtil.isNetworkConnected() || actionItem == 'about-app') {

      switch (actionItem) {
        case 'activities':
          this.router.navigate(['/']);
          break;
        case 'account':
          this.router.navigate(['/UserSettingsPage', '{}']);
          break;
        case 'about-app':
          this.router.navigate(['/AboutPage']);
          break;
        case 'show-website':
          this.appapi.openBrowser('https://www.inkpath.co.uk/');
          break;
        case 'recommended':
          this.router.navigate(['/RecommendedComponent']);
          break;
        case 'goals-page':
          this.router.navigate(['/GoalsPage']);
          break;
        case 'user-page':
          this.router.navigate(['/UserPage']);
          break;
        case 'show-website':
          this.appapi.openBrowser('https://www.inkpath.co.uk/');
          break;
        case 'terms-of-use':
          this.appapi.openAppLawBrowser('terms_and_conditions');
          break;
        case 'privacy-policy':
          this.appapi.openAppLawBrowser('privacy_policy');
          break;
        case 'open-help':
          
        await  MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser =>{
          this.appapi.post('/zendesk/ssotoken/' + appUser.id, {}).then(async (result) => {
              window.open(result['#data'].sso_url, "_blank");
            }).catch(async err => {
              MyUtil.error(err);
            });
          });
          break;
        case 'open-help-rsc':
          this.appapi.openBrowser('https://members.rsc.org/site/content/PathfinderHelp.aspx');
          break;
        case 'email-settings':
          this.router.navigate(['/EmailSettingsPage']);
          break;
        case 'open-accessibility':
          this.accessibilityHandler();
          break;
        case 'change-password':
          this.router.navigate(['/PasswordPage', JSON.stringify({ 'initialisedProfile': this.pageData.initialisedProfile })]).catch(err => {
            MyUtil.debug(['navigate', err]);
          });
          break;
        case 'diagnostic':
          this.router.navigate(['/DiagnosticListPage', '{}']);
          break;
        case 'logout':
          this.auth.logout();
          break;
        default:
          break;
      }
    } else {
      MyUtil.presentToast('Please check the network connection and try again later.', { cssClass: 'inkpath-toast' });
    }
  }
  

  /**
   * Switches the login use use a profile that matches the selected org.
   * @param newOid
   */
  public switchOrg(newOid: number, orgName: string) {

    MyUtil.presentAlert({
      'title': 'Switch organisation',
      message: "Switch organisation to " + orgName + "?",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            return;
          }
        },
        {
          text: 'OK',
          handler: data => {
            let loading = MyUtil.presentLoading();
            this.appapi.post('/switch-org', { newOid: newOid }).then(async result => {

              if (result['#status'] === 'success') {
        
                this.appapi.forgetAppUser(true).then(async () => {
        
                  let userData = result['#data'];
                  MyUtil.saveToLocalStorage('localOid', userData.oid);
        
                  if (userData.sso) {
                    //Redirect user via SSO
                    const organization = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][userData.oid];
                    this.ssoLogin.initializeSsoLogin(organization);
                  } else {
                    //Clear existing sso
                    if(this.isSso) {
                      this.store.dispatch(new appStore.SSOSet(null));
                    }

                    //Log user in
                    this.auth.loginUser(result['#data'], true);
                  }

                  (await loading).dismiss();
                });
        
                setTimeout(()=>{
                  MyUtil.presentToast("Switched to " + orgName, { duration: MyUtil.CONST.DURATION_TOAST_LONG, cssClass: 'inkpath-toast' });
                }, 1000);

              } else {
                MyUtil.presentToast(result['#message'], { duration: MyUtil.CONST.DURATION_TOAST_LONG, cssClass: 'inkpath-toast' });
              }
              
            }).catch(async err => {
              (await loading).dismiss();
            });
          }
        }
      ]
    });
  }

  showAnnouncements() {
    this.router.navigate(['/AnnouncementsPage']);
  }

  /** 
  * Accessibility handler.
  */
  public async accessibilityHandler() {
    let accessibilityModalComponent = MyUtil.createModal(AccessibilityModalComponent, {});
    (await accessibilityModalComponent).onDidDismiss().then((data: any) => { });
    (await accessibilityModalComponent).present();
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
