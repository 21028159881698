import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { OrganisationVO } from 'src/app/valueObjects/core.vo';
import { ContentListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from 'src/libs/MyUtil';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],
})
export class RecommendedComponent implements OnInit {
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  component = ListItemComponent;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  homeOrg:OrganisationVO;
  pageData: any = {};
  activitiesList$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);
  goalsList$: BehaviorSubject<ContentListItemVO[]> = new BehaviorSubject([]);
  activitiesCount$: BehaviorSubject<number> = new BehaviorSubject(-1);
  goalsCount$: BehaviorSubject<number> = new BehaviorSubject(-1);
  activeTab = 'activities';

  constructor(
    public appapi: Appapi,
    public activityService: ActivityService, 
    public router: Router,
    private titleService:Title) {
      this.titleService.setTitle("Recommended");
  }

  ngOnInit() {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.loading$.next(true);

    // Set home organisation
    this.homeOrg = MyUtil.getRootOrganization();
    
    // Sync Activities.
    this.appapi.syncAllActivities().then( async () => {
      this.appapi.syncAllGoals().then( async () => {
        // Activities
        await this.appapi.getRecommendedActivities().then((result) => {
          
          let activities = result['#data'];
          let clashableActivities = MyUtil.getClashableActivities();
          let recommendedActivities:ContentListItemVO[] = [];
          activities.forEach((activity) => {
            //Work out if this activity clashes with anything the user has also booked
            let activityBookingClash = MyUtil.getActivityClashingState(activity, clashableActivities);

            //Format the start and end times
            activity.period = MyUtil.getShortDisplayDateTime(this.homeOrg, 
              activity.start_at, 
              activity.start_time,
              activity.end_at,
              activity.end_time);

            //Format recommended skill
            activity.recommended_skill = '';
            if(activity.recommended_for_skill) {
              let skill = MyUtil.getSkill(activity.recommended_for_skill);
              if(skill) {
                activity.recommended_skill = skill.name;
              }
            }
            
            //Human-readable date range
            let period = MyUtil.getShortDisplayDateTime(this.homeOrg, 
              activity.start_at, 
              activity.start_time, 
              activity.end_at, 
              activity.end_time);

            let activityItem:ContentListItemVO ={
              id: activity.id,
              oid: activity.oid,
              is_course: false,
              name: activity.name,
              internal_reference: activity.internal_reference,
              description: '',
              profile_id: activity.profile_id,
              is_booked: false,
              on_waiting_list: false,
              on_application_list: false,
              added_not_booked: false,
              completed_at: null,
              provider: activity.provider,
              period: period,
              start_ts: activity.start_ts,
              end_ts: activity.end_ts,
              recommended_skill: activity.recommended_skill,
              attendance_type_id: activity.attendance_type_id,
              is_self_study: activity.is_self_study,
              is_type_other: activity.is_type_other,
              badge_details: activity.badge_details,
              price: activity.price,
              skills: [],
              booking_clash: activityBookingClash,
              currency_symbol: activity.currency_symbol,
            } 

            recommendedActivities.push(activityItem);
          });

          // Fill activities list.
          this.activitiesCount$.next(recommendedActivities.length);
          this.activitiesList$.next(recommendedActivities);
        })

        // Goals
        await this.appapi.getRecommendedGoals().then((result) => {
          let goals = result['#data'];
          let recommendedGoals:ContentListItemVO[] = [];

          goals.forEach(goal => {

            //Format recommended skill
            goal.recommended_skill = '';
            if(goal.recommended_for_skill > 0) {
              let skill = MyUtil.getSkill(goal.recommended_for_skill);
              if(skill) {
                goal.recommended_skill = skill.name;
              }
            }
            
            let goalItem:ContentListItemVO = {
              //TODO: Return to fill in blanks
              id: goal.id,
              oid: goal.oid,
              is_course: false,
              name: goal.name,
              internal_reference: goal.internal_reference,
              description: '',
              profile_id: goal.profile_id,
              is_booked: false,
              on_waiting_list: false,
              on_application_list: false,
              added_not_booked: false,
              completed_at: null,
              provider: goal.provider,
              period: '',
              start_ts: 0,
              end_ts: 0,
              recommended_skill: goal.recommended_skill,
              attendance_type_id: null,
              is_self_study: false,
              is_type_other: false,
              badge_details: goal.badge_details,
              price: goal.price,
              skills: [],
              booking_clash: false,
              currency_symbol: '',
            }

            recommendedGoals.push(goalItem);
          });

          // Fill activities goals list.
          this.goalsCount$.next(recommendedGoals.length);
          this.goalsList$.next(recommendedGoals);

          this.loading$.next(false);
        })
      });
    });
  }

  /** 
  * View Activity Details.
  * @param id  ID of Activity.
  */
  public viewActivityDetails(id: number) {
    this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: id })]);
    return;
  }

  /** 
  * View Goal Details.
  * @param id  ID of goal.
  */
  public viewGoalDetails(id: number) {
    this.router.navigate(['/GoalDetailPage', id]);
    return;
  }

    /** 
  * For mobile view tabs.
  * @param label   Label of tab.
  */
  changeTab(label: string) {
    this.activeTab = label;
  }

  // On Destroy
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
